import React, { useState, useEffect, memo, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  Button,
  TouchableOpacity,
  Alert,
  Image,
} from "react-native";

import { useDispatch, useSelector } from "react-redux";

import {
  setContract,
  setContractLoading,
} from "../../../redux/contracts/actions";
import { signOut } from "../../../redux/sign/actions";

import colors from "../../../../colors.json";

import * as Network from "expo-network";

import api, {
  apiCoobrastur,
  apiCoobD4sign,
  coobkey,
} from "../../../services/api";

import loadingIco from "../../../../assets/loading.gif";

import {
  booleanArray,
  ufArray,
  tipoVendaArray,
  planoArray,
  planoDescricaoArray,
  diariasArray,
  diariasGoArray,
  formaPagamentoMensalidadeArray,
  diaMensalidadeBancoArray,
  diaMensalidadeCartaoArray,
  bancoContaArray,
  estadoCivilArray,
  formaAssinaturaArray,
  adesaoMeioArray,
  formaPagamentoAdesaoVendedorArray,
  formaPagamentoAdesaoCoobrasturArray,
  parcelasPagamentoAdesaoArray,
  formaAditamentoArray,
  valorMensalidadePlanoArray,
  valorMensalidadePlanoFamiliaArray,
  acrescimoMensalidadeArray,
  bandeiraCartaoArray,
} from "../datas";

import {
  getParcelasAdesaoBoletoCoobrasturArray,
  getMensalidade,
  labelByValue,
  formatReal,
  getDayFromString,
  getMonthFromString,
  getYearFromString,
} from "../../../utils/functions";

import { masker } from "../../../utils/functions";

import { format } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

import { DialogContext } from "../../../context/Dialog";
import { NavigationContext } from "../../../context/Navigation";

const Card = ({ item: contract }) => {
  const dispatch = useDispatch();

  const ref = contract?.ref;

  const sign = useSelector((state) => state.sign);
  const token = String(sign.data.token);

  const useDialog = useContext(DialogContext);
  const navigation = useContext(NavigationContext);

  const codigoVendedor = String(sign.data.codigoVendedor);
  const usuarioVendedor = String(sign.data.usuarioVendedor);

  const [isLoading, setIsLoading] = useState(false);

  function getStatusName(id) {
    switch (id) {
      case 1:
        return "Aguardando plano normal";

      case 2:
        return "Aguardando restrição";

      case 3:
        return "Aguardando assinaturas";

      case 4:
        return "Enviado a Coobrastur";

      default:
        return;
    }
  }

  function getIdTipoVendaCoobrastur(id) {
    switch (id) {
      case 1:
        return 1;
      case 2:
        return 11;
      case 3:
        return 53;
      default:
        return 1;
    }
  }

  function getTemplateEnvioAssinaturas() {
    if (!contract.fiador && !contract.aditamento && !contract.terceiro)
      return "MTkzMzM";

    if (contract.fiador && !contract?.aditamento && !contract.terceiro)
      return "MTE4MzM";

    if (!contract.fiador && !contract.aditamento && contract.terceiro)
      return "MTk0Mzg";

    if (
      !contract.fiador &&
      !contract.terceiro &&
      contract.aditamento &&
      contract.formaAditamento == 1
    )
      return "MTQzNDM";
    if (
      !contract.fiador &&
      !contract.terceiro &&
      contract.aditamento &&
      contract.formaAditamento == 2
    )
      return "MTQzNDQ";
  }

  function getDocumentoEnvioAssinaturas(template) {
    return {
      [String(
        "name_document"
      )]: `${contract.cpf}/${contract.nome}/${usuarioVendedor}`,
      templates: {
        [String(template)]: {
          //dados pessoais
          nomeAssociado: String(contract?.nome),
          cpfAssociado: String(contract?.cpf),

          //contato
          emailAssociado: String(contract?.email),
          celularAssociado: String(
            `(${contract?.dddCelular}) ${contract?.celular}`
          ),

          //associacao
          plano: String(labelByValue(contract?.plano, planoArray)),
          diariasPlano: String(`${contract?.qtdDiarias} diárias`),
          familiaPlano: contract?.familia ? "Sim" : "Não",
          valorMensalidade: String(
            formatReal(
              getMensalidade({
                plano: contract?.plano,
                diarias: contract?.qtdDiarias,
                familia: contract?.familia || false,
              })
            )
          ),

          dataPrimeiraMensalidade: String(contract?.dataPrimeiraMensalidade),
          // dataLiberacaoPlano: String(contract?.dataLiberacaoPlano),

          // ADESAO
          adesao: contract.adesao ? "Sim" : "Não",
          valorPagamentoAdesao: contract.adesao
            ? String(formatReal(contract.valorAdesao))
            : "Sem adesão",
          nomeCartao:
            contract.formaPagamento == 2
              ? String(contract?.nomeTitularCartao)
              : "",
          cpfCartao:
            contract.formaPagamento == 2
              ? String(contract?.cpfTitularCartao)
              : "",

          bandeiraCartao:
            contract.formaPagamento == 2
              ? String(
                  labelByValue(contract.bandeiraCartao, bandeiraCartaoArray)
                )
              : "",
          numeroCartao:
            contract.formaPagamento == 2 ? String(contract.numeroCartao) : "",
          ValidadeCartao:
            contract.formaPagamento == 2
              ? String(
                  `${contract?.mesValidadeCartao}/${contract.anoValidadeCartao}`
                )
              : "",
          diaFaturaCartao:
            contract.formaPagamento == 2
              ? contract.adesao
                ? labelByValue(
                    contract?.diaFaturaCartao,
                    diaMensalidadeCartaoArray
                  )
                : String(getDayFromString(contract.dataFaturaCartao))
              : "",

          nomeConta:
            contract.formaPagamento == 1
              ? String(contract?.nomeTitularContaCorrente)
              : "",
          cpfConta:
            contract.formaPagamento == 1
              ? String(contract?.cpfTitularContaCorrente)
              : "",
          bancoConta:
            contract.formaPagamento == 1
              ? String(
                  labelByValue(contract.bancoContaCorrente, bancoContaArray)
                )
              : "",
          numeroConta:
            contract.formaPagamento == 1
              ? contract.bancoContaCorrente == 104
                ? `${contract.bancoCaixaTipo}${contract.numeroContaCorrente}`
                : String(contract.numeroContaCorrente)
              : "",
          agenciaConta:
            contract.formaPagamento == 1
              ? String(
                  contract.bancoContaCorrente == 41
                    ? `${contract.agenciaContaCorrente}-${contract.dvAgenciaContaCorrente}`
                    : contract.agenciaContaCorrente
                )
              : "",
          dvConta:
            contract.formaPagamento == 1
              ? String(contract.dvContaCorrente)
              : "",
          diaDebitoConta:
            contract.formaPagamento == 1
              ? contract.adesao
                ? labelByValue(
                    contract.diaDebitoConta,
                    diaMensalidadeBancoArray
                  )
                : String(getDayFromString(contract.dataDebitoConta))
              : "",

          // RESTRICAO FIADOR
          fiador: String(contract?.fiador ? "Sim" : "Não"),
          nomeFiador: String(
            contract?.fiador ? contract?.nomeFiador : "Sem fiador"
          ),
          cpfFiador: String(
            contract?.fiador ? contract?.cpfFiador : "Sem fiador"
          ),
          dataNascimentoFiador: String(
            contract.fiador
              ? masker(String(contract.nascimentoFiador), "00/00/0000") || ""
              : "Sem fiador"
          ),
          estadoCivilFiador: String(
            contract?.fiador
              ? labelByValue(contract?.estadoCivilFiador, estadoCivilArray) ||
                  ""
              : "Sem fiador"
          ),
          emailFiador: String(
            contract?.fiador ? contract?.emailFiador || "" : "Sem fiador"
          ),
          celularFiador: String(
            contract?.fiador ? contract?.celularFiador || "" : "Sem fiador"
          ),
          telefoneFiador: String(
            contract?.fiador ? contract?.telefoneFiador || "" : "Sem fiador"
          ),

          terceiro: contract.terceiro ? "Sim" : "Não",
          cpfTerceiro: contract.terceiro
            ? String(
                masker(String(contract.cpfTerceiro), "000.000.000-00") || "-"
              )
            : "-",
          nomeTerceiro: contract.terceiro
            ? String(contract.nomeTerceiro || "-")
            : "-",
          nascimentoTerceiro: contract.terceiro
            ? String(masker(contract.nascimentoTerceiro, "00/00/0000") || "-")
            : "-",
          nacionalidadeTerceiro: contract.terceiro
            ? String(contract.nacionalidadeTerceiro || "-")
            : "-",
          estadoCivilTerceiro: contract.terceiro
            ? String(contract.estadoCivilTerceiro || "-")
            : "-",
          nomeConjugeTerceiro: contract.terceiro
            ? String(contract.nomeConjugeTerceiro || "-")
            : "-",
          nomeMaeTerceiro: contract.terceiro
            ? String(contract.nomeMaeTerceiro || "-")
            : "-",
          nomePaiTerceiro: contract.terceiro
            ? String(contract.nomePaiTerceiro || "-")
            : "-",
          emailTerceiro: contract.terceiro
            ? String(contract.emailTerceiro || "-")
            : "-",
          celularTerceiro: contract.terceiro
            ? `(${contract.dddCelularTerceiro}) ${contract.celularTerceiro}` ||
              "-"
            : "-",
          telefoneTerceiro: contract.terceiro
            ? `(${contract.dddTelefoneTerceiro}) ${contract.telefoneTerceiro}` ||
              "-"
            : "-",
          formaAssinaturaTerceiro: contract.terceiro
            ? String(
                contract.formaAssinaturaTerceiro == 1
                  ? "Online (e-mail)"
                  : "Offline (Dedo)" || "-"
              )
            : "-",
        },
      },
    };
  }

  function getSignersEnvioAssinaturas() {
    const signers = [];

    if (contract?.formaAssinatura === 1 && contract?.email)
      signers.push({
        email: contract?.email,
        act: "1",
        foreign: "0",
        certificadoicpbr: "0",
        assinatura_presencial: "0",
        docauth: "0",
        docauthandselfie: "0",
        embed_methodauth: "email",
        embed_smsnumber: "",
        upload_allow: "0",
        upload_obs: "Contrato Coobrastur",
      });

    if (
      contract?.terceiro &&
      contract?.formaAssinaturaTerceiro === 1 &&
      contract?.emailTerceiro
    )
      signers.push({
        email: contract?.emailTerceiro,
        act: "1",
        foreign: "0",
        certificadoicpbr: "0",
        assinatura_presencial: "0",
        docauth: "0",
        docauthandselfie: "0",
        embed_methodauth: "email",
        embed_smsnumber: "",
        upload_allow: "0",
        upload_obs: "Contrato Coobrastur",
      });

    if (
      contract?.fiador &&
      contract?.formaAssinaturaFiador === 1 &&
      contract?.emailFiador
    )
      signers.push({
        email: contract?.emailFiador,
        act: "1",
        foreign: "0",
        certificadoicpbr: "0",
        assinatura_presencial: "0",
        docauth: "0",
        docauthandselfie: "0",
        embed_methodauth: "email",
        embed_smsnumber: "",
        upload_allow: "0",
        upload_obs: "Contrato Coobrastur",
      });

    return signers;
  }

  function consultaReativacao(contratoCoobrastur) {
    return new Promise(async (resolve, reject) => {
      if (contract.tipoVenda === 2) {
        if (contract?.resConsultaReativacao === true) {
          return resolve({
            next: true,
          });
        }

        if (contract?.resConsultaReativacao === false) {
          return reject({
            next: false,
            toDispatch: { status: 1 },
            message:
              "Associado consultado não pode reativar por ter planos ativos, ter cancelamentos compulsórios ou não ser cadastrado. Selecione tipo de venda normal.",
          });
        }

        await api
          .post(
            "AssociadoExistente",
            { cpf: contratoCoobrastur.cpfAssociado },
            { headers: { Authorization: token || "" } }
          )
          .then(async (resCoobrastur) => {
            console.log("AssociadoExistente", resCoobrastur.data);
            const { existente } = resCoobrastur?.data?.body;
            if (existente === undefined)
              return reject({
                next: false,
                message: "Erro no serviço de consulta de reativação",
              });

            if (existente === false) {
              return reject({
                next: false,
                toDispatch: { status: 1, resConsultaReativacao: false },
                message:
                  "Associado não pode reativar por ter planos ativos, ter cancelamentos compulsórios ou não ser cadastrado. Selecione tipo de venda normal.",
              });
            }

            return resolve({
              next: true,
              toDispatch: { resConsultaReativacao: true },
            });
          })
          .catch(async (e) => {
            return reject({
              next: false,
              message: "Erro interno no serviço de consulta para reativação",
            });
          });
      } else {
        return resolve({
          next: true,
        });
      }
    });
  }

  function consultaInadimplencia(contratoCoobrastur) {
    return new Promise(async (resolve, reject) => {
      if (contract?.restricao === false) {
        if (contract?.resConsultaInadimplencia === false) {
          return resolve();
        }

        if (contract?.resConsultaInadimplencia === true) {
          return reject({
            toDispatch: { status: 2 },
            message:
              "Associado com restrição Coobrastur, selecione alternativa",
          });
        }

        await apiCoobrastur
          .post(
            "AssociadoInadimplenciaAPP",
            { cpf: String(contratoCoobrastur.cpfAssociado) },
            { headers: { Authorization: token || "" } }
          )
          .then(async (resCoobrastur) => {
            console.log("AssociadoInadimplenciaAPP", resCoobrastur.data);
            const { inadimplente } = resCoobrastur?.data?.body;
            if (inadimplente === undefined)
              return reject({
                message:
                  "Erro no serviço de consulta de inadimplência Coobrastur",
              });

            if (inadimplente === true) {
              return reject({
                toDispatch: { status: 2, resConsultaInadimplencia: true },
                message:
                  "Associado consultado com inadimplência Coobrastur, selecione alternativa",
              });
            }

            return resolve({
              toDispatch: { resConsultaInadimplencia: false },
            });
          })

          .catch(async (e) => {
            return reject({
              message:
                "Erro interno no serviço de consulta a inadimplência Coobrastur",
            });
          });
      } else {
        return resolve();
      }
    });
  }

  function consultaRestricao(contratoCoobrastur) {
    return new Promise(async (resolve, reject) => {
      if (
        contract?.restricao === false &&
        !contract?.resConsultaInadimplencia
      ) {
        if (contract?.resConsultaRestricao === false) {
          return resolve();
        }

        if (contract?.resConsultaRestricao === true) {
          return reject({
            toDispatch: { status: 2 },
            message: "Associado com restrição no SPC, selecione alternativa",
          });
        }

        await apiCoobrastur
          .post(
            "consultaCDLApp",
            { cpf: contratoCoobrastur.cpfAssociado },
            { headers: { Authorization: token || "" } }
          )
          .then(async (resCoobrastur) => {
            console.log("consultaCDLApp", resCoobrastur.data);
            const { restricao } = resCoobrastur?.data?.body;
            if (restricao === undefined)
              return reject({
                message: "Erro no serviço de consulta a restrição SPC",
              });

            if (restricao === true) {
              return reject({
                toDispatch: { status: 2, resConsultaRestricao: true },
                message:
                  "Associado consultado com restrição SPC, selecione alternativa",
              });
            }

            return resolve({
              toDispatch: { resConsultaRestricao: false },
            });
          })

          .catch(async (e) => {
            return reject({
              message: "Erro interno no serviço de consulta a restrição SPC",
            });
          });
      } else {
        return resolve();
      }
    });
  }

  function consultaAssinaturas() {
    return new Promise(async (resolve, reject) => {
      if (
        contract?.formaAssinatura === 1 ||
        (contract?.fiador && contract?.formaAssinaturaFiador === 1) ||
        (contract?.terceiro && contract?.formaAssinaturaTerceiro === 1)
      ) {
        if (contract?.resEnvioAssinaturas === undefined) {
          return resolve();
        }

        if (contract?.resEnvioAssinaturas && contract?.resConsultaAssinaturas) {
          return resolve();
        }

        if (
          contract?.resEnvioAssinaturas &&
          !contract?.resConsultaAssinaturas
        ) {
          const uuid = contract?.resEnvioAssinaturas;

          const resConsultaAssinaturas = await apiCoobD4sign.post(
            "d4sign/consulta",
            {
              uuid,
            },
            {
              headers: {
                coobkey,
              },
            }
          );
          console.log("resConsultaAssinaturas", resConsultaAssinaturas.data);

          if (!resConsultaAssinaturas.data)
            return reject({
              message:
                "O serviço de verificar asssinatura via e-mail falhou, tente mais tarde.",
            });

          if (resConsultaAssinaturas.data.assinado === undefined)
            return reject({
              message:
                "A resposta do serviço de verificar asssinatura via e-mail falhou, tente mais tarde.",
            });

          if (!resConsultaAssinaturas.data.assinado) {
            return reject({
              toDispatch: { status: 3, resConsultaAssinaturas: false },
              message: `Aguarde a conclusão da(s) assinatura(s) via e-mail`,
            });
          }

          return resolve({
            toDispatch: { resConsultaAssinaturas: true },
          });
        }
      } else {
        return resolve();
      }
    });
  }

  async function envioAssinaturas(contratoCoobrastur) {
    return new Promise(async (resolve, reject) => {
      if (
        contract?.formaAssinatura === 1 ||
        (contract?.fiador && contract?.formaAssinaturaFiador === 1) ||
        (contract?.terceiro && contract?.formaAssinaturaTerceiro === 1)
      ) {
        if (contract?.resEnvioAssinaturas) {
          return resolve();
        }

        if (contract?.resEnvioAssinaturas === undefined) {
          const template = getTemplateEnvioAssinaturas();

          const documento = await getDocumentoEnvioAssinaturas(template);
          const signatarios = await getSignersEnvioAssinaturas();

          const headerEnvioAssinaturas = {
            headers: {
              coobkey,
            },
          };

          const bodyEnvioAssinaturas = {
            documento,
            signatarios,
            vendedor: {
              usuario: sign.data?.usuario || null,
              email: sign.data?.email || null,
            },
          };

          const resEnvioAssinaturas = await apiCoobD4sign.post(
            "d4sign/envio",
            bodyEnvioAssinaturas,
            headerEnvioAssinaturas
          );

          console.log("resEnvioAssinaturas", resEnvioAssinaturas.data);

          if (!resEnvioAssinaturas.data)
            return reject({
              message:
                "O envio de assinatura por e-mail falhou, tente mais tarde.",
            });

          const { uuid, envioDocumento, envioVendedor } =
            resEnvioAssinaturas.data;

          if (envioDocumento == false || envioDocumento == null)
            return reject({
              message:
                "O envio de assinatura por e-mail retornou falha, tente mais tarde.",
            });

          if (!uuid)
            return reject({
              message:
                "O envio de assinatura por e-mail falhou, tente mais tarde...",
            });

          const mensagemAdicional =
            envioVendedor === undefined
              ? "Aguarde os signatário(s) assinarem via e-mail."
              : envioVendedor === null || envioVendedor === false
              ? "Fique em contato com cliente para saber quando enviar a Coobrastur."
              : `Cheque sempre seu e-mail ${sign.data.email}, vamos avisar quando os contratos forem assinados para você enviar a Coobrastur.`;

          return reject({
            toDispatch: { resEnvioAssinaturas: uuid, status: 3 },
            message: "Assinaturas enviadas. " + mensagemAdicional,
          });
        }
      } else {
        return resolve();
      }
    });
  }

  function consultaCoobrastur(contratoCoobrastur) {
    return new Promise(async (resolve, reject) => {
      const contrato = {
        ...contratoCoobrastur,
        assinaturaAssociado: String(
          contract.formaAssinatura === 1
            ? contract?.resEnvioAssinaturas || ""
            : contract?.assinatura
        ),
        assinaturaTerceiro: contratoCoobrastur.terceiro
          ? String(
              contract?.formaAssinaturaTerceiro === 1
                ? contract?.resEnvioAssinaturas || ""
                : contract?.assinaturaTerceiro
            )
          : "",
        assinaturaFiador: contratoCoobrastur.assinaturaFiador
          ? String(
              contract?.formaAssinaturaFiador === 1
                ? contract?.resEnvioAssinaturas || ""
                : contract?.assinaturaFiador
            )
          : "",
      };

      console.log(token);
      console.log(JSON.stringify(contrato));

      // if (contract?.resConsultaCoobrastur === true) return resolve();

      await api
        .post("ValidaVendaExternaAppContratoNovo", contrato, {
          headers: { Authorization: token || "" },
        })
        .then(async (resConsultaCoobrastur) => {
          console.log(
            "ValidaVendaExternaAppContratoNovo",
            resConsultaCoobrastur.data
          );

          if (
            !resConsultaCoobrastur.data ||
            resConsultaCoobrastur.status === 500
          ) {
            return reject({
              toDispatch: {
                resConsultaCoobrastur: null,
              },
              message: "Houve um erro interno, tente mais tarde.",
            });
          }

          console.log(JSON.stringify(resConsultaCoobrastur.data));

          if (resConsultaCoobrastur.data?.body?.mensagem)
            return reject({
              toDispatch: {
                resConsultaCoobrastur: false,
              },
              message:
                resConsultaCoobrastur.data.body?.mensagem ||
                "Houve um erro na validação, verifique os dados do contrato.",
            });

          if (resConsultaCoobrastur.data?.body?.sucesso)
            return resolve({
              toDispatch: {
                resConsultaCoobrastur: true,
              },
            });
        })
        .catch(async (e) => {
          console.log(e.message);

          return reject({
            toDispatch: {
              resConsultaCoobrastur: null,
            },
            message:
              "Falha na consulta do contrato com a coobrastur. Tente deslogar, e acessar novamente.",
          });
        });
    });
  }

  async function envioCoobrastur(contratoCoobrastur) {
    if (!contract.resEnvioCoobrastur) {
      return new Promise(async (resolve, reject) => {
        const contrato = {
          ...contratoCoobrastur,
          assinaturaAssociado: String(
            contract.formaAssinatura === 1
              ? contract?.resEnvioAssinaturas
              : contract?.assinatura
          ),
          assinaturaTerceiro: contratoCoobrastur.terceiro
            ? String(
                contract?.formaAssinaturaTerceiro === 1
                  ? contract?.resEnvioAssinaturas
                  : contract?.assinaturaTerceiro
              )
            : "",
          assinaturaFiador: contratoCoobrastur.assinaturaFiador
            ? String(
                contract?.formaAssinaturaFiador === 1
                  ? contract?.resEnvioAssinaturas
                  : contract?.assinaturaFiador
              )
            : "",
        };

        await api
          .post("InsertVendaExternaAppContratoNovo", contrato, {
            headers: { Authorization: token || "" },
          })
          .then(async (resEnvioCoobrastur) => {
            console.log(
              "InsertVendaExternaAppContratoNovo",
              resEnvioCoobrastur.data
            );

            if (!resEnvioCoobrastur.data || resEnvioCoobrastur.status === 500) {
              return reject({
                message: "Houve um erro interno, tente mais tarde.",
              });
            }

            if (resEnvioCoobrastur.data.body?.code == 11) {
              const idSuporte = resEnvioCoobrastur?.data?.body?.idSuporte;

              return resolve({
                isPending: true,
                toDispatch: {
                  status: 4,
                  resEnvioCoobrastur: idSuporte,
                  resEnvioCoobrasturAt: new Date(),
                },
              });
            }

            if (resEnvioCoobrastur?.data?.code === 1) {
              return reject({
                message:
                  "Sessão expirada (1 dia), faça o acesso novamente para enviar.",
              });
            }

            if (resEnvioCoobrastur?.data?.body?.code === 10) {
              dispatch(signOut());
              navigation.set.screen("login");

              return reject({
                message: "Você não está mais habilitado a usar o app.",
              });
            }

            if (resEnvioCoobrastur?.data?.code === 6) {
              return reject({
                message:
                  "Necessário mudar forma de pagamento da mensalidade ou selecionar aditamento/fiador.",
              });
            }

            if (resEnvioCoobrastur?.data?.body?.code == 2) {
              return reject({
                message:
                  "Associados com planos cancelados são isentos da cobrança de adesão. Selecione tipo de contrato como reativação.",
              });
            }

            const idVenda = resEnvioCoobrastur?.data?.body?.idVenda;

            if (idVenda === undefined) {
              return reject({
                message: `Houve um erro ao enviar, tente mais tarde. (${
                  typeof resEnvioCoobrastur.data === "string"
                    ? resEnvioCoobrastur.data.body.descricao ||
                      "Contate o suporte"
                    : JSON.stringify(resEnvioCoobrastur.data.body.descricao) ||
                      "Contate o suporte"
                })`,
              });
            }

            return resolve({
              isPending: false,
              toDispatch: {
                status: 4,
                resEnvioCoobrastur: idVenda,
                resEnvioCoobrasturAt: new Date(),
              },
            });
          })
          .catch(async (e) => {
            return reject({
              message:
                "Falha interna no envio a coobrastur. Tente deslogar, e acessar novamente.",
            });
          });
      });
    } else {
      return resolve();
    }
  }

  async function getContratoCoobrastur() {
    return new Promise(async (resolve, reject) => {
      resolve({
        contratoString: JSON.stringify(contract),

        // ASSOCIADO - DADOS PESSOAIS
        cpfAssociado: String(contract.cpf),
        nomeAssociado: String(contract.nome),

        // ASSOCIADO - ENDEREÇO
        ufAssociado: contract.uf ? String(contract.uf) : "",

        cepAssociado:
          contract.adesao && contract?.meioAdesao && contract.cep
            ? String(contract.cep)
            : "",

        cidadeAssociado:
          contract.adesao && contract?.meioAdesao && contract.cidade
            ? String(contract.cidade)
            : "",
        bairroAssociado:
          contract.adesao && contract?.meioAdesao && contract.bairro
            ? String(contract.bairro)
            : "",
        ruaAssociado:
          contract.adesao && contract?.meioAdesao && contract.rua
            ? String(contract.rua)
            : "",
        numeroRuaAssociado:
          contract.adesao && contract?.meioAdesao && contract.numeroRua
            ? String(contract.numeroRua)
            : "",
        complementoRuaAssociado:
          contract.adesao && contract?.meioAdesao && contract.complementoRua
            ? String(contract.complementoRua)
            : "",

        // ASSOCIADO - CONTATO
        emailAssociado: String(contract.email),
        dddCelularAssociado: String(contract.dddCelular || ""),
        celularAssociado: String(
          String(contract.celular).replace(/\D/gim, "") || ""
        ),

        // ASSOCIADO - INDICAÇÃO
        indicacao: Boolean(contract.indicador || false),

        // INDICADOR - DADOS PESSOAIS
        nomeIndicador: contract.indicador
          ? String(contract.nomeIndicador || "")
          : "",
        cpfIndicador: contract.indicador
          ? String(String(contract.cpfIndicador).replace(/\D/gim, "") || "")
          : "",

        // INDICADOR - CONTATO
        emailIndicador: contract.indicador
          ? String(contract.emailIndicador || "")
          : "",
        dddCelularIndicador: contract.indicador
          ? String(contract.dddCelularIndicador || "")
          : "",
        celularIndicador: contract.indicador
          ? String(String(contract.celularIndicador).replace(/\D/gim, "") || "")
          : "",

        // ASSOCIADO - VENDA
        idTipoVenda: Boolean(contract.aditamento)
          ? 2
          : Number(getIdTipoVendaCoobrastur(Number(contract.tipoVenda))),

        // PLANO
        idPlano: Number(contract.plano),
        diariasPlano: Number(contract.qtdDiarias),
        familiaPlano: Boolean(contract.familia || false),

        // ASSOCIADO - PLANO - MENSALIDADES
        idFormaPagamentoMensalidade: Number(contract.formaPagamento),

        // ASSOCIADO - PLANO - MENSALIDADES - CONTA
        cpfConta: String(
          contract.formaPagamento == 1
            ? String(contract.cpfTitularContaCorrente)
            : ""
        ),
        nomeConta: String(contract.nomeTitularContaCorrente || ""),
        idBancoConta: Number(contract.bancoContaCorrente) || null,
        numeroConta:
          contract.formaPagamento == 1
            ? contract.bancoContaCorrente == 104
              ? `${contract.bancoCaixaTipo}${contract.numeroContaCorrente}`
              : String(contract.numeroContaCorrente)
            : "",
        dvConta: String(contract.dvContaCorrente || ""),
        agenciaConta:
          contract.formaPagamento == 1
            ? String(
                contract.bancoContaCorrente == 41
                  ? `${contract.agenciaContaCorrente}-${contract.dvAgenciaContaCorrente}`
                  : contract.agenciaContaCorrente
              )
            : "",

        diaDebitoConta:
          contract.formaPagamento == 1
            ? contract.adesao
              ? Number(contract.diaDebitoConta || 0)
              : Number(getDayFromString(contract.dataDebitoConta) || 0)
            : null,

        mesDebitoConta:
          contract.formaPagamento == 1
            ? contract.adesao
              ? Number(0)
              : Number(getMonthFromString(contract.dataDebitoConta) || 0)
            : null,

        anoDebitoConta:
          contract.formaPagamento == 1
            ? contract.adesao
              ? Number(0)
              : Number(getYearFromString(String(contract.dataDebitoConta)) || 0)
            : null,

        dataVendaSemDados:
          contract.formaPagamento == 9 && contract.adesao == false
            ? String(
                masker(String(contract.dataVendaSemDados), "00/00/0000") || ""
              )
            : null,

        // ASSOCIADO - PLANO - MENSALIDADES - CARTAO
        cpfCartao: String(
          String(contract.cpfTitularCartao).replace(/\D/gim, "") || ""
        ),
        nomeCartao: String(contract.nomeTitularCartao || ""),
        idBandeiraCartao: Number(contract.bandeiraCartao || 0),
        numeroCartao: String(
          String(contract.numeroCartao).replace(/\D/gim, "") || ""
        ),
        validadeCartao:
          contract.mesValidadeCartao && contract.anoValidadeCartao
            ? String(
                contract.mesValidadeCartao + (contract.anoValidadeCartao - 2000)
              )
            : "",
        cvvCartao: String(contract.cvvCartao || ""),

        diaFaturaCartao:
          contract.formaPagamento == 2
            ? contract.adesao
              ? Number(contract.diaFaturaCartao || 0)
              : Number(getDayFromString(contract.dataFaturaCartao) || 0)
            : null,

        mesFaturaCartao:
          contract.formaPagamento == 2
            ? contract.adesao
              ? Number(0)
              : Number(getMonthFromString(contract.dataFaturaCartao) || 0)
            : null,

        anoFaturaCartao:
          contract.formaPagamento == 2
            ? contract.adesao
              ? Number(0)
              : Number(getYearFromString(contract.dataFaturaCartao) || 0)
            : null,

        // ASSOCIADO - ADESAO
        adesao: Boolean(contract.adesao || false),

        // ASSOCIADO - ADESAO - PAGAMENTO
        valorPagamentoAdesao: contract.adesao
          ? Number(contract.valorAdesao || 0)
          : 0,
        idMeioPagamentoAdesao: contract.adesao
          ? Number(contract.meioAdesao || 0)
          : 0,

        // ASSOCIADO - ADESAO - PAGAMENTO - VENDEDOR
        idFormaPagamentoAdesaoVendedor: contract.adesao
          ? contract.meioAdesao == 1
            ? Number(contract.formaAdesaoVendedor)
            : 0
          : 0,
        parcelasPagamentoAdesaoVendedor: contract.adesao
          ? contract.meioAdesao == 1
            ? Number(contract.parcelasAdesaoVendedor)
            : 0
          : 0,

        dataPagamentoAdesaoVendedor: contract.adesao
          ? String(masker(String(contract.dataVendedor), "00/00/0000") || "")
          : "",
        obsAdesaoVendedor: contract.adesao
          ? String(contract.obsVendedor || "")
          : "",

        // ASSOCIADO - ADESAO - PAGAMENTO - COOBRASTUR
        idFormaPagamentoAdesaoCoobrastur: contract.adesao
          ? contract.meioAdesao == 2
            ? Number(contract.formaAdesaoCoobrastur)
            : 0
          : 0,
        parcelasPagamentoAdesaoCoobrastur: contract.adesao
          ? contract.meioAdesao == 2
            ? Number(contract.parcelasAdesaoCoobrastur)
            : 0
          : 0,
        dataPagamentoAdesaoCoobrastur: contract.adesao
          ? String(masker(String(contract.dataCoobrastur), "00/00/0000") || "")
          : "",
        obsAdesaoCoobrastur: contract.adesao
          ? String(contract.obsCoobrastur || "")
          : "",

        // ASSOCIADO - PLANO - MENSALIDADES - CONTA - TERCEIRO
        terceiro: Boolean(contract.terceiro || false),

        // ASSOCIADO - PLANO - MENSALIDADES - CONTA - TERCEIRO - DADOS PESSOAIS
        cpfTerceiro: contract.terceiro
          ? String(String(contract.cpfTerceiro).replace(/\D/gim, "") || "")
          : "",
        nomeTerceiro: contract.terceiro ? String(contract.nomeTerceiro) : "",
        nascimentoTerceiro: contract.terceiro
          ? masker(String(contract.nascimentoTerceiro), "00/00/0000")
          : "",
        nacionalidadeTerceiro: contract.terceiro
          ? String(contract.nacionalidadeTerceiro)
          : "",
        idEstadoCivilTerceiro: contract.terceiro
          ? Number(contract.estadoCivilTerceiro || 0)
          : 0,
        conjugeTerceiro: contract.terceiro
          ? String(contract.nomeConjugeTerceiro)
          : "",
        maeTerceiro: contract.terceiro ? String(contract.nomeMaeTerceiro) : "",
        paiTerceiro: contract.terceiro ? String(contract.nomePaiTerceiro) : "",

        // ASSOCIADO - PLANO - MENSALIDADES - CONTA - TERCEIRO - CONTATO
        emailTerceiro: contract.terceiro ? String(contract.emailTerceiro) : "",
        dddCelularTerceiro: contract.terceiro
          ? String(contract.dddCelularTerceiro)
          : "",
        celularTerceiro: contract.terceiro
          ? String(String(contract.celularTerceiro).replace(/\D/gim, "") || "")
          : "",
        dddTelefoneTerceiro: contract.terceiro
          ? String(contract.dddTelefoneTerceiro)
          : "",
        telefoneTerceiro: contract.terceiro
          ? String(String(contract.telefoneTerceiro).replace(/\D/gim, "") || "")
          : "",

        // ASSOCIADO - PLANO - MENSALIDADES - CONTA - TERCEIRO - ASSINATURA
        idFormaAssinaturaTerceiro: contract.terceiro
          ? Number(contract.formaAssinaturaTerceiro || 0)
          : 0,
        assinaturaTerceiro: contract.terceiro
          ? String(contract.assinaturaTerceiro)
          : "",

        // ASSOCIADO - PLANO - RESTRICAO
        retricaoAssociado: Boolean(contract.restricao || false),

        // ASSOCIADO - PLANO - RESTRICAO - ADITAMENTO
        aditamento: Boolean(contract.aditamento || false),

        // ASSOCIADO - PLANO - RESTRICAO - ADITAMENTO - FORMA
        idFormaAditamento: contract.aditamento
          ? Number(contract.formaAditamento || 0)
          : 0,

        // ASSOCIADO - PLANO - RESTRICAO - FIADOR
        fiador: Boolean(contract.fiador || false),

        // FIADOR - DADOS PESSOAIS
        cpfFiador: contract.fiador
          ? String(String(contract.cpfFiador).replace(/\D/gim, "") || "")
          : "",

        nomeFiador: contract.fiador ? String(contract.nomeFiador || "") : "",
        dataNascimentoFiador: contract.fiador
          ? masker(String(contract.nascimentoFiador), "00/00/0000") || ""
          : "",
        nacionalidadeFiador: contract.fiador
          ? String(contract.nacionalidadeFiador || "")
          : "",
        idEstadoCivilFiador: contract.fiador
          ? Number(contract.estadoCivilFiador || 0)
          : 0,
        conjugeFiador: contract.fiador
          ? String(contract.nomeConjugeFiador || "")
          : "",
        maeFiador: contract.fiador ? String(contract.nomeMaeFiador || "") : "",
        paiFiador: contract.fiador ? String(contract.nomePaiFiador || "") : "",

        // FIADOR - CONTATO
        emailFiador: contract.fiador ? String(contract.emailFiador || "") : "",
        dddCelularFiador: contract.fiador
          ? String(contract.dddCelularFiador || "")
          : "",
        celularFiador: contract.fiador
          ? String(String(contract.celularFiador).replace(/\D/gim, "") || "")
          : "",
        dddTelefoneFiador: contract.fiador
          ? String(contract.dddTelefoneFiador || "")
          : "",
        telefoneFiador: contract.fiador
          ? String(String(contract.telefoneFiador).replace(/\D/gim, "") || "")
          : "",

        // FIADOR - ASSINATURA
        idFormaAssinaturaFiador: contract.fiador
          ? Number(contract.formaAssinaturaFiador || 0)
          : 0,
        assinaturaFiador: contract.fiador
          ? String(contract.assinaturaFiador || "")
          : "",

        // ASSOCIADO - ASSINATURA
        idFormaAssinaturaAssociado: Number(contract.formaAssinatura),
        assinaturaAssociado: contract.assinatura || "",

        // VENDEDOR
        codigoVendedor: String(codigoVendedor) || 0,
        usuarioVendedor: String(usuarioVendedor),
      });
    });
  }

  function checkToDispatch(result) {
    if (result?.toDispatch)
      dispatch(setContractLoading({ ...contract, ...result.toDispatch }));
  }

  const submit = async () => {
    setIsLoading(true);

    try {
      dispatch(setContractLoading({ ...contract, loading: true }));

      const contratoCoobrastur = await getContratoCoobrastur();

      const resReativacao = await consultaReativacao(contratoCoobrastur);
      checkToDispatch(resReativacao);

      const resInadimplencia = await consultaInadimplencia(contratoCoobrastur);
      checkToDispatch(resInadimplencia);

      const resRestricao = await consultaRestricao(contratoCoobrastur);
      checkToDispatch(resRestricao);

      const resConsultaCoobrastur = await consultaCoobrastur(
        contratoCoobrastur
      );
      checkToDispatch(resConsultaCoobrastur);

      console.log(resConsultaCoobrastur);

      const resAssinaturas = await consultaAssinaturas(contratoCoobrastur);
      checkToDispatch(resAssinaturas);

      const resEnvioAssinaturas = await envioAssinaturas(contratoCoobrastur);
      checkToDispatch(resEnvioAssinaturas);

      const resEnvioCoobrastur = await envioCoobrastur(contratoCoobrastur);

      dispatch(
        setContractLoading({
          ...contract,
          loading: false,
          ...resEnvioCoobrastur.toDispatch,
        })
      );

      const isPending = resEnvioCoobrastur.isPending;

      useDialog({
        title: isPending ? "Enviado!" : "Sucesso!",
        message: isPending
          ? "Enviamos este contrato ao suporte Coobrastur, aguarde o retorno sobre o registro da venda."
          : "O contrato foi enviado a Coobrastur.",
      });

      setIsLoading(false);
    } catch (error) {
      if (error.message) useDialog({ message: String(error.message) });

      if (error.toDispatch) {
        dispatch(
          setContractLoading({
            ...contract,
            loading: false,
            ...error.toDispatch,
          })
        );
      } else {
        dispatch(setContractLoading({ ...contract, loading: false }));
      }

      setIsLoading(false);
    }
  };

  const reSubmit = async () => {
    setIsLoading(true);
    dispatch(setContractLoading({ ...contract, loading: true }));

    const contratoCoobrastur = await getContratoCoobrastur();

    const dados = {
      in_cpf: contratoCoobrastur.cpfAssociado,
      in_nome: contratoCoobrastur.nomeAssociado,
      in_ref: ref,
      in_tpplcodigo: contratoCoobrastur.idPlano,
      retornaCodigo: "",
    };

    await api
      .get(
        "https://telemarketing.coobrastur.com.br/wsAppVendasV2/Contrato.asmx/ReenviarContrato",
        {
          params: {
            dados,
          },
        },
        { headers: { Authorization: token || "" } }
      )
      .then(async (res) => {
        if (res.data === '{"TransCodigo":1}') {
          useDialog({
            title: "Alerta",
            message: "O contrato já se encontra no Siscoob.",
          });
        }
        if (res.data === '{"TransCodigo":-1}') {
          useDialog({
            title: "Alerta",
            message:
              "O contrato não se encontra no Siscoob, deseja envia-lo agora?. ",
            options: [
              {
                text: "Sim",
                onPress: () => submit(),
              },
              {
                text: "Não",
                style: "cancel",
              },
            ],
          });
        }
      })
      .catch(async () => {
        useDialog({
          title: "Erro interno",
          message: "Algo deu errado, tente novamente mais tarde.",
        });
      });

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.nome}>{contract?.nome}</Text>
        <Text style={styles.cpf}>
          {masker(contract?.cpf, "000.000.000-00")}
        </Text>

        <View style={styles.planContainer}>
          <Text style={styles.planText}>
            {labelByValue(contract?.plano, planoArray)}
          </Text>
        </View>
      </View>

      {contract?.status > 0 && (
        <View style={styles.statusContainer}>
          <Text style={styles.statusText}>
            {getStatusName(contract?.status)}
          </Text>
        </View>
      )}

      <View style={styles.footer}>
        <Text style={styles.data}>
          {format(
            new Date(contract?.updatedAt || contract?.createdAt),
            "dd/MM/Y HH:mm",
            {
              locale: ptBr,
            }
          )}
        </Text>

        <View style={styles.actions}>
          {/* <TouchableOpacity
            style={styles.button}
            onPress={() => navigation.set.screen("contractsShow", { ref })}
          >
            <Text style={styles.buttonText}>Ver</Text>
          </TouchableOpacity> */}

          {contract?.status < 4 &&
            (isLoading ? (
              <>
                <Text style={styles.buttonTextDisabled}>Carregando</Text>

                {contract.formaAssinatura === 1 && contract.status === 3 && (
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() => {
                      setIsLoading(true);
                      dispatch(
                        setContractLoading({ ...contract, loading: false })
                      );
                    }}
                  >
                    <Text style={styles.buttonText}>Cancelar</Text>
                  </TouchableOpacity>
                )}

                {contract.formaAssinatura === 2 && (
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() =>
                      dispatch(
                        setContractLoading({ ...contract, loading: false })
                      )
                    }
                  >
                    <Text style={styles.buttonText}>Cancelar</Text>
                  </TouchableOpacity>
                )}
              </>
            ) : (
              <>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() =>
                    navigation.set.screen("contractsShow", { ref })
                  }
                >
                  <Text style={styles.buttonText}>Ver</Text>
                </TouchableOpacity>

                {contract?.status < 3 && (
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() =>
                      navigation.set.screen("contractsUpdate", { ref })
                    }
                  >
                    <Text style={styles.buttonText}>Editar</Text>
                  </TouchableOpacity>
                )}

                <TouchableOpacity style={styles.button} onPress={submit}>
                  <Text style={styles.buttonText}>Enviar</Text>
                </TouchableOpacity>
              </>
            ))}

          {contract?.status == 4 &&
            (isLoading ? (
              <>
                <Text style={styles.buttonTextDisabled}>Carregando</Text>
              </>
            ) : (
              <>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() =>
                    navigation.set.screen("contractsShow", { ref })
                  }
                >
                  <Text style={styles.buttonText}>Ver </Text>
                </TouchableOpacity>
                {contract?.status == 4 && (
                  <TouchableOpacity style={styles.button} onPress={reSubmit}>
                    <Text style={styles.buttonText}>Verificar </Text>
                  </TouchableOpacity>
                )}
              </>
            ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    marginVertical: 10,
    marginHorizontal: 10,
    backgroundColor: "rgb(250,250,255)",
    borderRadius: 10,
    shadowColor: "#223",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-end",
    alignItems: "flex-end",
  },

  content: {
    flex: 1,
    width: "100%",
    padding: 15,
    alignSelf: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
  },

  nome: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#444",
  },

  cpf: {
    color: "#999",
  },

  planContainer: {
    marginTop: 15,
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 50,
    backgroundColor: colors.primary,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  planText: {
    fontSize: 14,
    color: "#fff",
    textTransform: "uppercase",
    fontStyle: "italic",
  },

  footer: {
    marginTop: 0,
    padding: 10,
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: "#cfcfcf",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },

  data: {
    color: "#999",
    fontSize: 14,
  },

  actions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingHorizontal: 5,
  },

  button: {
    backgroundColor: "transparent",
    paddingHorizontal: 5,
    // paddingVertical: 5,
  },

  buttonText: {
    color: colors.primary,
    fontSize: 16,
  },

  buttonTextDisabled: {
    color: "#999",
    fontSize: 16,
  },

  statusContainer: {
    position: "absolute",
    top: -12,
    right: -12,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    borderRadius: 20,
    marginTop: 0,
    paddingVertical: 5,
    paddingHorizontal: 15,
    minWidth: "50%",
    backgroundColor: "#fff",
    flexDirection: "row",
    shadowColor: "#223",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 1,
  },

  statusText: {
    textAlign: "center",
    fontSize: 12,
    color: "#999",
    fontStyle: "italic",
  },
});

export default Card;
