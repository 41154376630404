import React from "react";
import { View, Text, StyleSheet } from "react-native";

const Description = ({ value }) => {
  return (
    <View style={styles.descriptionContainer}>
      <Text style={styles.description}>{value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  descriptionContainer: {
    marginTop: 5,
    paddingHorizontal: 20,
    paddingBottom: 20,
  },

  description: {
    color: "#999",
  },
});

export default Description;
