import React, { useRef, useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Image,
  Text,
  TouchableOpacity,
  Animated,
} from "react-native";

import welcomeImg from "../../../assets/welcome.png";

import colors from "../../../colors.json";

import { useDispatch, useSelector } from "react-redux";

import { disableWelcome, enableWelcome } from "../../redux/welcome/actions";

import { NavigationContext } from "../../context/Navigation";

const Welcome = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.welcome);
  const sign = useSelector((state) => state.sign);

  const navigation = useContext(NavigationContext);

  const [animated, setAnimated] = useState(false);
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: animated ? 1 : 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [animated]);

  if (sign?.data?.token) {
    navigation.set.screen("home");
  }

  const enterHandler = () => {
    dispatch(enableWelcome());

    navigation.set.screen("login");
  };

  const disableHandler = () => {
    dispatch(disableWelcome());

    navigation.set.screen("login");
  };

  useEffect(() => {
    // if (state.disabled) return navigation.navigate("login");
  }, []);

  if (navigation.get.screen() !== "welcome") return <></>;

  return (
    <Animated.View style={[styles.container, { opacity: animatedOpacity }]}>
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={welcomeImg} />
      </View>

      <Text style={styles.title}>Bem-vindo</Text>

      <Text style={styles.description}>Trazer associados agora é fácil.</Text>

      <TouchableOpacity style={styles.enterButton} onPress={enterHandler}>
        <Text style={styles.enterText}>Entrar</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.disableButton} onPress={disableHandler}>
        <Text style={styles.disableText}>Não mostrar mais</Text>
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(240,240,245)",
  },

  imageContainer: {
    flex: 1,
    paddingHorizontal: 40,
    maxHeight: 200,
    width: "100%",
  },

  image: {
    flex: 1,
    resizeMode: "contain",
    width: "100%",
    height: "100%",
    borderRadius: 20,
  },

  title: {
    marginTop: 40,
    fontSize: 32,
    lineHeight: 32,
    fontWeight: "800",
    color: "#444",
  },

  description: {
    marginTop: 10,
    fontSize: 18,
    lineHeight: 20,
    color: "#999",
    paddingHorizontal: 20,
    textAlign: "center",
  },

  enterButton: {
    marginTop: 40,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: 50,
    minWidth: 140,
    borderRadius: 50,
    backgroundColor: colors.primary,
  },

  enterText: {
    color: "#fff",
    fontSize: 18,
  },

  disableButton: {
    marginTop: 40,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    padding: 15,
  },

  disableText: {
    textDecorationLine: "underline",
    textDecorationColor: "#999",
    color: "#999",
  },
});

export default Welcome;
