import React, { useState, createContext } from "react";

export const NavigationContext = createContext();

const Navigation = (props) => {
  const [navigation, setNavigation] = useState({
    screen: "welcome",
    params: {},
    history: [],
  });

  const useNavigation = {
    set: {
      screen: (newScreen, params) => {
        const oldHistory =
          navigation.history.length >= 3
            ? [
                navigation.history[2],
                navigation.history[1],
                navigation.history[0],
              ]
            : navigation.history;

        setNavigation({
          screen: newScreen,
          params: params || navigation.params,
          history: [navigation.screen, ...oldHistory],
        });
      },

      goBack: () =>
        setNavigation({
          ...navigation,
          screen: navigation.history[0] || "home",
        }),
    },

    get: {
      screen: () => navigation.screen,
      params: () => navigation.params,
    },
  };

  return (
    <NavigationContext.Provider value={useNavigation}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export default Navigation;
