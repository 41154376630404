import React from "react";
import { StyleSheet, View, Text } from "react-native";

const Section = ({ value }) => (
  <View style={styles.hrContainer}>
    <View style={styles.hrLine} />
    {value && <Text style={styles.hrTitle}>{value}</Text>}
    <View style={styles.hrLine} />
  </View>
);

const styles = StyleSheet.create({
  hrContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginTop: 10,
  },

  hrLine: {
    borderBottomWidth: 1,
    borderColor: "#999",
    height: 1,
    flex: 1,
  },

  hrTitle: {
    color: "#444",
    fontSize: 16,
    fontWeight: "bold",
    margin: 5,
  },
});

export default Section;
