import React, { useRef, useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Button,
  TouchableOpacity,
  Alert,
  Image,
  Dimensions,
  Animated,
} from "react-native";

import { useSelector, useDispatch } from "react-redux";
import { setContractLoading } from "../../redux/contracts/actions";

import {
  restricaoArray,
  booleanArray,
  ufArray,
  tipoVendaArray,
  planoArray,
  planoDescricaoArray,
  diariasArray,
  diariasGoArray,
  bandeiraCartaoArray,
  formaPagamentoMensalidadeArray,
  diaMensalidadeBancoArray,
  diaMensalidadeCartaoArray,
  bancoContaArray,
  bancoCaixaTipoArray,
  estadoCivilArray,
  formaAssinaturaArray,
  adesaoMeioArray,
  formaPagamentoAdesaoVendedorArray,
  formaPagamentoAdesaoCoobrasturArray,
  parcelasPagamentoAdesaoArray,
  formaAditamentoArray,
} from "./datas";

import {
  getParcelasAdesaoBoletoCoobrasturArray,
  getMensalidade,
  getDataPrimeiraMensalidade,
  getDataLiberacaoAditamento,
  labelByValue,
  formatReal,
  masker,
} from "../../utils/functions";

import colors from "../../../colors.json";

import { format } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

import TextOut from "../../utils/outputs/Text";
import DivisorOut from "../../utils/outputs/Divisor";
import DescriptionOut from "../../utils/outputs/Description";

import { NavigationContext } from "../../context/Navigation";

import Header from "../../utils/outputs/Header";

const Show = () => {
  const navigation = useContext(NavigationContext);
  const { ref } = navigation.get.params();

  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.contracts.data);

  const select = contracts?.filter((item) => item.ref === ref && item);
  const contract = select[0];

  const sign = useSelector((state) => state.sign);

  const resetLoading = () =>
    dispatch(setContractLoading({ ...contract, status: 0, loading: false }));

  console.log(contract);

  const edit = () => {
    navigation.set.screen("contractsUpdate", { ref });
  };

  const goBack = () => {
    // resetLoading();
    navigation.set.screen("contractsIndex");
  };

  const [animated, setAnimated] = useState(false);
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: animated ? 1 : 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [animated]);

  return (
    <View style={styles.container}>
      <Header
        title="Contrato"
        headerRight={{
          text: "Fechar",
          callback: () => navigation.set.screen("contractsIndex"),
        }}
      />

      <Animated.ScrollView
        style={[styles.content, { opacity: animatedOpacity }]}
      >
        <TextOut
          style={{ textTransform: "uppercase" }}
          label="Referência"
          value={contract?.ref}
        />

        <DivisorOut value="Dados pessoais" />

        <TextOut label="CPF" value={contract.cpf} />

        <TextOut label="Nome" value={contract.nome} />

        <DivisorOut value="Contato" />

        <TextOut label="E-mail" value={contract.email} />

        <TextOut
          label="Celular"
          value={`(${contract.dddCelular}) ${contract.celular}`}
        />

        <DivisorOut value="Endereço" />
          <TextOut label="CEP" value={masker(contract?.cep, "00000-000")} />
          <TextOut label="UF" value={contract?.uf} />
          <TextOut label="Cidade" value={contract?.cidade} />
          <TextOut label="Bairro" value={contract?.bairro} />
          <TextOut label="Rua" value={contract?.rua} />
          <TextOut label="Número" value={contract?.numeroRua} />
          <TextOut label="Complemento" value={contract?.complementoRua} />
 

        <DivisorOut value="Indicação" />

        <TextOut label="Indicador" value={contract.indicador} />

        {contract.indicador && (
          <>
            <TextOut label="CPF  (indicador)" value={contract.cpfIndicador} />

            <TextOut label="Nome (indicador)" value={contract.nomeIndicador} />

            <TextOut
              label="E-mail (indicador)"
              value={contract.emailIndicador}
            />

            <TextOut
              label="Celular (indicador)"
              value={contract.celularIndicador}
            />
          </>
        )}

        <DivisorOut value="Associação" />

        <TextOut
          label="Tipo da venda"
          value={labelByValue(contract.tipoVenda, tipoVendaArray)}
        />

        <TextOut
          label="Plano"
          value={labelByValue(contract.plano, planoArray)}
        />

        <DescriptionOut
          value={labelByValue(contract.plano, planoDescricaoArray)}
        />

        <TextOut label="Qtd. diárias" value={contract.qtdDiarias} />

        <TextOut label="Familia" value={contract.familia} />

        {contract.familia && (
          <DescriptionOut value="O plano inclui 3 hóspedes." />
        )}

        {contract.familia === false && (
          <DescriptionOut value="O plano inclui 2 hóspedes." />
        )}

        {contract.adesao && (
          <>
            <DivisorOut value="Adesão" />

            <TextOut
              label="Valor"
              value={contract.valorAdesao ? "R$ " + contract.valorAdesao : ""}
            />

            {sign.data.pagamentoAdesao && (
              <TextOut
                style={styles.capitalizeText}
                label="Meio de pagamento"
                value={labelByValue(contract.meioAdesao, adesaoMeioArray)}
              />
            )}
          </>
        )}

        {contract.adesao && contract.meioAdesao == 1 && (
          <>
            <TextOut
              label="Forma de pagamento"
              value={labelByValue(
                contract.formaAdesaoVendedor,
                formaPagamentoAdesaoVendedorArray
              )}
            />

            <TextOut
              label="Parcelas"
              value={labelByValue(
                contract.parcelasAdesaoVendedor,
                parcelasPagamentoAdesaoArray
              )}
            />

            <TextOut label="Data da cobrança" value={contract.dataVendedor} />

            {contract.obsVendedor && (
              <TextOut label="Observação" value={contract.obsVendedor} />
            )}
          </>
        )}

        {contract.adesao && contract.meioAdesao == 2 && (
          <>
            <TextOut
              label="Forma de pagamento"
              value={labelByValue(
                contract.formaAdesaoCoobrastur,
                formaPagamentoAdesaoCoobrasturArray
              )}
            />

            <TextOut
              label="Parcelas"
              value={labelByValue(
                contract.parcelasAdesaoCoobrastur,
                parcelasPagamentoAdesaoArray
              )}
            />

            <TextOut label="Data da cobrança" value={contract.dataCoobrastur} />

            {contract.obsCoobrastur && (
              <TextOut label="Observação" value={contract.obsCoobrastur} />
            )}
          </>
        )}

        <DivisorOut value="Mensalidade" />

        <TextOut
          label="Valor"
          value={formatReal(
            getMensalidade({
              plano: contract.plano,
              diarias: contract.qtdDiarias,
              familia: contract.familia,
            })
          )}
        />

        <TextOut
          label="Primeiro pagamento"
          value={getDataPrimeiraMensalidade({
            adesao: contract?.adesao,
            meioAdesao: contract?.meioAdesao,
            parcelasAdesaoCoobrastur: contract?.parcelasAdesaoCoobrastur,
            parcelasAdesaoVendedor: contract?.parcelasAdesaoVendedor,
            dataVendedor: contract?.dataVendedor,
            dataCoobrastur: contract?.dataCoobrastur,
            formaPagamento: contract?.formaPagamento,
            dataDebitoConta: contract?.dataDebitoConta,
            dataFaturaCartao: contract?.dataFaturaCartao,
            dataVendaSemDados: contract?.dataVendaSemDados
          })}
        />

        <TextOut
          label="Forma de pagamento"
          value={labelByValue(
            contract.formaPagamento,
            formaPagamentoMensalidadeArray
          )}
        />

        {contract?.formaPagamento == 2 && (
          <>
            <TextOut label="CPF do titular" value={contract.cpfTitularCartao} />

            <TextOut
              label="Nome do titular"
              value={contract.nomeTitularCartao}
            />

            <TextOut label="Número do cartão" value={contract.numeroCartao} />

            <TextOut
              label="Validade do cartão"
              value={
                contract.mesValidadeCartao + "/" + contract.anoValidadeCartao
              }
            />

            {contract.adesao ? (
              <TextOut
                label="Dia da cobrança"
                value={labelByValue(
                  contract.diaFaturaCartao,
                  diaMensalidadeCartaoArray
                )}
              />
            ) : (
              <TextOut
                label="Dia da cobrança"
                value={contract.dataFaturaCartao}
              />
            )}
          </>
        )}

        {contract?.formaPagamento == 1 && (
          <>
            <TextOut
              label="CPF do titular"
              value={contract.cpfTitularContaCorrente}
            />

            <TextOut
              label="Nome do titular"
              value={contract.nomeTitularContaCorrente}
            />

            <TextOut
              label="Banco"
              value={labelByValue(contract.bancoContaCorrente, bancoContaArray)}
            />

            {contract.bancoContaCorrente == 104 && (
              <TextOut
                label="Tipo"
                value={labelByValue(
                  contract.bancoCaixaTipo,
                  bancoCaixaTipoArray
                )}
              />
            )}

            <TextOut label="Conta" value={contract.numeroContaCorrente} />

            <TextOut label="Dígito" value={contract.dvContaCorrente} />

            {/* <TextOut label="Agência" value={contract.agenciaContaCorrente} /> */}
            <TextOut label="Agência" value={contract.agenciaContaCorrente} />

            {contract.bancoContaCorrente == 41 && (
              <TextOut
                label="Dv Agência"
                value={`${contract.dvAgenciaContaCorrente}`}
              />
            )}

            {contract.adesao ? (
              <TextOut
                label="Dia do débito"
                value={labelByValue(
                  contract.diaDebitoConta,
                  diaMensalidadeBancoArray
                )}
              />
            ) : (
              <TextOut
                label="Dia do débito"
                value={
                  String(contract.dataDebitoConta).length === 8 &&
                  contract.dataDebitoConta !== undefined
                    ? masker(contract.dataDebitoConta, "00/00/0000")
                    : contract.data
                }
              />
            )}
          </>
        )}

        {contract.terceiro && (
          <>
            <DivisorOut value="Autorização de terceiro" />

            <TextOut label="CPF (terceiro)" value={contract.cpfTerceiro} />

            <TextOut label="Nome (terceiro)" value={contract.nomeTerceiro} />

            <TextOut
              label="Nascimento (terceiro)"
              value={contract.nascimentoTerceiro}
            />

            <TextOut
              label="Nacionalidade (terceiro)"
              value={contract.nacionalidadeTerceiro}
            />

            <TextOut
              label="Estado Civil (terceiro)"
              value={labelByValue(
                contract.estadoCivilTerceiro,
                estadoCivilArray
              )}
            />

            <TextOut
              label="Cônjuge (terceiro)"
              value={contract.nomeConjugeTerceiro}
            />

            <TextOut label="Mãe (terceiro)" value={contract.nomeMaeTerceiro} />

            <TextOut label="Pai (terceiro)" value={contract.nomePaiTerceiro} />

            <TextOut label="E-mail (terceiro)" value={contract.emailTerceiro} />

            <TextOut
              label="Celular (terceiro)"
              value={contract.celularTerceiro}
            />

            <TextOut
              label="Telefone (terceiro)"
              value={contract.telefoneTerceiro}
            />

            <TextOut
              label="Forma de assinatura (terceiro)"
              value={labelByValue(
                contract.formaAssinaturaTerceiro,
                formaAssinaturaArray
              )}
            />

            {contract.formaAssinaturaTerceiro == 2 && (
              <View style={styles.assinaturaImageContainer}>
                <Image
                  resizeMode={"contain"}
                  style={styles.assinaturaImage}
                  source={{ uri: contract.assinaturaTerceiro }}
                />
              </View>
            )}
          </>
        )}

        {contract?.restricao && (
          <>
            <DivisorOut value="Restrição" />

            <TextOut
              label="Alternativa"
              value={labelByValue(
                contract?.alternativaRestricao,
                restricaoArray
              )}
            />

            {contract?.alternativaRestricao === 2 && (
              <TextOut
                label="Forma de aditamento"
                value={labelByValue(
                  contract?.formaAditamento,
                  formaAditamentoArray
                )}
              />
            )}
          </>
        )}

        {contract.fiador && (
          <>
            <DivisorOut value="Fiador" />

            <TextOut label="CPF (fiador)" value={contract.cpfFiador} />

            <TextOut label="Nome (fiador)" value={contract.nomeFiador} />

            <TextOut
              label="Nascimento (fiador)"
              value={contract.nascimentoFiador}
            />

            <TextOut
              label="Nacionalidade (fiador)"
              value={contract.nacionalidadeFiador}
            />

            <TextOut
              label="Estado Civil (fiador)"
              value={labelByValue(contract.estadoCivilFiador, estadoCivilArray)}
            />

            <TextOut
              label="Cônjuge (fiador)"
              value={contract.nomeConjugeFiador}
            />

            <TextOut label="Mãe (fiador)" value={contract.nomeMaeFiador} />

            <TextOut label="Pai (fiador)" value={contract.nomePaiFiador} />

            <TextOut label="E-mail (fiador)" value={contract.emailFiador} />

            <TextOut label="Celular (fiador)" value={contract.celularFiador} />

            <TextOut
              label="Telefone (fiador)"
              value={contract.telefoneFiador}
            />

            <TextOut
              label="Forma assinatura (fiador)"
              value={labelByValue(
                contract.formaAssinaturaFiador,
                formaAssinaturaArray
              )}
            />

            {contract.formaAssinaturaFiador === 2 && (
              <View style={styles.assinaturaImageContainer}>
                <Image
                  resizeMode={"contain"}
                  style={styles.assinaturaImage}
                  source={{ uri: contract.assinaturaFiador }}
                />
              </View>
            )}
          </>
        )}

        <DivisorOut value="Assinatura" />

        <TextOut
          label="Forma de assinatura (associado)"
          value={labelByValue(contract.formaAssinatura, formaAssinaturaArray)}
        />

        {contract.formaAssinatura == 2 && (
          //offline
          <View style={styles.assinaturaImageContainer}>
            <Image
              resizeMode={"contain"}
              style={styles.assinaturaImage}
              source={{ uri: contract.assinatura }}
            />
          </View>
        )}

        <DivisorOut value="Informações" />

        <TextOut
          style={{ textTransform: "uppercase" }}
          label="Criado (data)"
          value={format(new Date(contract?.createdAt), "dd/MM/Y - HH:mm", {
            locale: ptBr,
          })}
        />

        {contract.updatedAt && (
          <TextOut
            style={{ textTransform: "uppercase" }}
            label="Editado (data)"
            value={format(new Date(contract?.updatedAt), "dd/MM/Y - HH:mm", {
              locale: ptBr,
            })}
          />
        )}

        {Number(
          new Date(contract?.updatedAt || contract?.createdAt).getMonth()
        ) >= 9 && (
          <TextOut
            style={{ color: "rgb(245,70,85)" }}
            value="Este contrato pode estar sujeito à adiamento da liberação de e-tickets por conta da tabela restritiva (Novembro e Dezembro)."
          />
        )}

        <View style={styles.inputContainer}>
          <View style={{ flexDirection: "row" }}>
            {contract.status < 3 && !contract.loading ? (
              <TouchableOpacity style={styles.submitEdit} onPress={edit}>
                <Text style={styles.submitEditText}>editar</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity style={styles.submitEdit} onPress={goBack}>
                <Text style={styles.submitEditText}>voltar</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </Animated.ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    backgroundColor: "rgb(240,240,245)",
  },

  content: {
    flex: 1,
    maxWidth: 720,
    width: "100%",
  },

  title: {
    flex: 1,
    alignSelf: "center",
    padding: 10,
    textTransform: "capitalize",
    fontSize: 18,
  },

  subTitle: {
    flex: 1,
    alignSelf: "center",
    padding: 10,
    textTransform: "capitalize",
    fontSize: 16,
    color: "#999",
  },

  hrContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  hrLine: {
    borderBottomWidth: 1,
    borderColor: "#999",
    height: 1,
    flex: 1,
  },

  hrTitle: {
    color: "#444",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "capitalize",
    margin: 5,
  },

  inputContainer: {
    padding: 10,
  },

  label: {
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "capitalize",
  },

  labelUppercase: {
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "uppercase",
  },

  inputValue: {
    flex: 1,
  },

  // assinatura

  assinaturaModalContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    zIndex: 2,
  },

  assinaturaModal: {
    flex: 1,
    width: "100%",
  },

  assinaturaImageContainer: {
    padding: 0,
    margin: 0,

    height: Dimensions.get("window").width,
    width: Dimensions.get("window").width,

    backgroundColor: "#F8F8F8",
  },

  assinaturaImage: {
    width: "100%",
    height: "100%",
  },

  // header right
  headerRightButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: 10,
  },

  headerRightButtonText: {
    color: "#fff",
    fontSize: 18,
  },

  //

  submit: {
    flex: 1,

    height: 45,
    borderRadius: 5,
    marginBottom: 20,
    alignSelf: "flex-end",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
  },

  submitText: {
    color: "#fff",
    fontSize: 16,
    textTransform: "uppercase",
  },

  submitEdit: {
    flex: 1,
    // marginRight: 10,
    height: 45,
    borderRadius: 5,
    marginBottom: 20,
    alignSelf: "flex-end",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: colors.danger,
  },

  submitEditText: {
    color: "#fff",
    fontSize: 16,
    textTransform: "uppercase",
  },
});

export default Show;
