import React, { useRef, useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  TouchableOpacity,
  View,
  Text,
  Image,
  Platform,
  TextInput,
  Alert,
  Animated,
} from "react-native";

import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../redux/sign/actions";

import colors from "../../../colors.json";

import loadingImg from "../../../assets/loading.gif";

import { DialogContext } from "../../context/Dialog";

import * as Network from "expo-network";

import { NavigationContext } from "../../context/Navigation";

export default function index() {
  const dispatch = useDispatch();
  const sign = useSelector((state) => state.sign);

  const [usuario, setUsuario] = useState();
  const [password, setPassword] = useState();

  const useDialog = useContext(DialogContext);
  const navigation = useContext(NavigationContext);

  const welcomeHandler = () => navigation.set.screen("welcome");

  const [animated, setAnimated] = useState(false);
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: animated ? 1 : 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [animated]);

  const submit = async () => {
    if (!usuario || !password) {
      return useDialog({
        message: "Preencha os dados de acesso",
      });
    }

    try {
      const connection = await Network.getNetworkStateAsync();
      if (!connection.isConnected)
        throw "Você está desconectado da internet, tente mais tarde.";

      const ipAcesso = await Network.getIpAddressAsync();
      const payload = {
        usuario,
        password,
        ipAcesso: String(ipAcesso) || "192.168.0.1",
      };

      dispatch(signIn(payload));
    } catch (error) {
      if (error)
        return useDialog({ message: error || "Tente novamente mais tarde." });
    }
  };

  useEffect(() => {
    if (!sign.loading && sign.data.token) {
      navigation.set.screen("home");
      return useDialog({
        title: "Bem-vindo!",
        message: "Agora você está logado.",
      });
    }
  }, [sign]);

  useEffect(() => {
    if (!sign.loading && !sign.data.token && sign.error !== "")
      useDialog({ message: sign.error || "Tente novamente mais tarde." });
  }, [sign]);

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS == "ios" ? "padding" : "height"}
    >
      <Animated.View style={[styles.content, { opacity: animatedOpacity }]}>
        <View style={styles.header}>
          <Text style={styles.title}>Acesso</Text>
          <Text style={styles.description}>Preencha os dados para entrar.</Text>
        </View>

        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            value={usuario}
            onChangeText={(text) => setUsuario(text)}
            keyboardType="email-address"
            textContentType="emailAddress"
            autoCapitalize="none"
            returnKeyType="done"
            returnKeyLabel="Pronto"
            placeholder={"Usuário"}
          />
        </View>

        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            value={password}
            onChangeText={(text) => setPassword(text)}
            keyboardType="default"
            returnKeyType="done"
            textContentType="name"
            secureTextEntry={true}
            placeholder={"Senha"}
          />
        </View>

        <View style={styles.inputContainer}>
          <View style={styles.actions}>
            <TouchableOpacity style={styles.back} onPress={welcomeHandler}>
              <Text style={styles.backText}>voltar</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.submit}
              onPress={submit}
              // disabled={sign.loading ? true : false}
            >
              {sign.loading ? (
                <Image style={styles.loadingImg} source={loadingImg} />
              ) : (
                <Text style={styles.submitText}>Acessar</Text>
              )}
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.inputContainer}>
          <View style={{ alignItems: "center" }}>
            <Text style={{ color: "#cfcfcf", fontWeight: "bold" }}>
              Versão 0.0.119
            </Text>
          </View>
        </View>
      </Animated.View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(240,240,245)",
  },

  content: {
    flex: 1,
    padding: 10,

    width: "100%",
    maxWidth: 720,

    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  header: {
    marginBottom: 20,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  imageContainer: {
    flex: 1,
    paddingHorizontal: 40,
    maxHeight: 200,
  },

  image: {
    flex: 1,
    resizeMode: "contain",
    borderRadius: 20,
  },

  title: {
    marginTop: 40,
    fontSize: 32,
    lineHeight: 32,
    fontWeight: "800",
    color: "#444",
  },

  description: {
    marginTop: 10,
    fontSize: 18,
    lineHeight: 20,
    color: "#999",
    paddingHorizontal: 20,
    textAlign: "center",
  },

  label: {
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "capitalize",
  },

  inputContainer: {
    width: "100%",
    padding: 20,
  },

  input: {
    height: 45,
    width: "100%",
    // borderRadius: 50,
    // backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderBottomColor: "#cfcfcf",
    paddingHorizontal: 10,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  actions: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },

  back: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    paddingRight: 15,
  },

  backText: {
    textDecorationLine: "underline",
    textDecorationColor: "#999",
    color: "#999",
  },

  submit: {
    height: 45,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: 50,
    minWidth: 140,
    borderRadius: 50,
    backgroundColor: colors.primary,
  },

  submitText: {
    color: "#fff",
    fontSize: 16,
    textTransform: "uppercase",
  },

  loadingImg: {
    height: 50,
    width: "100%",
    resizeMode: "contain",
  },
});
