import React, { useRef, useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
  Image,
  Button,
  FlatList,
  Alert,
  Animated,
} from "react-native";

import { useSelector } from "react-redux";

import { format } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

import colors from "../../../colors.json";

import emptyImg from "../../../assets/empty.png";

import Card from "./Contract";

import DivisorOut from "../../utils/outputs/Divisor";
import TabsIn from "../../utils/inputs/Tabs";
import SelectIn from "../../utils/inputs/Select";
import BooleanIn from "../../utils/inputs/Boolean";

import { NavigationContext } from "../../context/Navigation";

import Header from "../../utils/outputs/Header";

const Index = ({ route }) => {
  const contracts = useSelector((state) => state.contracts);
  const navigation = useContext(NavigationContext);

  const contractsModeArray = [
    { value: 1, label: "Pendentes" },
    { value: 2, label: "Enviados" },
  ];

  const [contractsMode, setContractsMode] = useState(
    contractsModeArray[0].value
  );

  const [pendingContracts, setPendingContracts] = useState([]);
  const [sentContracts, setSentContracts] = useState([]);

  const [animated, setAnimated] = useState(false);
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: animated ? 1 : 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [animated]);

  useEffect(() => {
    if (contracts?.data?.length > 0) {
      const pending = contracts.data.filter((contract) => {
        if (contract?.status !== 4) {
          return contract;
        }
      });
      
      const sent = contracts.data.filter((contract) => {
        if (contract?.status == 4) {
          console.log(contract);
          return contract;
        }
      });
      
      console.log(sent);
      setPendingContracts(pending);
      setSentContracts(sent);
    }
  }, [contracts]);

  return (
    <View style={styles.container}>
      <Header
        title="Contratos"
        headerLeft={{
          text: "Voltar",
          callback: () => navigation.set.screen("home"),
        }}
        headerRight={{
          text: "Novo",
          callback: () => navigation.set.screen("contractsStore"),
        }}
      />

      <Animated.View style={[styles.content, { opacity: animatedOpacity }]}>
        <BooleanIn
          style={{ flex: 1, paddingBottom: 10, maxHeight: 70 }}
          color={colors.primary}
          value={contractsMode}
          badges={[
            pendingContracts.length > 0 && {
              value: pendingContracts.length,
              done: false,
            },
            sentContracts.length > 0 && {
              value: sentContracts.length,
              done: true,
            },
          ]}
          setValue={setContractsMode}
          data={contractsModeArray}
        />

        {(contractsMode === 1 && pendingContracts.length < 1) ||
        (contractsMode === 2 && sentContracts.length < 1) ? (
          <Animated.View
            style={[styles.notFoundContainer, { opacity: animatedOpacity }]}
          >
            <View style={styles.imageContainer}>
              <Image style={styles.image} source={emptyImg} />
            </View>

            <Text style={styles.title}>Vazio</Text>
            <Text style={styles.description}>Nenhum contrato encontrado.</Text>
          </Animated.View>
        ) : (
          <FlatList
            style={styles.contracts}
            data={contractsMode === 1 ? pendingContracts : sentContracts}
            keyExtractor={(item) => item.ref}
            renderItem={({ item }) => <Card item={item} />}
          />
        )}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    backgroundColor: "rgb(240,240,245)",
  },

  content: {
    flex: 1,
    maxWidth: 720,
    width: "100%",
  },

  contracts: {
    flex: 1,

    padding: 10,
    paddingTop: 0,
    // borderWidth: 2,
  },

  notFoundContainer: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  imageContainer: {
    marginTop: -60,
    width: "100%",
    height: "100%",
    maxHeight: 240,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  image: {
    flex: 1,
    resizeMode: "contain",
    height: 240,
    width: 240,
  },

  title: {
    marginTop: 30,
    fontSize: 32,
    lineHeight: 32,
    fontWeight: "800",
    color: "#444",
  },

  description: {
    marginTop: 10,
    fontSize: 18,
    lineHeight: 20,
    color: "#999",
    paddingHorizontal: 20,
    textAlign: "center",
  },

  cards: {
    paddingTop: 5,
  },

  headerButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: 10,
  },

  headerButtonText: {
    color: "#fff",
    fontSize: 18,
  },
});

export default Index;
