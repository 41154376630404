import React, { useRef, useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  View,
  Text,
  Button,
  Platform,
  TouchableOpacity,
  Image,
  Dimensions,
  Animated,
} from "react-native";

import { useDispatch, useSelector } from "react-redux";
import { setContract, deleteContract } from "../../redux/contracts/actions";

import {
  restricaoArray,
  booleanArray,
  ufArray,
  tipoVendaArray,
  planoArray,
  planoDescricaoArray,
  diariasArray,
  diariasGoArray,
  bandeiraCartaoArray,
  formaPagamentoMensalidadeArray,
  diaMensalidadeBancoArray,
  diaMensalidadeCartaoArray,
  bancoContaArray,
  bancoCaixaTipoArray,
  estadoCivilArray,
  formaAssinaturaArray,
  adesaoMeioArray,
  formaPagamentoAdesaoVendedorArray,
  formaPagamentoAdesaoCoobrasturArray,
  parcelasPagamentoAdesaoArray,
  formaAditamentoArray,
} from "./datas";

import {
  converterNumeroCaixa,
  getLimiteDigitosConta,
  getLimiteDigitosAgencia,
  getParcelasAdesaoBoletoCoobrasturArray,
  getMensalidade,
  getDataPrimeiraMensalidade,
  getDataLiberacaoAditamento,
  labelByValue,
  formatReal,
  masker,
} from "../../utils/functions";

import colors from "../../../colors.json";

import SignatureScreen from "react-native-signature-canvas";

// inputs
import TextIn from "../../utils/inputs/Text";
import BooleanIn from "../../utils/inputs/Boolean";
import SelectIn from "../../utils/inputs/Select";

// outputs
import TextOut from "../../utils/outputs/Text";

import DivisorCarousel from "../../utils/outputs/DivisorCarousel";
import DescriptionOut from "../../utils/outputs/Description";

import { DialogContext } from "../../context/Dialog";
import { NavigationContext } from "../../context/Navigation";

import Header from "../../utils/outputs/Header";

const assinaturaStyle = `.m-signature-pad--footer  
    .button {
      font-size: ${14}px;      
    }
  `;

import * as Network from "expo-network";

import { apiViacep } from "../../services/api";

const Update = () => {
  const dispatch = useDispatch();

  const navigation = useContext(NavigationContext);
  const { ref } = navigation.get.params();

  const contracts = useSelector((state) => state.contracts.data);

  const select = contracts?.filter((item) => item.ref === ref && item);
  const contract = select[0];

  const sign = useSelector((state) => state.sign);
  const pagamentoAdesao = sign.data.pagamentoAdesao;

  const [animated, setAnimated] = useState(false);
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  const formaAssinaturaArrayPlatform =
    Platform.OS === "ios" || Platform.OS === "android"
      ? formaAssinaturaArray
      : [formaAssinaturaArray[0]];

  const planoInicial = contract.plano;
  const cepInicial = contract.cep || "";

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: animated ? 1 : 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [animated]);

  // dados pessoais
  const [cpf, setCpf] = useState(contract?.cpf);
  const [nome, setNome] = useState(contract?.nome);

  // endereço
  const [enderecoBloqueado, setEnderecoBloqueado] = useState(false);

  const [uf, setUf] = useState(contract?.uf);

  const [cep, setCep] = useState(contract?.cep);
  const [cidade, setCidade] = useState(contract?.cidade);
  const [bairro, setBairro] = useState(contract?.bairro);
  const [rua, setRua] = useState(contract?.rua);
  const [numeroRua, setNumeroRua] = useState(contract?.numeroRua);
  const [complementoRua, setComplementoRua] = useState(
    contract?.complementoRua
  );

  // contato
  const [email, setEmail] = useState(contract?.email);
  const [dddCelular, setDddCelular] = useState(contract?.dddCelular);
  const [celular, setCelular] = useState(contract?.celular);

  // indicação dados pessoais
  const [indicador, setIndicador] = useState(contract?.indicador);
  const [nomeIndicador, setNomeIndicador] = useState(contract?.nomeIndicador);
  const [cpfIndicador, setCpfIndicador] = useState(contract?.cpfIndicador);

  // indicação contato
  const [emailIndicador, setEmailIndicador] = useState(
    contract?.emailIndicador
  );
  const [dddCelularIndicador, setDddCelularIndicador] = useState(
    contract?.dddCelularIndicador
  );
  const [celularIndicador, setCelularIndicador] = useState(
    contract?.celularIndicador
  );

  // associação / dados venda
  const [tipoVenda, setTipoVenda] = useState(contract?.tipoVenda);
  const [plano, setPlano] = useState(contract?.plano);
  const [qtdDiarias, setQtdDiarias] = useState(contract?.qtdDiarias);
  const [familia, setFamilia] = useState(contract?.familia);

  // adesão
  const [adesao, setAdesao] = useState(contract?.adesao);
  const [valorAdesao, setValorAdesao] = useState(contract?.valorAdesao);
  const [meioAdesao, setMeioAdesao] = useState(contract?.meioAdesao);

  // adesão - vendedor
  const [formaAdesaoVendedor, setFormaAdesaoVendedor] = useState(
    contract?.formaAdesaoVendedor || formaPagamentoAdesaoVendedorArray[0].value
  );
  const [parcelasAdesaoVendedor, setParcelasAdesaoVendedor] = useState(
    contract?.parcelasAdesaoVendedor || parcelasPagamentoAdesaoArray[0].value
  );
  const [dataVendedor, setDataVendedor] = useState(contract?.dataVendedor);
  const [obsVendedor, setObsVendedor] = useState(contract?.obsVendedor);

  // adesão - coobrastur
  const [formaAdesaoCoobrastur, setFormaAdesaoCoobrastur] = useState(
    contract?.formaAdesaoCoobrastur ||
      formaPagamentoAdesaoCoobrasturArray[0].value
  );
  const [parcelasAdesaoCoobrastur, setParcelasAdesaoCoobrastur] = useState(
    contract?.parcelasAdesaoCoobrastur || parcelasPagamentoAdesaoArray[0].value
  );
  const [dataCoobrastur, setDataCoobrastur] = useState(
    contract?.dataCoobrastur
  );
  const [obsCoobrastur, setObsCoobrastur] = useState(contract?.obsCoobrastur);

  // restricao
  const [restricao, setRestricao] = useState(contract?.restricao);
  const [alternativaRestricao, setAlternativaRestricao] = useState(
    contract?.alternativaRestricao
  );

  // aditamento
  const [aditamento, setAditamento] = useState(contract?.aditamento);
  const [formaAditamento, setFormaAditamento] = useState(
    contract?.formaAditamento
  );

  // fiador
  const [fiador, setFiador] = useState(contract?.fiador);

  // fiador - dados pessoais
  const [cpfFiador, setCpfFiador] = useState(contract?.cpfFiador);
  const [nomeFiador, setNomeFiador] = useState(contract?.nomeFiador);
  const [nascimentoFiador, setNascimentoFiador] = useState(
    contract?.nascimentoFiador
  );
  const [nacionalidadeFiador, setNacionalidadeFiador] = useState(
    contract?.nacionalidadeFiador
  );
  const [estadoCivilFiador, setEstadoCivilFiador] = useState(
    contract?.estadoCivilFiador
  );

  // fiador - dados complementares
  const [nomeConjugeFiador, setNomeConjugeFiador] = useState(
    contract?.nomeConjugeFiador
  );
  const [nomeMaeFiador, setNomeMaeFiador] = useState(contract?.nomeMaeFiador);
  const [nomePaiFiador, setNomePaiFiador] = useState(contract?.nomePaiFiador);

  // fiador - contato
  const [emailFiador, setEmailFiador] = useState(contract?.emailFiador);
  const [dddCelularFiador, setDddCelularFiador] = useState(
    contract?.dddCelularFiador
  );
  const [celularFiador, setCelularFiador] = useState(contract?.celularFiador);
  const [dddTelefoneFiador, setDddTelefoneFiador] = useState(
    contract?.dddTelefoneFiador
  );
  const [telefoneFiador, setTelefoneFiador] = useState(
    contract?.telefoneFiador
  );

  // fiador - assinatura
  const [formaAssinaturaFiador, setFormaAssinaturaFiador] = useState(
    contract?.formaAssinaturaFiador
  );
  const [assinaturaModalFiador, setAssinaturaModalFiador] = useState(false);
  const [assinaturaFiador, setAssinaturaFiador] = useState(
    contract?.assinaturaFiador
  );
  const [assinaturaFiadorEditavel, setAssinaturaFiadorEditavel] = useState(
    contract.assinaturaFiador ? false : true
  );

  // pagamento
  const [formaPagamento, setFormaPagamento] = useState(
    contract?.formaPagamento
  );

  // pagamento - conta bancária
  const [cpfTitularContaCorrente, setCpfTitularContaCorrente] = useState(
    contract?.cpfTitularContaCorrente
  );
  const [nomeTitularContaCorrente, setNomeTitularContaCorrente] = useState(
    contract?.nomeTitularContaCorrente
  );
  const [bancoContaCorrente, setBancoContaCorrente] = useState(
    contract?.bancoContaCorrente
  );
  const [bancoCaixaTipo, setBancoCaixaTipo] = useState(
    contract?.bancoCaixaTipo
  );
  const [numeroContaCorrente, setNumeroContaCorrente] = useState(
    contract?.numeroContaCorrente
  );
  const [dvContaCorrente, setDvContaCorrente] = useState(
    contract?.dvContaCorrente
  );
  const [agenciaContaCorrente, setAgenciaContaCorrente] = useState(
    contract?.agenciaContaCorrente
  );
  const [diaDebitoConta, setDiaDebitoConta] = useState(
    contract?.diaDebitoConta
  );
  const [dataDebitoConta, setDataDebitoConta] = useState(
    contract?.dataDebitoConta
  );
  // limitador digitos banco
  const [limiteNumeroConta, setLimiteNumeroConta] = useState(
    getLimiteDigitosConta({ banco: Number(bancoContaCorrente) })
  );
  const [limiteNumeroAgencia, setLimiteNumeroAgencia] = useState(
    getLimiteDigitosConta({ banco: Number(bancoContaCorrente) })
  );

  // pagamento - cartão de crédito
  const [cpfTitularCartao, setCpfTitularCartao] = useState(
    contract?.cpfTitularCartao
  );
  const [nomeTitularCartao, setNomeTitularCartao] = useState(
    contract?.nomeTitularCartao
  );
  const [bandeiraCartao, setBandeiraCartao] = useState(
    contract?.bandeiraCartao
  );
  const [numeroCartao, setNumeroCartao] = useState(contract?.numeroCartao);
  const [mesValidadeCartao, setMesValidadeCartao] = useState(
    contract?.mesValidadeCartao
  );
  const [anoValidadeCartao, setAnoValidadeCartao] = useState(
    contract?.anoValidadeCartao
  );
  const [cvvCartao, setCvvCartao] = useState(contract?.cvvCartao);
  const [diaFaturaCartao, setDiaFaturaCartao] = useState(
    contract?.diaFaturaCartao
  );
  const [dataFaturaCartao, setDataFaturaCartao] = useState(
    contract?.dataFaturaCartao
  );

  // Data pagamento sem dados bancários e sem adesão
  const [dataVendaSemDados, setDataVendaSemDados] = useState(
    contract?.dataVendaSemDados
  );

  // Terceiro
  const [terceiro, setTerceiro] = useState(contract?.terceiro);

  // Terceiro - dados pessoais
  const [cpfTerceiro, setCpfTerceiro] = useState(contract?.cpfTerceiro);
  const [nomeTerceiro, setNomeTerceiro] = useState(contract?.nomeTerceiro);
  const [nascimentoTerceiro, setNascimentoTerceiro] = useState(
    contract?.nascimentoTerceiro
  );
  const [nacionalidadeTerceiro, setNacionalidadeTerceiro] = useState(
    contract?.nacionalidadeTerceiro
  );
  const [estadoCivilTerceiro, setEstadoCivilTerceiro] = useState(
    contract?.estadoCivilTerceiro
  );

  // Terceiro - dados complementares
  const [nomeConjugeTerceiro, setNomeConjugeTerceiro] = useState(
    contract?.nomeConjugeTerceiro
  );
  const [nomeMaeTerceiro, setNomeMaeTerceiro] = useState(
    contract?.nomeMaeTerceiro
  );
  const [nomePaiTerceiro, setNomePaiTerceiro] = useState(
    contract?.nomePaiTerceiro
  );

  // Terceiro - contato
  const [emailTerceiro, setEmailTerceiro] = useState(contract?.emailTerceiro);
  const [dddCelularTerceiro, setDddCelularTerceiro] = useState(
    contract?.dddCelularTerceiro
  );
  const [celularTerceiro, setCelularTerceiro] = useState(
    contract?.celularTerceiro
  );
  const [dddTelefoneTerceiro, setDddTelefoneTerceiro] = useState(
    contract?.dddTelefoneTerceiro
  );
  const [telefoneTerceiro, setTelefoneTerceiro] = useState(
    contract?.telefoneTerceiro
  );

  // Terceiro - assinatura
  const [formaAssinaturaTerceiro, setFormaAssinaturaTerceiro] = useState(
    contract?.formaAssinaturaTerceiro
  );
  const [assinaturaModalTerceiro, setAssinaturaModalTerceiro] = useState(false);
  const [assinaturaTerceiro, setAssinaturaTerceiro] = useState(
    contract?.assinaturaTerceiro
  );
  const [assinaturaTerceiroEditavel, setAssinaturaTerceiroEditavel] = useState(
    contract.assinaturaTerceiro ? false : true
  );

  // assinatura associado
  const [formaAssinatura, setFormaAssinatura] = useState(
    contract?.formaAssinatura
  );
  const [assinaturaModal, setAssinaturaModal] = useState(false);
  const [assinatura, setAssinatura] = useState(contract?.assinatura);
  const [assinaturaEditavel, setAssinaturaEditavel] = useState(
    contract.assinatura ? false : true
  );

  const [dvAgenciaContaCorrente, setDvAgenciaContaCorrente] = useState(
    contract?.dvAgenciaContaCorrente
  );

  const useDialog = useContext(DialogContext);

  // handlers assinatura associado;
  const salvarAssinatura = (signature) => {
    setAssinatura(signature);
  };

  const limparAssinatura = () => {
    setAssinatura(null);
    setAssinaturaModal(false);
  };

  // handlers assinatura fiador;
  const salvarAssinaturaFiador = (signature) => {
    setAssinaturaFiador(signature);
  };

  const limparAssinaturaFiador = () => {
    setAssinaturaFiador(null);
    setAssinaturaModalFiador(false);
  };

  // handlers assinatura Terceiro;
  const salvarAssinaturaTerceiro = (signature) => {
    setAssinaturaTerceiro(signature);
  };

  const limparAssinaturaTerceiro = () => {
    setAssinaturaTerceiro(null);
    setAssinaturaModalTerceiro(false);
  };

  const submitDelete = () => {
    try {
      dispatch(deleteContract({ ref }));

      useDialog({ title: "Sucesso", message: "O contrato foi apagado." });

      navigation.set.screen("contractsIndex");
    } catch (error) {
      useDialog({ message: error || "Tente novamente mais tarde." });
    }
  };

  const resetTerceiro = () => {
    setTerceiro(false);
    setCpfTerceiro("");
    setNomeTerceiro("");
    setNascimentoTerceiro("");
    setNacionalidadeTerceiro("");
    setEstadoCivilTerceiro("");
    setNomeConjugeTerceiro("");
    setNomeMaeTerceiro("");
    setNomePaiTerceiro("");
    setEmailTerceiro("");
    setDddCelularTerceiro("");
    setCelularTerceiro("");
    setDddTelefoneTerceiro("");
    setTelefoneTerceiro("");
    setFormaAssinaturaTerceiro("");
    setAssinaturaTerceiro("");

    // return useDialog({
    //   message: `Autorização de Terceiro foi atualizada pode salvar agora `,
    // });
  };

  const checkAndSubmit = async () => {
    if (formaPagamento == 1) {
      if (!terceiro && cpf != cpfTitularContaCorrente) {
        setTerceiro(true);
        setCpfTerceiro(cpfTitularContaCorrente || "");

        // return useDialog({
        //   message: `Preencha os campos da "Autorização de Terceiro"`,
        // });
      }

      if (
        !terceiro &&
        (cpfTerceiro ||
          nomeTerceiro ||
          nascimentoTerceiro ||
          nacionalidadeTerceiro ||
          estadoCivilTerceiro ||
          nomeConjugeTerceiro ||
          nomeMaeTerceiro ||
          nomePaiTerceiro ||
          emailTerceiro ||
          dddCelularTerceiro ||
          celularTerceiro ||
          dddTelefoneTerceiro ||
          telefoneTerceiro ||
          formaAssinaturaTerceiro ||
          assinaturaTerceiro)
      ) {
        return resetTerceiro();
      }

      if (terceiro && cpf == cpfTitularContaCorrente) {
        resetTerceiro();

        return setCpfTerceiro(cpfTitularContaCorrente || "");
      }
    }

    submit();
  };

  const submit = async () => {
    try {
      dispatch(
        setContract({
          ...contract,
          ref,
          createdAt: new Date(contract.createdAt),
          updatedAt: new Date(),
          status: 0,
          loading: false,
          restricaoOnline: undefined,
          idAssinaturaOnline: undefined,
          idCoobrastur: undefined,
          cpf,
          nome: nome?.trim(),
          uf,
          cep,
          cidade,
          bairro,
          rua,
          numeroRua,
          complementoRua,
          email: email?.trim(),
          dddCelular,
          celular,
          indicador,
          nomeIndicador: nomeIndicador?.trim(),
          cpfIndicador,
          emailIndicador: emailIndicador?.trim(),
          dddCelularIndicador,
          celularIndicador,
          tipoVenda,
          plano,
          qtdDiarias,
          familia,
          adesao,
          valorAdesao,
          meioAdesao,
          formaAdesaoVendedor,
          parcelasAdesaoVendedor,
          dataVendedor,
          obsVendedor,
          formaAdesaoCoobrastur,
          parcelasAdesaoCoobrastur,
          dataCoobrastur,
          obsCoobrastur,
          restricao,
          alternativaRestricao,
          aditamento,
          formaAditamento,
          fiador,
          cpfFiador,
          nomeFiador: nomeFiador?.trim(),
          nascimentoFiador,
          nacionalidadeFiador: nacionalidadeFiador?.trim(),
          estadoCivilFiador,
          nomeConjugeFiador: nomeConjugeFiador?.trim(),
          nomeMaeFiador: nomeMaeFiador?.trim(),
          nomePaiFiador: nomePaiFiador?.trim(),
          emailFiador: emailFiador?.trim(),
          dddCelularFiador,
          celularFiador,
          dddTelefoneFiador,
          telefoneFiador,
          formaAssinaturaFiador,
          assinaturaFiador,
          formaPagamento,
          cpfTitularCartao,
          nomeTitularCartao: nomeTitularCartao?.trim(),
          bandeiraCartao,
          numeroCartao,
          mesValidadeCartao,
          anoValidadeCartao,
          cvvCartao,
          diaFaturaCartao,
          dataFaturaCartao,
          cpfTitularContaCorrente,
          nomeTitularContaCorrente: nomeTitularContaCorrente?.trim(),
          bancoContaCorrente,
          bancoCaixaTipo,
          numeroContaCorrente:
            bancoContaCorrente == 104
              ? converterNumeroCaixa(numeroContaCorrente)
              : numeroContaCorrente,
          dvContaCorrente,
          agenciaContaCorrente,
          dvAgenciaContaCorrente,
          diaDebitoConta,
          dataDebitoConta,
          dataVendaSemDados,
          terceiro,
          cpfTerceiro,
          nomeTerceiro: nomeTerceiro?.trim(),
          nascimentoTerceiro,
          nacionalidadeTerceiro,
          estadoCivilTerceiro,
          nomeConjugeTerceiro: nomeConjugeTerceiro?.trim(),
          nomeMaeTerceiro: nomeMaeTerceiro?.trim(),
          nomePaiTerceiro: nomePaiTerceiro?.trim(),
          emailTerceiro: emailTerceiro?.trim(),
          dddCelularTerceiro,
          celularTerceiro,
          dddTelefoneTerceiro,
          telefoneTerceiro,
          formaAssinaturaTerceiro,
          assinaturaTerceiro,
          formaAssinatura,
          assinatura,

          dataPrimeiraMensalidade: getDataPrimeiraMensalidade({
            adesao,
            meioAdesao,
            parcelasAdesaoCoobrastur,
            parcelasAdesaoVendedor,
            dataVendedor,
            dataCoobrastur,
            formaPagamento,
            dataDebitoConta,
            dataFaturaCartao,
          }),
          dataLiberacaoPlano:
            restricao && alternativaRestricao == 2 && formaAditamento == 1
              ? getDataLiberacaoAditamento({
                  adesao,
                  meioAdesao,
                  parcelasAdesaoCoobrastur,
                  parcelasAdesaoVendedor,
                  dataVendedor,
                  dataCoobrastur,
                })
              : getDataPrimeiraMensalidade({
                  adesao,
                  meioAdesao,
                  parcelasAdesaoCoobrastur,
                  parcelasAdesaoVendedor,
                  dataVendedor,
                  dataCoobrastur,
                  formaPagamento,
                  dataDebitoConta,
                  dataFaturaCartao,
                }),
        })
      );

      await finalizaEdicao();
    } catch (error) {
      useDialog({ message: error || "Tente novamente mais tarde." });
    }
  };

  async function finalizaEdicao() {
    useDialog({ title: "Sucesso" });

    return navigation.set.screen("contractsShow", { ref });
  }

  async function asyncEndereco() {
    const connection = await Network.getNetworkStateAsync();
    if (!connection.isConnected) return setEnderecoBloqueado(false);

    const res = await apiViacep.get(`${cep}/json`);
    if (!res.data) return setEnderecoBloqueado(false);

    const { logradouro, complemento, bairro, localidade, uf } = res.data;

    if (uf) setUf(uf);
    if (localidade) setCidade(localidade);
    if (bairro) setBairro(bairro);
    if (logradouro) setRua(logradouro);
    if (complemento) setComplementoRua(complemento);

    return setEnderecoBloqueado(false);
  }

  // se CPF associado for diferente do CPF de pagamento em conta então ativa terceiro
  useEffect(() => {
    if (
      String(cpf).length == 11 &&
      String(cpfTitularContaCorrente).length == 11
    ) {
      if (cpf !== cpfTitularContaCorrente) {
        setCpfTerceiro(cpfTitularContaCorrente);
        setNomeTerceiro(nomeTitularContaCorrente);
        setTerceiro(true);
      } else {
        setTerceiro(false);
      }
    } else {
    }
  }, [cpf, cpfTitularContaCorrente, nomeTitularContaCorrente]);

  // ajusta diárias de acordo com plano Go e o restante
  useEffect(() => {
    if (planoInicial != plano) {
      //se plano Go
      if (Number(plano) > 33) {
        //reset diárias para planos Go
        setQtdDiarias(diariasGoArray[0].value);

        //reset adesão
        setAdesao(false);
        setValorAdesao();
        setMeioAdesao(adesaoMeioArray[1].value);
        setFamilia(false);
        setFormaAdesaoCoobrastur();
        setFormaAdesaoVendedor();
        setParcelasAdesaoCoobrastur();
        setParcelasAdesaoVendedor();

        return;
      }

      //reseta diárias
      return setQtdDiarias(diariasArray[0].value);
    }
  }, [plano]);

  // reseta para via coobrastur, caso mude para com adesao
  useEffect(() => {
    if (adesao) {
      if (meioAdesao === undefined) {
        setMeioAdesao(adesaoMeioArray[1].value);
        setFormaAdesaoCoobrastur(formaPagamentoAdesaoCoobrasturArray[0].value);
        setParcelasAdesaoCoobrastur(1);
        return;
      }
    }
  }, [adesao]);

  // reseta as parcelas quando mudar a formaPagamentoAdesaoCoobrastur ou valorAdesao
  useEffect(() => {
    if (meioAdesao == 2 && valorAdesao > 0) {
      const parcelas = getParcelasAdesaoBoletoCoobrasturArray(valorAdesao);

      if (parcelasAdesaoCoobrastur > parcelas.length)
        return setParcelasAdesaoCoobrastur(parcelas.length);
    }
  }, [formaAdesaoCoobrastur, valorAdesao, meioAdesao]);

  // desativa fiador e aditamento quando desativa restricao
  useEffect(() => {
    if (restricao) {
      if (alternativaRestricao == 1) {
        setFiador(true);
        setAditamento(false);
      }
      if (alternativaRestricao == 2) {
        setFiador(false);
        setAditamento(true);
      }
    } else {
      setFiador(false);
      setAditamento(false);
    }
  }, [alternativaRestricao, restricao]);

  // se tipo de venda for Reativação, desativa adesão
  useEffect(() => {
    if (tipoVenda == 2) {
      setAdesao(false);
      setMeioAdesao(adesaoMeioArray[1].value);
    }
  }, [tipoVenda]);

  // oculta conta corrente quando aditamento imediato
  useEffect(() => {
    if (restricao && alternativaRestricao == 2 && formaAditamento == 2)
      return setFormaPagamento(2);
  }, [restricao, alternativaRestricao, formaAditamento]);

  // busca endereço na api viacep quando preencher todo cep
  useEffect(() => {
    if (cep && cep !== cepInicial && String(cep).length >= 8) {
      asyncEndereco();
    }

    if (cep && String(cep).length < 8) {
      setEnderecoBloqueado(true);
    }
  }, [cep]);

  // atualiza limite número conta e agencia
  useEffect(() => {
    const limiteConta = getLimiteDigitosConta({ banco: bancoContaCorrente });
    const limiteAgencia = getLimiteDigitosAgencia({
      banco: Number(bancoContaCorrente),
    });

    setLimiteNumeroConta(limiteConta);
    setLimiteNumeroAgencia(limiteAgencia);
  }, [bancoContaCorrente]);

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS == "ios" ? "padding" : "height"}
    >
      <>
        {assinaturaModal && (
          <Animated.View
            style={[
              styles.assinaturaModalContainer,
              { opacity: animatedOpacity },
            ]}
          >
            <View style={styles.assinaturaModal}>
              <SignatureScreen
                onOK={(signature) => {
                  setAssinatura(signature);
                  setAssinaturaModal(false);
                }}
                onEnd={salvarAssinatura}
                onEmpty={limparAssinatura}
                descriptionText=""
                clearText="Limpar"
                confirmText="Salvar"
                webStyle={assinaturaStyle}
              />
            </View>
          </Animated.View>
        )}

        {assinaturaModalFiador && (
          <Animated.View
            style={[
              styles.assinaturaModalContainer,
              { opacity: animatedOpacity },
            ]}
          >
            <View style={styles.assinaturaModal}>
              <SignatureScreen
                onOK={(signature) => {
                  setAssinaturaFiador(signature);
                  setAssinaturaModalFiador(false);
                }}
                onEnd={salvarAssinaturaFiador}
                onEmpty={limparAssinaturaFiador}
                descriptionText=""
                clearText="Limpar"
                confirmText="Salvar"
                webStyle={assinaturaStyle}
              />
            </View>
          </Animated.View>
        )}

        {assinaturaModalTerceiro && (
          <Animated.View
            style={[
              styles.assinaturaModalContainer,
              { opacity: animatedOpacity },
            ]}
          >
            <View style={styles.assinaturaModal}>
              <SignatureScreen
                onOK={(signature) => {
                  setAssinaturaTerceiro(signature);
                  setAssinaturaModalTerceiro(false);
                }}
                onEnd={salvarAssinaturaTerceiro}
                onEmpty={limparAssinaturaTerceiro}
                descriptionText=""
                clearText="Limpar"
                confirmText="Salvar"
                webStyle={assinaturaStyle}
              />
            </View>
          </Animated.View>
        )}

        <Header
          title="Novo Contrato"
          headerLeft={{
            text: "Voltar",
            callback: () =>
              useDialog({
                message: "Você não salvou, o que deseja fazer?",
                options: [
                  {
                    text: "Salvar",
                    onPress: () => checkAndSubmit(),
                  },
                  {
                    text: "Descartar",
                    onPress: () =>
                      useDialog({
                        message: "Este contrato será perdido.",
                        options: [
                          {
                            text: "Confirmar",
                            onPress: () => navigation.set.goBack(),
                          },
                          {
                            text: "Cancelar",
                          },
                        ],
                      }),
                  },
                  {
                    text: "Voltar",
                  },
                ],
              }),
          }}
          headerRight={{
            text: "Salvar",
            callback: () => checkAndSubmit(),
          }}
        />

        <Animated.ScrollView
          style={[styles.content, { opacity: animatedOpacity }]}
        >
          <DivisorCarousel
            value="Dados pessoais"
            opened={true}
            children={
              <>
                <TextIn
                  value={cpf}
                  setValue={setCpf}
                  type="cpf"
                  label="CPF"
                  placeholder="Digite o CPF"
                  limit={11}
                />

                <TextIn
                  value={nome}
                  setValue={setNome}
                  type="nome"
                  label="Nome"
                  placeholder="Digite o nome completo"
                  limit={60}
                />
              </>
            }
          />

          <DivisorCarousel
            value="Contato"
            children={
              <>
                <TextIn
                  value={email}
                  setValue={setEmail}
                  type="email"
                  label="E-mail"
                  placeholder="Digite o e-mail"
                  limit={80}
                />

                <TextIn
                  value={dddCelular}
                  setValue={setDddCelular}
                  type="ddd"
                  label="DDD (celular)"
                  placeholder="Digite o DDD"
                  limit={2}
                />

                <TextIn
                  value={celular}
                  setValue={setCelular}
                  type="telefone"
                  label="Celular"
                  placeholder="Digite o número"
                  limit={9}
                />
              </>
            }
          />
          <DivisorCarousel
            value="Indicação"
            children={
              <>
                <BooleanIn
                  value={indicador}
                  setValue={setIndicador}
                  color={colors.primary}
                  data={booleanArray}
                />

                {indicador && (
                  <>
                    <TextIn
                      value={cpfIndicador}
                      setValue={setCpfIndicador}
                      type="cpf"
                      label="CPF (indicador)"
                      placeholder="Digite o CPF (obrigatório)"
                      limit={11}
                    />

                    <TextIn
                      value={nomeIndicador}
                      setValue={setNomeIndicador}
                      type="nome"
                      label="Nome (indicador)"
                      placeholder="Digite o nome completo (opcional)"
                      limit={60}
                    />

                    <TextIn
                      value={emailIndicador}
                      setValue={setEmailIndicador}
                      type="email"
                      label="E-mail (indicador)"
                      placeholder="Digite o e-mail (opcional)"
                      limit={80}
                    />

                    <TextIn
                      value={dddCelularIndicador}
                      setValue={setDddCelularIndicador}
                      type="ddd"
                      label="DDD (celular)"
                      placeholder="Digite o DDD (opcional)"
                      limit={2}
                    />

                    <TextIn
                      value={celularIndicador}
                      setValue={setCelularIndicador}
                      type="telefone"
                      label="Celular"
                      placeholder="Digite o número (opcional)"
                      limit={9}
                    />
                  </>
                )}
              </>
            }
          />
          <DivisorCarousel
            value="Restrição"
            children={
              <>
                <BooleanIn
                  data={booleanArray}
                  value={restricao}
                  setValue={setRestricao}
                  label="Coobrastur ou SPC"
                  color={colors.primary}
                />

                {restricao && (
                  <BooleanIn
                    data={restricaoArray}
                    value={alternativaRestricao}
                    setValue={setAlternativaRestricao}
                    label="Alternativa"
                    color={colors.primary}
                  />
                )}

                {restricao && fiador && (
                  <>
                    <TextIn
                      value={cpfFiador}
                      setValue={setCpfFiador}
                      type="cpf"
                      label="CPF (fiador)"
                      placeholder="Digite o CPF"
                      limit={11}
                    />

                    <TextIn
                      value={nomeFiador}
                      setValue={setNomeFiador}
                      type="nome"
                      label="Nome"
                      placeholder="Digite o nome completo"
                      limit={60}
                    />

                    <TextIn
                      value={nascimentoFiador}
                      setValue={setNascimentoFiador}
                      type="data"
                      label="Nascimento"
                      placeholder="Digite o dia/mês/ano"
                      limit={8}
                    />

                    <TextIn
                      value={nacionalidadeFiador}
                      setValue={setNacionalidadeFiador}
                      type="nome"
                      label="Nacionalidade"
                      placeholder="Digite nacionalidade"
                      limit={40}
                    />

                    <SelectIn
                      data={estadoCivilArray}
                      value={estadoCivilFiador}
                      setValue={setEstadoCivilFiador}
                      label="Estado civil"
                    />

                    {estadoCivilFiador == 2 && (
                      <TextIn
                        value={nomeConjugeFiador}
                        setValue={setNomeConjugeFiador}
                        type="nome"
                        label="Cônjuge"
                        placeholder="Nome completo"
                        limit={60}
                      />
                    )}

                    <TextIn
                      value={nomeMaeFiador}
                      setValue={setNomeMaeFiador}
                      type="nome"
                      label="Nome da mãe"
                      placeholder="Digite o nome completo"
                      limit={60}
                    />

                    <TextIn
                      value={nomePaiFiador}
                      setValue={setNomePaiFiador}
                      type="nome"
                      label="Nome do pai (se houver)"
                      placeholder="Digite o nome completo"
                      limit={60}
                    />

                    <TextIn
                      value={emailFiador}
                      setValue={setEmailFiador}
                      type="email"
                      label="E-mail"
                      placeholder="Digite o e-mail"
                      limit={80}
                    />

                    <TextIn
                      value={dddCelularFiador}
                      setValue={setDddCelularFiador}
                      type="ddd"
                      label="DDD (celular)"
                      placeholder="Digite o DDD"
                      limit={2}
                    />

                    <TextIn
                      value={celularFiador}
                      setValue={setCelularFiador}
                      type="telefone"
                      label="Celular"
                      placeholder="Digite o número"
                      limit={9}
                    />

                    <TextIn
                      value={dddTelefoneFiador}
                      setValue={setDddTelefoneFiador}
                      type="ddd"
                      label="DDD (telefone)"
                      placeholder="Digite o DDD (opcional)"
                      limit={2}
                    />

                    <TextIn
                      value={telefoneFiador}
                      setValue={setTelefoneFiador}
                      type="residencial"
                      label="Telefone"
                      placeholder="Digite o número (opcional)"
                      limit={8}
                    />

                    <BooleanIn
                      data={formaAssinaturaArrayPlatform}
                      value={formaAssinaturaFiador}
                      setValue={setFormaAssinaturaFiador}
                      label="Forma de assinatura (fiador)"
                      color={colors.primary}
                    />

                    {formaAssinaturaFiador == 1 && (
                      <View style={styles.inputContainer}>
                        <Text>Receberá um e-mail para assinar.</Text>
                      </View>
                    )}

                    {formaAssinaturaFiador == 2 && (
                      <>
                        {assinaturaFiador && (
                          <View style={styles.assinaturaImageContainer}>
                            <Image
                              resizeMode={"contain"}
                              style={styles.assinaturaImage}
                              source={{ uri: assinaturaFiador }}
                            />
                          </View>
                        )}

                        <View
                          style={[
                            styles.inputContainer,
                            { alignItems: "flex-end" },
                          ]}
                        >
                          <Button
                            title={assinaturaFiador ? "Editar" : "Assinar"}
                            onPress={() => setAssinaturaModalFiador(true)}
                          />
                        </View>
                      </>
                    )}
                  </>
                )}

                {restricao && aditamento && (
                  <SelectIn
                    data={formaAditamentoArray}
                    label="Forma de aditamento"
                    value={formaAditamento}
                    setValue={setFormaAditamento}
                  />
                )}

                <DescriptionOut value="Caso o associado tenha indadimplência com a Coobrastur ou restrição ao SPC, deverá constar a opção de fiador ou aditamento." />
              </>
            }
          />
          <DivisorCarousel
            value="Associação"
            children={
              <>
                <SelectIn
                  data={tipoVendaArray}
                  value={tipoVenda}
                  setValue={setTipoVenda}
                  label="Tipo de contrato"
                />

                <SelectIn
                  data={planoArray}
                  value={plano}
                  setValue={setPlano}
                  label="Plano"
                />

                <DescriptionOut
                  value={labelByValue(plano, planoDescricaoArray)}
                />

                <SelectIn
                  data={plano < 41 ? diariasArray : diariasGoArray}
                  value={qtdDiarias}
                  setValue={setQtdDiarias}
                  label="Qtd. diárias"
                />

                {plano < 41 && (
                  <BooleanIn
                    data={booleanArray}
                    value={familia}
                    setValue={setFamilia}
                    label="Família"
                    color={colors.primary}
                  />
                )}

                {familia && (
                  <DescriptionOut value="O plano inclui 3 hóspedes." />
                )}

                {familia === false && (
                  <DescriptionOut value="O plano inclui 2 hóspedes." />
                )}

                {/* {tipoVenda == 1 && Number(plano) < 34 && (
                  <>
                    <BooleanIn
                      data={booleanArray}
                      value={adesao}
                      setValue={setAdesao}
                      label="Adesão"
                      color={colors.primary}
                    />

                    {adesao && (
                      <DescriptionOut value="Preencha os dados para pagamento de adesão." />
                    )}
                  </>
                )} */}

                {plano !== undefined &&
                qtdDiarias !== undefined &&
                familia !== undefined ? (
                  <TextOut
                    label="Valor (mensalidade)"
                    value={formatReal(
                      getMensalidade({
                        plano: plano,
                        diarias: qtdDiarias,
                        familia: familia,
                      })
                    )}
                  />
                ) : (
                  <TextOut
                    label="Mensalidade"
                    value={"Necessário selecionar plano, diárias e família"}
                  />
                )}

                {tipoVenda == 1 && Number(plano) < 34 && adesao && (
                  <DivisorCarousel
                    value="Adesão"
                    children={
                      <>
                        <TextIn
                          value={valorAdesao || ""}
                          setValue={setValorAdesao}
                          type="numero"
                          label="Valor da adesão"
                          placeholder="Digite o valor"
                          limit={8}
                        />

                        {pagamentoAdesao && (
                          <>
                            <BooleanIn
                              data={adesaoMeioArray}
                              value={meioAdesao || ""}
                              setValue={setMeioAdesao}
                              label="Meio de pagamento"
                              color={colors.primary}
                            />

                            {meioAdesao === undefined && (
                              <DescriptionOut value="Selecione uma opção para pagar a adesão." />
                            )}
                          </>
                        )}

                        {meioAdesao == 1 && (
                          <>
                            <DescriptionOut value="A adesão deve ser paga ao vendedor." />

                            <SelectIn
                              data={formaPagamentoAdesaoVendedorArray}
                              value={formaAdesaoVendedor || ""}
                              setValue={setFormaAdesaoVendedor}
                              label="Forma de pagamento"
                            />

                            <SelectIn
                              data={parcelasPagamentoAdesaoArray}
                              value={parcelasAdesaoVendedor || ""}
                              setValue={setParcelasAdesaoVendedor}
                              label="Parcelas"
                            />

                            <TextIn
                              value={dataVendedor || ""}
                              setValue={setDataVendedor}
                              type="data"
                              label="Data da cobrança"
                              placeholder="Digite o dia/mês/ano"
                              limit={8}
                            />

                            <TextIn
                              value={obsVendedor || ""}
                              setValue={setObsVendedor}
                              label="Observação"
                              placeholder="Opcional"
                              limit={200}
                            />
                          </>
                        )}

                        {meioAdesao == 2 && (
                          <>
                            <DescriptionOut value="A adesão será paga através da Coobrastur." />

                            <SelectIn
                              data={formaPagamentoAdesaoCoobrasturArray}
                              value={formaAdesaoCoobrastur || ""}
                              setValue={setFormaAdesaoCoobrastur}
                              label="Forma de pagamento"
                            />

                            <SelectIn
                              data={
                                formaAdesaoCoobrastur == 1
                                  ? valorAdesao > 0
                                    ? getParcelasAdesaoBoletoCoobrasturArray(
                                        valorAdesao
                                      )
                                    : parcelasPagamentoAdesaoArray
                                  : parcelasPagamentoAdesaoArray
                              }
                              value={parcelasAdesaoCoobrastur}
                              setValue={setParcelasAdesaoCoobrastur}
                              label="Parcelas"
                            />

                            <TextIn
                              value={dataCoobrastur || ""}
                              setValue={setDataCoobrastur}
                              type="data"
                              label="Data da cobrança"
                              placeholder="Digite o dia/mês/ano"
                              limit={8}
                            />

                            <TextIn
                              value={obsCoobrastur || ""}
                              setValue={setObsCoobrastur}
                              label="Observação"
                              placeholder="Opcional"
                              limit={200}
                            />
                          </>
                        )}
                      </>
                    }
                  />
                )}
              </>
            }
          />

          <DivisorCarousel
            value="Endereço"
            children={
              <>
                    <TextIn
                      value={cep}
                      setValue={setCep}
                      type="cep"
                      label="CEP"
                      placeholder="Digite o CEP"
                      limit={8}
                    />

                    {enderecoBloqueado ? (
                      <DescriptionOut value="Preencha o CEP para liberar os campos de endereço." />
                    ) : (
                      <>
                        <SelectIn
                          data={ufArray}
                          value={uf}
                          setValue={setUf}
                          label="UF"
                        />

                        <TextIn
                          value={cidade}
                          setValue={setCidade}
                          label="Cidade"
                          placeholder="Digite a cidade"
                          limit={60}
                        />

                        <TextIn
                          value={bairro}
                          setValue={setBairro}
                          label="Bairro"
                          placeholder="Digite a bairro"
                          limit={60}
                        />

                        <TextIn
                          value={rua}
                          setValue={setRua}
                          label="Rua"
                          placeholder="Digite a rua"
                          limit={60}
                        />

                        <TextIn
                          value={numeroRua}
                          setValue={setNumeroRua}
                          type="numero"
                          label="Número"
                          placeholder="Digite a rua"
                          limit={10}
                        />

                        <TextIn
                          value={complementoRua}
                          setValue={setComplementoRua}
                          label="Complemento"
                          placeholder="Digite um complemento (opcional)"
                          limit={80}
                        />
                      </>
                    )}
              </>
            }
          />

          <DivisorCarousel
            value="Mensalidade"
            children={
              <>
                {!(
                  restricao &&
                  alternativaRestricao == 2 &&
                  formaAditamento == 2
                ) ? (
                  <SelectIn
                    data={formaPagamentoMensalidadeArray}
                    value={formaPagamento}
                    setValue={setFormaPagamento}
                    label="Forma de pagamento"
                  />
                ) : (
                  <DescriptionOut value="A alternativa de restrição aditamento imediato só está disponível via cartão de crédito. " />
                )}

                    {formaPagamento == 9 && adesao == false && (
                      <TextIn
                        value={dataVendaSemDados}
                        setValue={setDataVendaSemDados}
                        type="data"
                        label="Data para 1ª mensalidade"
                        placeholder="Ex: 08112021 (somente números)"
                        limit={8}
                      />
                    )}

                {formaPagamento == 1 && (
                  <>
                    <TextIn
                      value={cpfTitularContaCorrente}
                      setValue={setCpfTitularContaCorrente}
                      type="cpf"
                      label="CPF (titular)"
                      placeholder="Digite o CPF"
                      limit={11}
                    />

                    <TextIn
                      value={nomeTitularContaCorrente}
                      setValue={setNomeTitularContaCorrente}
                      type="nome"
                      label="Nome (titular)"
                      placeholder="Nome completo do titular"
                      limit={60}
                    />

                    <SelectIn
                      data={bancoContaArray}
                      value={bancoContaCorrente}
                      setValue={setBancoContaCorrente}
                      label="Banco"
                    />

                    {bancoContaCorrente == 104 && (
                      <SelectIn
                        data={bancoCaixaTipoArray}
                        value={bancoCaixaTipo}
                        setValue={setBancoCaixaTipo}
                        label="Tipo"
                      />
                    )}

                    <View style={{ flexDirection: "row" }}>
                      <TextIn
                        style={{ flex: 2, paddingRight: 0 }}
                        value={numeroContaCorrente}
                        setValue={setNumeroContaCorrente}
                        type="numero"
                        label="Número"
                        placeholder={`Número de ${
                          bancoContaCorrente == 1 ? "até " : ""
                        }${limiteNumeroConta} dígitos`}
                        limit={limiteNumeroConta}
                      />

                      <TextIn
                        value={dvContaCorrente}
                        setValue={setDvContaCorrente}
                        type="numero-primeiro"
                        label="Dígito"
                        placeholder="Dv"
                        limit={1}
                      />
                    </View>

                    {bancoContaCorrente == 41 ? (
                      // se banco for BANRISUL exibe inputs agenciaNumero + agenciaDv
                      <View style={{ flexDirection: "row" }}>
                        <TextIn
                          style={{ flex: 2, paddingRight: 0 }}
                          value={agenciaContaCorrente}
                          setValue={setAgenciaContaCorrente}
                          type="numero"
                          label="Agência"
                          placeholder={`Número de ${limiteNumeroAgencia} dígitos`}
                          limit={limiteNumeroAgencia}
                        />

                        <TextIn
                          value={dvAgenciaContaCorrente}
                          setValue={setDvAgenciaContaCorrente}
                          type="numero-primeiro"
                          label="Dígito Ag."
                          placeholder="Dv Ag."
                          limit={2}
                        />
                      </View>
                    ) : (
                      // se outro banco exibe só input agenciaNumero
                      <TextIn
                        value={agenciaContaCorrente}
                        setValue={setAgenciaContaCorrente}
                        type="numero"
                        label="Agência"
                        placeholder={`Número de ${limiteNumeroAgencia} dígitos`}
                        limit={limiteNumeroAgencia}
                      />
                    )}

                    {adesao ? (
                      <SelectIn
                        data={diaMensalidadeBancoArray}
                        value={diaDebitoConta}
                        setValue={setDiaDebitoConta}
                        label="Dia do débito"
                      />
                    ) : (
                      <TextIn
                        value={dataDebitoConta}
                        setValue={setDataDebitoConta}
                        type="data"
                        label="Data para mensalidade"
                        placeholder="Digite o dia/mês/ano"
                        limit={8}
                      />
                    )}
                  </>
                )}

                {formaPagamento == 2 && (
                  <>
                    <TextIn
                      value={cpfTitularCartao}
                      setValue={setCpfTitularCartao}
                      type="cpf"
                      label="CPF (titular)"
                      placeholder="Digite o CPF"
                      limit={11}
                    />

                    <TextIn
                      value={nomeTitularCartao}
                      setValue={setNomeTitularCartao}
                      type="nome"
                      label="Nome impresso (titular)"
                      placeholder="Nome completo do titular"
                      limit={60}
                    />

                    <SelectIn
                      data={bandeiraCartaoArray}
                      value={bandeiraCartao}
                      setValue={setBandeiraCartao}
                      label="Bandeira"
                    />

                    <TextIn
                      value={numeroCartao}
                      setValue={setNumeroCartao}
                      type="cartao"
                      label="Número do cartão"
                      placeholder="Digite o número do cartão"
                      limit={16}
                    />

                    <TextIn
                      value={mesValidadeCartao}
                      setValue={setMesValidadeCartao}
                      label="Validade (mês)"
                      placeholder={"Mês de 2 dígitos"}
                      type="numero"
                      limit={2}
                    />

                    <TextIn
                      value={anoValidadeCartao}
                      setValue={setAnoValidadeCartao}
                      label="Validade (ano)"
                      placeholder={"Ano de 4 dígitos"}
                      type="numero"
                      limit={4}
                    />

                    <TextIn
                      value={cvvCartao}
                      setValue={setCvvCartao}
                      type="cvv"
                      label="CVV"
                      placeholder="Código de segurança"
                      limit={4}
                    />

                    {adesao ? (
                      <SelectIn
                        data={diaMensalidadeCartaoArray}
                        value={diaFaturaCartao}
                        setValue={setDiaFaturaCartao}
                        label="Cobrança (dia)"
                      />
                    ) : (
                      <TextIn
                        value={dataFaturaCartao}
                        setValue={setDataFaturaCartao}
                        type="data"
                        label="Data para mensalidade"
                        placeholder="Digite o dia/mês/ano"
                        limit={8}
                      />
                    )}
                  </>
                )}
              </>
            }
          />
          {formaPagamento == 1 && terceiro && (
            <>
              <DivisorCarousel
                value="Autorização de terceiro"
                children={
                  <>
                    <DescriptionOut value="O CPF do associado é diferente do CPF da conta bancária, deverá constar os dados para autorização de débito de terceiro." />

                    <TextIn
                      value={emailTerceiro}
                      setValue={setEmailTerceiro}
                      type="email"
                      label="E-mail"
                      placeholder="Digite o e-mail"
                      limit={80}
                    />

                    <TextIn
                      value={dddCelularTerceiro}
                      setValue={setDddCelularTerceiro}
                      type="ddd"
                      label="DDD (celular)"
                      placeholder="Digite o DDD"
                      limit={2}
                    />

                    <TextIn
                      value={celularTerceiro}
                      setValue={setCelularTerceiro}
                      type="telefone"
                      label="Celular"
                      placeholder="Digite o número"
                      limit={9}
                    />

                    <TextIn
                      value={dddTelefoneTerceiro}
                      setValue={setDddTelefoneTerceiro}
                      type="ddd"
                      label="DDD (telefone)"
                      placeholder="Digite o DDD (opcional)"
                      limit={2}
                    />

                    <TextIn
                      value={telefoneTerceiro}
                      setValue={setTelefoneTerceiro}
                      type="residencial"
                      label="Telefone"
                      placeholder="Digite o número (opcional)"
                      limit={8}
                    />

                    <TextIn
                      value={nascimentoTerceiro}
                      setValue={setNascimentoTerceiro}
                      type="data"
                      label="Nascimento"
                      placeholder="Digite o dia/mês/ano"
                      limit={8}
                    />

                    <TextIn
                      value={nacionalidadeTerceiro}
                      setValue={setNacionalidadeTerceiro}
                      type="nome"
                      label="Nacionalidade"
                      placeholder="Digite nacionalidade"
                      limit={40}
                    />

                    <SelectIn
                      data={estadoCivilArray}
                      value={estadoCivilTerceiro}
                      setValue={setEstadoCivilTerceiro}
                      label="Estado civil"
                    />

                    {estadoCivilTerceiro == 2 && (
                      <TextIn
                        value={nomeConjugeTerceiro}
                        setValue={setNomeConjugeTerceiro}
                        type="nome"
                        label="Cônjuge"
                        placeholder="Nome completo"
                        limit={60}
                      />
                    )}

                    <TextIn
                      value={nomeMaeTerceiro}
                      setValue={setNomeMaeTerceiro}
                      type="nome"
                      label="Nome da mãe"
                      placeholder="Digite o nome completo"
                      limit={60}
                    />

                    <TextIn
                      value={nomePaiTerceiro}
                      setValue={setNomePaiTerceiro}
                      type="nome"
                      label="Nome do pai (se houver)"
                      placeholder="Digite o nome completo"
                      limit={60}
                    />

                    <BooleanIn
                      data={formaAssinaturaArrayPlatform}
                      value={formaAssinaturaTerceiro}
                      setValue={setFormaAssinaturaTerceiro}
                      label="Forma de assinatura (Terceiro)"
                      color={colors.primary}
                    />

                    {formaAssinaturaTerceiro == 1 && (
                      <View style={styles.inputContainer}>
                        <Text>Receberá um e-mail para assinar.</Text>
                      </View>
                    )}

                    {formaAssinaturaTerceiro == 2 && (
                      <>
                        {assinaturaTerceiro && (
                          <View style={styles.assinaturaImageContainer}>
                            <Image
                              resizeMode={"contain"}
                              style={styles.assinaturaImage}
                              source={{ uri: assinaturaTerceiro }}
                            />
                          </View>
                        )}

                        <View
                          style={[
                            styles.inputContainer,
                            { alignItems: "flex-end" },
                          ]}
                        >
                          <Button
                            title={assinaturaTerceiro ? "Editar" : "Assinar"}
                            onPress={() => setAssinaturaModalTerceiro(true)}
                          />
                        </View>
                      </>
                    )}
                  </>
                }
              />
            </>
          )}

          {adesao &&
            meioAdesao == 2 &&
            formaAdesaoCoobrastur == 2 &&
            formaPagamento == 1 && (
              <DivisorCarousel
                value="Cartão adicional"
                children={
                  <>
                    <DescriptionOut value="Mensalidade selecionada via débito em conta. Adesão selecionada via cartão de crédito. Preencha os dados abaixo." />

                    <TextIn
                      value={cpfTitularCartao}
                      setValue={setCpfTitularCartao}
                      type="cpf"
                      label="CPF (titular)"
                      placeholder="Digite o CPF"
                      limit={11}
                    />

                    <TextIn
                      value={nomeTitularCartao}
                      setValue={setNomeTitularCartao}
                      type="nome"
                      label="Nome impresso (titular)"
                      placeholder="Nome completo do titular"
                      limit={60}
                    />

                    <SelectIn
                      data={bandeiraCartaoArray}
                      value={bandeiraCartao}
                      setValue={setBandeiraCartao}
                      label="Bandeira"
                    />

                    <TextIn
                      value={numeroCartao}
                      setValue={setNumeroCartao}
                      type="cartao"
                      label="Número do cartão"
                      placeholder="Digite o número do cartão"
                      limit={16}
                    />

                    <TextIn
                      value={mesValidadeCartao}
                      setValue={setMesValidadeCartao}
                      label="Validade (mês)"
                      placeholder={"Mês de 2 dígitos"}
                      type="numero"
                      limit={2}
                    />

                    <TextIn
                      value={anoValidadeCartao}
                      setValue={setAnoValidadeCartao}
                      label="Validade (ano)"
                      placeholder={"Ano de 4 dígitos"}
                      type="numero"
                      limit={4}
                    />

                    <TextIn
                      value={cvvCartao}
                      setValue={setCvvCartao}
                      type="cvv"
                      label="CVV"
                      placeholder="Código de segurança"
                      limit={4}
                    />
                  </>
                }
              />
            )}

          <DivisorCarousel
            value="Resumo"
            children={
              <>
                <TextOut
                  label="CPF"
                  value={masker(cpf, "000.000.000-00") || "-"}
                />

                <TextOut label="Nome" value={nome} />

                <TextOut label="E-mail" value={email} />

                <TextOut
                  label="Celular"
                  value={
                    dddCelular && celular
                      ? `(${dddCelular}) ${masker(celular, "0 0000-0000")}`
                      : "-"
                  }
                />

                {adesao == true && meioAdesao == 2 ? (
                  <>
                    <TextOut label="CEP" value={masker(cep, "00000-000")} />
                    <TextOut label="UF" value={uf} />
                    <TextOut label="Cidade" value={cidade} />
                    <TextOut label="Bairro" value={bairro} />
                    <TextOut label="Rua" value={rua} />
                    <TextOut label="Número" value={numeroRua} />
                    <TextOut label="Complemento" value={complementoRua} />
                  </>
                ) : (
                  <TextOut label="UF" value={uf} />
                )}

                <TextOut
                  label="Indicador"
                  value={indicador ? nomeIndicador || "Sim" : "Não"}
                />

                {restricao && alternativaRestricao == 1 && (
                  <>
                    <TextOut label="Restrição" value="Alternativa fiador" />

                    <TextOut
                      label="CPF (fiador)"
                      value={masker(cpfFiador, "000.000.000-00")}
                    />

                    <TextOut label="Nome (fiador)" value={nomeFiador} />

                    <TextOut
                      label="Nascimento (fiador)"
                      value={masker(nascimentoFiador, "00/00/0000")}
                    />

                    <TextOut
                      label="Nacionalidade (fiador)"
                      value={nacionalidadeFiador}
                    />

                    <TextOut
                      label="Estado civil (fiador)"
                      value={labelByValue(estadoCivilFiador, estadoCivilArray)}
                    />

                    <TextOut
                      label="Cônjuge (fiador)"
                      value={nomeConjugeFiador}
                    />

                    <TextOut label="Mãe (fiador)" value={nomeMaeFiador} />

                    <TextOut label="Pai (fiador)" value={nomePaiFiador} />

                    <TextOut label="E-mail (fiador)" value={emailFiador} />

                    <TextOut
                      label="Celular (fiador)"
                      value={
                        dddCelularFiador && celularFiador
                          ? `(${dddCelularFiador}) ${masker(
                              celularFiador,
                              "0 0000-0000"
                            )}`
                          : "-"
                      }
                    />

                    <TextOut
                      label="Telefone (fiador)"
                      value={
                        dddTelefoneFiador && telefoneFiador
                          ? `(${dddTelefoneFiador}) ${masker(
                              telefoneFiador,
                              "0 0000-0000"
                            )}`
                          : "-"
                      }
                    />

                    <TextOut
                      label="Forma assinatura (fiador)"
                      value={labelByValue(
                        formaAssinaturaFiador,
                        formaAssinaturaArray
                      )}
                    />
                  </>
                )}

                {restricao && alternativaRestricao == 2 && (
                  <TextOut
                    label="Restrição"
                    value={`${
                      labelByValue(alternativaRestricao, restricaoArray) || "-"
                    }, ${
                      labelByValue(formaAditamento, formaAditamentoArray) || "-"
                    }`}
                  />
                )}

                <TextOut
                  label="Tipo de contrato"
                  value={
                    tipoVenda ? labelByValue(tipoVenda, tipoVendaArray) : "-"
                  }
                />

                <TextOut
                  label="Plano"
                  value={plano ? labelByValue(plano, planoArray) : "-"}
                />

                <TextOut
                  label="Qtd. Diárias"
                  value={
                    qtdDiarias && plano
                      ? labelByValue(
                          qtdDiarias,
                          plano < 41 ? diariasArray : diariasGoArray
                        )
                      : "-"
                  }
                />

                <TextOut
                  label="Plano família"
                  value={familia ? "Sim" : "Não"}
                />

                {adesao && (
                  <>
                    <TextOut
                      label="Valor (adesão)"
                      value={valorAdesao ? `R$ ${valorAdesao}` : "-"}
                    />

                    {pagamentoAdesao && (
                      <TextOut
                        style={styles.capitalizeText}
                        label="Meio de pagamento (adesão)"
                        value={
                          meioAdesao
                            ? labelByValue(meioAdesao, adesaoMeioArray)
                            : " "
                        }
                      />
                    )}
                  </>
                )}

                {adesao && meioAdesao == 1 && (
                  <>
                    <TextOut
                      label="Forma de pagamento (adesão)"
                      value={
                        formaAdesaoVendedor
                          ? labelByValue(
                              formaAdesaoVendedor,
                              formaPagamentoAdesaoVendedorArray
                            )
                          : " "
                      }
                    />

                    <TextOut
                      label="Parcelas (adesão)"
                      value={
                        parcelasAdesaoVendedor
                          ? labelByValue(
                              parcelasAdesaoVendedor,
                              parcelasPagamentoAdesaoArray
                            )
                          : " "
                      }
                    />

                    <TextOut
                      label="Data da cobrança (adesão)"
                      value={
                        String(dataVendedor).length == 8
                          ? masker(dataVendedor, "00/00/0000")
                          : "-"
                      }
                    />

                    {obsVendedor && (
                      <TextOut label="Observação" value={obsVendedor || "-"} />
                    )}
                  </>
                )}

                {adesao && meioAdesao == 2 && (
                  <>
                    <TextOut
                      label="Forma de pagamento (adesão)"
                      value={
                        formaAdesaoCoobrastur
                          ? labelByValue(
                              formaAdesaoCoobrastur,
                              formaPagamentoAdesaoCoobrasturArray
                            )
                          : "-"
                      }
                    />

                    <TextOut
                      label="Parcelas (adesão)"
                      value={
                        parcelasAdesaoCoobrastur
                          ? labelByValue(
                              parcelasAdesaoCoobrastur,
                              parcelasPagamentoAdesaoArray
                            )
                          : "-"
                      }
                    />

                    <TextOut
                      label="Data de cobrança (adesão)"
                      value={
                        String(dataCoobrastur).length == 8
                          ? masker(dataCoobrastur, "00/00/0000")
                          : "-"
                      }
                    />

                    {obsCoobrastur && (
                      <TextOut
                        label="Observação (adesão)"
                        value={obsCoobrastur || "-"}
                      />
                    )}
                  </>
                )}

                {plano !== undefined &&
                qtdDiarias !== undefined &&
                familia !== undefined ? (
                  <>
                    <TextOut
                      label="Valor (mensalidade)"
                      value={
                        formatReal(
                          getMensalidade({
                            plano: plano,
                            diarias: qtdDiarias,
                            familia: familia,
                          })
                        ) || "-"
                      }
                    />

                    <TextOut
                      label="Primeira cobrança (mensalidade)"
                      value={
                        adesao
                          ? String(dataCoobrastur).length >= 8 ||
                            String(dataVendedor).length >= 8
                            ? getDataPrimeiraMensalidade({
                                adesao,
                                meioAdesao,
                                parcelasAdesaoCoobrastur,
                                parcelasAdesaoVendedor,
                                dataVendedor,
                                dataCoobrastur,
                                formaPagamento,
                                dataDebitoConta,
                                dataFaturaCartao,
                                dataVendaSemDados
                              })
                            : "Necessário preencher data para cobrança de adesão"
                          : formaPagamento == 1
                          ? String(dataDebitoConta).length >= 8
                            ? masker(dataDebitoConta, "00/00/0000")
                            : "Necessário preencher data para pagamento da mensalidade via débito em conta."

                            : formaPagamento == 9 && adesao === false
                            ? String(dataVendaSemDados).length >= 8
                              ? masker(dataVendaSemDados, "00/00/0000")
                              : "Necessário preencher data para pagamento da mensalidade"

                          : formaPagamento == 2
                          ? String(dataFaturaCartao).length >= 8
                            ? masker(dataFaturaCartao, "00/00/0000")
                            : "Necessário preencher data para pagamento da mensalidade via cartão."
                          : "Necessário selecionar uma forma de pagamento."
                      }
                    />

                    {formaPagamento && (
                      <TextOut
                        label="Forma de pagamento (mensalidade)"
                        value={labelByValue(
                          formaPagamento,
                          formaPagamentoMensalidadeArray
                        )}
                      />
                    )}


                    {formaPagamento == 1 && (
                      <>
                        <TextOut
                          label="CPF (titular)"
                          value={
                            masker(cpfTitularContaCorrente, "000.000.000-00") ||
                            "-"
                          }
                        />

                        <TextOut
                          label="Nome do titular (mensalidade)"
                          value={nomeTitularContaCorrente || "-"}
                        />

                        <TextOut
                          label="Banco"
                          value={
                            labelByValue(bancoContaCorrente, bancoContaArray) ||
                            "-"
                          }
                        />

                        <TextOut
                          label="Número e DV"
                          value={
                            numeroContaCorrente && dvContaCorrente
                              ? numeroContaCorrente + "-" + dvContaCorrente
                              : "-"
                          }
                        />

                        <TextOut
                          label="Agência"
                          value={agenciaContaCorrente || "-"}
                        />

                        {Number(bancoContaCorrente) == 41 && (
                          <TextOut
                            label="Dv Agência"
                            value={dvAgenciaContaCorrente}
                          />
                        )}

                        <TextOut
                          label="Dia (débito)"
                          value={
                            adesao
                              ? labelByValue(
                                  diaDebitoConta,
                                  diaMensalidadeBancoArray
                                )
                              : masker(dataDebitoConta, "00/00/0000") || "-"
                          }
                        />
                      </>
                    )}

                    {formaPagamento == 2 && (
                      <>
                        <TextOut
                          label="Nome do titular (mensalidade)"
                          value={nomeTitularCartao || "-"}
                        />
                        <TextOut
                          label="CPF do titular (mensalidade)"
                          value={
                            masker(cpfTitularCartao, "000.000.000-00") || "-"
                          }
                        />
                        <TextOut
                          label="Número do cartão (mensalidade)"
                          value={
                            masker(numeroCartao, "0000 0000 0000 0000") || "-"
                          }
                        />

                        <TextOut
                          label="Validade do cartão (mensalidade)"
                          value={
                            mesValidadeCartao && anoValidadeCartao
                              ? mesValidadeCartao + "/" + anoValidadeCartao
                              : ""
                          }
                        />

                        <TextOut
                          label="Dia de cobrança (mensalidade)"
                          value={
                            adesao
                              ? labelByValue(
                                  diaFaturaCartao,
                                  diaMensalidadeCartaoArray
                                )
                              : dataFaturaCartao
                          }
                        />
                      </>
                    )}
                  </>
                ) : (
                  <TextOut
                    label="Mensalidade"
                    value={"Necessário selecionar plano, diárias e família"}
                  />
                )}

                <DescriptionOut value="Informações sobre e-tickets, hospedagens e pagamentos constarão no contrato enviado posteriormente ao novo associado via e-mail." />
              </>
            }
          />

          <DivisorCarousel
            value="Assinatura"
            children={
              <>
                <BooleanIn
                  data={formaAssinaturaArrayPlatform}
                  value={formaAssinatura}
                  setValue={setFormaAssinatura}
                  label="Forma de assinatura"
                  color={colors.primary}
                />

                {formaAssinatura == 1 && (
                  <View style={styles.inputContainer}>
                    <Text>Receberá um e-mail para assinar.</Text>
                  </View>
                )}

                {formaAssinatura == 2 && (
                  <>
                    {assinatura && (
                      <View style={styles.assinaturaImageContainer}>
                        <Image
                          resizeMode={"contain"}
                          style={styles.assinaturaImage}
                          source={{ uri: assinatura }}
                        />
                      </View>
                    )}

                    <View
                      style={[
                        styles.inputContainer,
                        { alignItems: "flex-end" },
                      ]}
                    >
                      <Button
                        title={assinatura ? "Editar" : "Assinar"}
                        onPress={() => setAssinaturaModal(true)}
                      />
                    </View>
                  </>
                )}
              </>
            }
          />

          {Number(new Date().getMonth()) >= 9 && (
            <TextOut
              style={{ color: "rgb(245,70,85)" }}
              value="Este contrato pode estar sujeito à adiamento da liberação de e-tickets por conta da tabela restritiva (Novembro e Dezembro)."
            />
          )}

          <View style={styles.inputContainer}>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                style={styles.submitDelete}
                onPress={submitDelete}
              >
                <Text style={styles.submitDeleteText}>deletar</Text>
              </TouchableOpacity>

              <TouchableOpacity style={styles.submit} onPress={checkAndSubmit}>
                <Text style={styles.submitText}>salvar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Animated.ScrollView>
      </>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    backgroundColor: "rgb(240,240,245)",
  },

  content: {
    flex: 1,
    maxWidth: 720,
    width: "100%",
  },

  title: {
    flex: 1,
    alignSelf: "center",
    padding: 10,
    textTransform: "capitalize",
    fontSize: 18,
  },

  subTitle: {
    flex: 1,
    alignSelf: "center",
    padding: 10,
    textTransform: "capitalize",
    fontSize: 16,
    color: "#999",
  },

  hrContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  hrLine: {
    borderBottomWidth: 1,
    borderColor: "#999",
    height: 1,
    flex: 1,
  },

  hrTitle: {
    color: "#444",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "capitalize",
    margin: 5,
  },

  submit: {
    flex: 1,

    height: 45,
    borderRadius: 5,
    marginBottom: 20,
    alignSelf: "flex-end",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
  },

  submitText: {
    color: "#fff",
    fontSize: 16,
    textTransform: "uppercase",
  },

  submitDelete: {
    flex: 1,
    marginRight: 10,
    height: 45,
    borderRadius: 5,
    marginBottom: 20,
    alignSelf: "flex-end",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: colors.danger,
  },

  submitDeleteText: {
    color: "#fff",
    fontSize: 16,
    textTransform: "uppercase",
  },
  inputContainer: {
    padding: 20,
  },

  label: {
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "capitalize",
  },
  labelUppercase: {
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "uppercase",
  },
  labelUnderline: {
    fontWeight: "bold",
    marginBottom: 10,
    textDecorationLine: "underline",
  },

  subLabel: {
    fontWeight: "300",
    marginTop: 10,
    textTransform: "capitalize",
    color: "#999",
  },
  subLabelUppercase: {
    fontWeight: "300",
    marginTop: 10,
    textTransform: "uppercase",
    color: "#999",
  },

  input: {
    flex: 1,
    height: 45,
    borderRadius: 5,
    backgroundColor: "#fff",
    paddingHorizontal: 10,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  selectInput: {
    flex: 1,
    borderRadius: 5,
    minHeight: 45,
    maxHeight: 120,
    backgroundColor: "#fff",
    overflow: "hidden",
    justifyContent: "center",
  },

  selectDate: {
    flexDirection: "row",
    backgroundColor: "#fff",
    borderRadius: 5,
  },

  selectDateItem: {
    flex: 1,

    minHeight: 45,
    maxHeight: 120,

    overflow: "hidden",
    justifyContent: "center",
  },

  radioButton: {
    flex: 1,
    height: 36,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
  },

  radioButtonCircle: {
    marginRight: 10,
    borderColor: "#BFBFBF",
    borderWidth: 3,
    borderRadius: 50,
    height: 20,
    width: 20,
  },

  radioButtonCircleActive: {
    borderColor: "#566d7c",
    backgroundColor: colors.primary,
  },

  selectedRadioButton: {
    backgroundColor: "#fff",
  },

  upperCase: {
    textTransform: "uppercase",
  },

  // assinatura

  assinaturaModalContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    zIndex: 2,
  },

  assinaturaModal: {
    flex: 1,
    width: "100%",
  },

  assinaturaImageContainer: {
    padding: 0,
    margin: 0,

    height: Dimensions.get("window").width,
    width: Dimensions.get("window").width,

    backgroundColor: "#F8F8F8",
  },

  assinaturaImage: {
    width: "100%",
    height: "100%",
  },

  headerRightButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: 10,
  },

  headerRightButtonText: {
    color: "#fff",
    fontSize: 18,
  },

  headerButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: 10,
  },

  headerButtonText: {
    color: "#fff",
    fontSize: 18,
  },
});

export default Update;
