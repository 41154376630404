import { call, delay, put, takeLatest, all } from "redux-saga/effects";
import api from "../../services/api";

function* sign() {
  yield all([
    yield takeLatest("REQUEST_SIGN_IN", asyncSignIn),
    yield takeLatest("REQUEST_SIGN_OUT", asyncSignOut),
  ]);
}

function* asyncSignIn(action) {
  if (!action.payload.usuario) {
    return yield put({
      type: "FAILURE_SIGN_IN",
      payload: {
        error: "Preencha o e-mail.",
      },
    });
  }

  if (!action.payload.password) {
    return yield put({
      type: "FAILURE_SIGN_IN",
      payload: {
        error: "Preencha a senha.",
      },
    });
  }

  try {
    const ipAcesso = action.payload.ipAcesso;
    const usuario = action.payload.usuario;
    const senha = action.payload.password;

    const request = {
      ipAcesso,
      usuario,
      senha,
    };

    const response = yield call(api.post, "returnUsuariosLogonAPP", request);

    if (response?.data?.code === 400) {
      switch (response.data.body.code) {
        case 1:
          return yield put({
            type: "FAILURE_SIGN_IN",
            payload: {
              error: "Usuário não encontrado.",
            },
          });

        case 2:
          return yield put({
            type: "FAILURE_SIGN_IN",
            payload: {
              error: "Senha incorreta.",
            },
          });

        case 3:
          return yield put({
            type: "FAILURE_SIGN_IN",
            payload: {
              error: "Usuário inativo.",
            },
          });

        case 4:
          return yield put({
            type: "FAILURE_SIGN_IN",
            payload: {
              error: "Houve um erro interno. Tende depois.",
            },
          });

        default:
          break;
      }
    }

    const { token } = yield response.data.header;
    const { email, nome, cpf, pagamentoAdesao, codigoVendedor } = yield response
      .data.body;

    const data = yield {
      usuarioVendedor: usuario,
      token,
      email,
      usuario,
      nome,
      cpf,
      pagamentoAdesao,
      codigoVendedor,
    };

    yield put({
      type: "SUCCESS_SIGN_IN",
      payload: { data },
    });
  } catch (e) {
    yield put({
      type: "FAILURE_SIGN_IN",
      payload: {
        error: "Falha ao conectar, tente mais tarde.",
      },
    });
  }
}

function* asyncSignOut() {
  yield put({
    type: "SUCCESS_SIGN_OUT",
  });
}

export default sign;
