import {
  lastDayOfMonth,
  differenceInYears,
  isValid,
  isAfter,
  isBefore,
  setDate,
  differenceInDays,
  addMonths,
  subMonths,
  addYears,
  subYears,
  format,
  getYear,
  getMonth,
} from "date-fns";

import ptBr from "date-fns/locale/pt-BR";

import {
  restricaoArray,
  booleanArray,
  ufArray,
  tipoVendaArray,
  planoArray,
  planoDescricaoArray,
  diariasArray,
  diariasGoArray,
  formaPagamentoMensalidadeArray,
  bandeiraCartaoArray,
  diaMensalidadeBancoArray,
  diaMensalidadeCartaoArray,
  bancoContaArray,
  estadoCivilArray,
  formaAditamentoArray,
  formaAssinaturaArray,
  adesaoMeioArray,
  formaPagamentoAdesaoVendedorArray,
  formaPagamentoAdesaoCoobrasturArray,
  parcelasPagamentoAdesaoArray,
  valorMensalidadePlanoArray,
  valorMensalidadePlanoFamiliaArray,
  acrescimoMensalidadeArray,
} from "../../screens/Contracts/datas";

export const converterNumeroCaixa = (numero) => {
  if (!numero) return "";

  // se o numero for 1234
  // entao essa funcao ira preencher com zeros a esquerda
  // retornando entao 00001234
  // porem essa funcionalidade foi inativa
  // entao só retorno o numero
  // caso essa regra for inativada, basta adicionar "return numero" abaixo.
  // return numero;

  const completarDigitos = 8;

  if (String(numero).length >= completarDigitos) return String(numero);

  const digitosMaximo = completarDigitos;
  const digitosNumero = String(numero).length;
  const digitosTotais = "000000000";

  const formulaSlice = digitosMaximo - digitosNumero;
  const digitosZeroEsquerda = digitosTotais.slice(0, formulaSlice);

  const digitosFormatados = String(digitosZeroEsquerda + numero);
  return digitosFormatados;
};

export const getLimiteDigitosConta = ({ banco }) => {
  switch (Number(banco)) {
    case 1:
      // label: "001-B. BRASIL" },
      return 7;

    case 33:
      // label: "033-SANTANDER" },
      return 9;

    case 41:
      // label: "041-BANRISUL" },
      return 9;

    case 104:
      // label: "104-CAIXA" },
      // 2 tipo + 8 digitos conta + 1 dv
      return 8;

    case 237:
      // label: "237-BRADESCO" },
      return 7;

    case 341:
      // label: "341-ITAÚ" },
      return 5;

    case 748:
      // label: "748-SICREDI" },
      return 5;

    default:
      return;
  }
};

export const getLimiteDigitosAgencia = ({ banco }) => {
  switch (Number(banco)) {
    // label: "001-B. BRASIL" },
    case 1:
      return 4;

    // label: "033-SANTANDER" },
    case 33:
      return 4;

    // label: "041-BANRISUL" },
    case 41:
      return 4;

    // label: "104-CAIXA" },
    case 104:
      return 4;

    // label: "237-BRADESCO" },
    case 237:
      return 4;

    // label: "341-ITAÚ" },
    case 341:
      return 4;

    // label: "748-SICREDI" },
    case 748:
      return 4;

    default:
      return;
  }
};

export const getMensalidade = ({ plano, diarias, familia }) => {
  const isFamilia = plano < 41 ? familia : false;

  const array = isFamilia
    ? valorMensalidadePlanoFamiliaArray
    : valorMensalidadePlanoArray;
    const filtered = array.filter((i) => i.value == plano && i);

  // Ajusta mensalidade se o cálculo for feito antes, ou depois do dia 01/06/2021
  let mensalidade;
  let diaria;

  var str = "01/06/2021";
  var date = new Date(str.split("/").reverse().join("/"));
  var novaData = new Date();

  if (novaData < date) {
    mensalidade = filtered[0].mensalidade;
    diaria = mensalidade / 7;
  }

  if (novaData >= date) {
    mensalidade = filtered[0].novaMensalidade;
    diaria = mensalidade / 7;
  }

  const valorMensalidade = diaria * diarias;

  const getValorMensalidade = (valor) => {
    const valorStr = String(valor.toFixed(2));

    const reaisStr = valorStr.substring(0, valorStr.indexOf("."));
    const centavosStr = valorStr.substring(
      valorStr.indexOf("."),
      valorStr.length
    );

    const reais = Number(reaisStr);
    const centavos = Number(centavosStr);
    // const centavos = Number(centavosStr) >= 0.9 ? Number(centavosStr) : 0.9;

    return reais + centavos;
  };

  if (diarias < 7) {
    const filtered = acrescimoMensalidadeArray.filter(
      (item) => item.value == diarias && item
    );

    const acrescimo = filtered[0].acrescimo;
    const valorAcrescimo = (valorMensalidade * acrescimo) / 100;

    const valorMensalidadeAcrescido = valorMensalidade + valorAcrescimo;
    return getValorMensalidade(valorMensalidadeAcrescido);
  }

  return getValorMensalidade(valorMensalidade);
};

export const getDayFromString = (dateStr) => {
  return String(dateStr.slice(0, 2));
};

export const getMonthFromString = (dateStr) => {
  return String(dateStr.slice(2, 4));
};

export const getYearFromString = (dateStr) => {
  return String(dateStr.slice(4, 8));
};

export const getDataPrimeiraMensalidade = ({
  adesao,
  meioAdesao,
  parcelasAdesaoCoobrastur,
  parcelasAdesaoVendedor,
  dataCoobrastur,
  dataVendedor,
  formaPagamento,
  dataDebitoConta,
  dataFaturaCartao,
  dataVendaSemDados
}) => {
  if (!adesao) {
    let dataStr = null;
    
    dataStr = formaPagamento == 1 ? dataDebitoConta : dataFaturaCartao;

    if(formaPagamento == 9) {
      dataStr = dataVendaSemDados;
    }

    if (!dataStr) return false;
    if (dataStr.length !== 8) return false;

    const dia = dataStr.slice(0, 2);
    const mes = dataStr.slice(2, 4);
    const ano = dataStr.slice(4, 8);

    const dataCobranca = new Date(ano, mes - 1, dia);

    const month = format(dataCobranca, "MMM", { locale: ptBr });
    const year = getYear(dataCobranca);

    return `${month}/${year}`;
  }

  const parcelas =
    meioAdesao === 1 ? parcelasAdesaoVendedor : parcelasAdesaoCoobrastur;
  const dataStr = meioAdesao === 1 ? dataVendedor : dataCoobrastur;

  if (!dataStr) return false;
  if (dataStr.length !== 8) return false;

  const dia = dataStr.slice(0, 2);
  const mes = dataStr.slice(2, 4);
  const ano = dataStr.slice(4, 8);

  const dataCobranca = new Date(ano, mes - 1, dia);
  const dataPrimeiraMensalidade = addMonths(new Date(dataCobranca), parcelas);

  const month = format(new Date(dataPrimeiraMensalidade), "MMM", {
    locale: ptBr,
  });
  const year = getYear(new Date(dataPrimeiraMensalidade));

  return `${month}/${year}`;
};

export const getDataLiberacaoAditamento = ({
  adesao,
  meioAdesao,
  parcelasAdesaoCoobrastur,
  parcelasAdesaoVendedor,
  dataCoobrastur,
  dataVendedor,
}) => {
  if (!adesao) {
    const currentDate = new Date();

    const dataLiberacao = addYears(new Date(currentDate), 1);

    const month = format(dataLiberacao, "MMM", { locale: ptBr });
    const year = getYear(dataLiberacao);

    return `${month}/${year}`;
  }

  const parcelas =
    meioAdesao === 1 ? parcelasAdesaoVendedor : parcelasAdesaoCoobrastur;
  const dataStr = meioAdesao === 1 ? dataVendedor : dataCoobrastur;

  if (!dataStr) return false;

  if (dataStr.length !== 8) return false;

  const dia = dataStr.slice(0, 2);
  const mes = dataStr.slice(2, 4);
  const ano = dataStr.slice(4, 8);

  const data = new Date(ano, mes, dia);
  const dataPrimeiraMensalidade = addMonths(new Date(data), parcelas - 1);

  const dataLiberacao = addYears(new Date(dataPrimeiraMensalidade), 1);

  const month = format(new Date(dataLiberacao), "MMM", {
    locale: ptBr,
  });
  const year = getYear(new Date(dataLiberacao));

  return `${month}/${year}`;
};

export const getParcelasAdesaoBoletoCoobrasturArray = (valor) => {
  const parcelas = [
    { value: 1, label: "À vista" },
    { value: 2, label: "2x" },
    { value: 3, label: "3x" },
  ];

  if (valor / 3 >= 100) return parcelas;
  if (valor / 2 >= 100) return [parcelas[0], parcelas[1]];

  return [parcelas[0]];
};

export const labelByValue = (value, array) => {
  const filtered = array.filter((item) => item.value == value && item);
  return filtered[0]?.label || "-";
};

export const datebyString = (string) => {
  if (string.length !== 8) return false;

  const dataStr = string;

  const dia = dataStr.slice(0, 2);
  const mes = dataStr.slice(2, 4);
  const ano = dataStr.slice(4, 8);

  return new Date(ano, mes, dia);
};

export const formatReal = (valor) => {
  const number = Number(valor);

  const formatedNumber = number
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  return "R$ " + formatedNumber;
};

export const checkCpf = (cpf) => {
  const strCPF = cpf.replace(/[^\d]+/g, "");

  var Soma;
  var Resto;

  Soma = 0;
  if (strCPF == "00000000000") return false;

  for (let n = 1; n <= 9; n++)
    Soma = Soma + parseInt(strCPF.substring(n - 1, n)) * (11 - n);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let s = 1; s <= 10; s++)
    Soma = Soma + parseInt(strCPF.substring(s - 1, s)) * (12 - s);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export const checkStringDate = (date) => {
  if (date === undefined || date.length !== 8) return false;

  return true;
};

export const checkDataCobranca = (date, msg) => {
  if (!date) throw msg + " precisa ter uma data, por favor preencher.";

  if (!checkStringDate(date))
    throw msg + " precisa ter uma data de cobrança válida, por favor corrigir.";

  const dia = date.slice(0, 2);
  const mes = date.slice(2, 4);
  const ano = date.slice(4, 8);

  // const anoMax = getYear(new Date()) + 2;
  // const mesMax = getMonth(new Date()) + 6;
  

  if (dia < 1 || dia > 31) throw `${msg} precisa ter dia de cobrança válido.`;
  if (mes < 1 || mes > 12) throw `${msg} precisa ter mês de cobrança válido.`;
  // if (ano > anoMax) throw `${msg} precisa ter o ano menor que ${anoMax}.`;
  // if (mes > mesMax) throw `${msg} precisa ter o mês menor que ${mesMax}.`;

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const dataAtual = new Date(currentYear, currentMonth, currentDay, 0, 0, 0, 0);

  const dataAtualAcrescida = new Date(addMonths(dataAtual, 3));
  const dataLimite = new Date(lastDayOfMonth(dataAtualAcrescida));

  const dataCobranca = new Date(ano, mes - 1, dia, 0, 0, 0, 0);

  if (!isValid(dataCobranca))
    throw `${msg} precisa ter uma data de cobrança válida.`;

  const minimo = isBefore(dataCobranca, dataAtual);
  const maximo = isAfter(dataCobranca, dataLimite);

  if (minimo) throw `${msg} não pode ser anterior a data atual.`;
  if (maximo) throw `${msg} não pode exceder 3 meses da data atual.`;
};

export const checkMaiorIdade = (date, msg) => {
  if (date.length !== 8)
    throw `${msg} precisa ter uma dada de nascimento válida.`;

  const dia = date.slice(0, 2);
  const mes = date.slice(2, 4);
  const ano = date.slice(4, 8);

  if (dia < 1 || dia > 31) throw `${msg} precisa ter dia de nascimento válido.`;
  if (mes < 1 || mes > 12) throw `${msg} precisa ter mês de nascimento válido.`;

  const dataAtual = new Date();
  const dataNascimento = new Date(ano, mes - 1, dia);

  if (!isValid(dataNascimento))
    throw `${msg} precisa ter uma dada de nascimento válida.`;

  const idade = differenceInYears(dataAtual, dataNascimento);

  if (idade < 18) throw `${msg} precisa ter mais de 18 anos.`;
  if (idade > 130) throw `${msg} precisa ter menos de 130 anos.`;
};

export const validateValues = (contract) => {
  // dados pessoais
  if (!contract.cpf) throw "Preencha o CPF do associado";

  if (!contract.formaPagamento) throw "Preencha a forma de pagamento";

  if (!checkCpf(contract.cpf))
    throw "Por favor, preencha um CPF válido para o novo associado.";

  if (!contract.nome) throw "Preencha o nome do associado";

  // contato
  if (!contract.email) throw "Preencha o email do associado";
  if (!contract.dddCelular) throw "Preencha o DDD do celular do associado";
  if (!contract.celular) throw "Preencha o celular do associado";

  // indicação dados pessoais
  if (contract.indicador === undefined)
    throw "Selecione uma opção para indicador";

  if (contract.indicador) {
    if (!contract.cpfIndicador) throw `Preencha o CPF do indicador`;

    if (contract?.cpf === contract?.cpfIndicador)
      throw "O CPF do indicador não pode ser igual ao CPF do novo associado.";

    if (contract.cpfIndicador)
      if (!checkCpf(contract.cpf))
        throw "Por favor, preencha um CPF válido para o indicador.";

    if (contract?.nome === contract?.nomeIndicador)
      throw "O nome do indicador não pode ser igual ao nome do novo associado.";

    if (contract?.email === contract?.emailIndicador)
      throw "O e-mail do indicador não pode ser igual ao e-mail do novo associado.";

    if (contract?.celular === contract?.celularIndicador)
      throw "O celular do indicador não pode ser igual ao celular do novo associado.";
  }

  // restricao
  if (contract.restricao === undefined)
    throw "Selecione uma opção para restrição do associado";

  if (contract.restricao) {
    if (!contract.alternativaRestricao)
      throw "Selecione uma alternativa para restricao do associado";

    // aditamento
    if (contract.aditamento)
      if (!contract.formaAditamento) throw "Selecione uma forma de aditamento";

    // fiador
    if (contract.fiador) {
      if (!contract.cpfFiador) throw "Preencha o CPF do fiador";

      if (contract?.cpf === contract?.cpfFiador)
        throw "O CPF do fiador não pode ser igual ao CPF do novo associado.";

      if (!checkCpf(contract.cpfFiador))
        throw "Por favor, preencha um CPF válido para o fiador.";

      if (!contract.nomeFiador) throw "Preencha o nome do fiador";
      if (!contract.nascimentoFiador) throw "Preencha o nascimento do fiador";

      if (!checkStringDate(contract.nascimentoFiador))
        throw "A data de nascimento do fiador é inválida, por favor corrigir.";

      checkMaiorIdade(contract.nascimentoFiador, "O fiador");

      if (!contract.nacionalidadeFiador)
        throw "Preencha a nacionalidade do fiador";
      if (!contract.estadoCivilFiador)
        throw "Selecione o estado civil do fiador";

      if (contract.estadoCivilFiador === 2)
        if (!contract.nomeConjugeFiador)
          throw "Preencha o nome do conjuge do fiador";

      if (!contract.nomeMaeFiador) throw "Preencha o nome da mãe do fiador";

      if (!contract.emailFiador) throw "Preencha o email do fiador";

      if (contract?.email === contract?.emailFiador)
        throw "O e-mail do fiador não pode ser igual ao e-mail do novo associado.";

      if (!contract.dddCelularFiador) throw "Preencha o celular do fiador";
      if (!contract.celularFiador) throw "Preencha o celular do fiador";

      if (contract?.celular === contract?.celularFiador)
        throw "O celular do fiador não pode ser igual ao celular do novo associado.";

      if (!contract.formaAssinaturaFiador)
        throw "Selecione uma forma de assinatura para o fiador";

      if (contract.formaAssinaturaFiador === 2)
        if (!contract.assinaturaFiador)
          throw "Na opção de assinatura offline(dedo), o fiador precisa assinar";
    }
  }

  // associação / dados venda
  if (!contract.tipoVenda) throw "Selecione o tipo de contrato do associado";

  if (contract.tipoVenda == 2) {
    if (contract.adesao)
      throw `O tipo de venda "reativação" não permite cobrança de adesão.`;
  }

  if (!contract.plano) throw "Selecione o plano do associado";
  if (!contract.qtdDiarias) throw "Selecione as diárias do plano";
  if (contract.familia === undefined)
    throw "Selecione uma opção para plano familia";

  // adesão
  if (contract.adesao === undefined) throw "Selecione uma opção para adesão";

  if (contract.adesao) {
    if (!contract.valorAdesao || contract.valorAdesao < 1)
      throw "Preencha o valor da adesão";

    if (!contract.meioAdesao) throw "Selecione o meio de pagamento da adesão";

    if (contract.meioAdesao == 1) {
      if (!contract.formaAdesaoVendedor)
        throw "Selecione o forma de pagamento da adesão ao vendedor";
      if (!contract.parcelasAdesaoVendedor)
        throw "Selecione a forma de parcelamento do pagamento da adesão ao vendedor";
      if (!contract.dataVendedor)
        throw "Preencha o data de cobrança do pagamento da adesão ao vendedor";

      checkDataCobranca(
        contract.dataVendedor,
        "Pagamento da adesão ao vendedor"
      );
    }

    if (contract.meioAdesao == 2) {
      if (!contract.formaAdesaoCoobrastur)
        throw "Selecione o forma de pagamento da adesão a Coobrastur";

      if (!contract.parcelasAdesaoCoobrastur)
        throw "Selecione a forma de parcelamento do pagamento da adesão a Coobrastur";

      if (!contract.dataCoobrastur)
        throw "Preencha o data de cobrança do pagamento da adesão a Coobrastur";

      checkDataCobranca(
        contract.dataCoobrastur,
        "Pagamento da adesão a Coobrastur"
      );
    }
  }

  // endereço
  if (!contract.uf) throw "Selecione o uf do associado";

  if (contract.adesao && contract.meioAdesao == 2) {
    if (!contract.cep) throw "Preencha o CEP do associado";
    if (!contract.cidade) throw "Preencha a cidade do endereço do associado";
    if (!contract.bairro) throw "Preencha o bairro do endereço do associado";
    if (!contract.rua) throw "Preencha a rua do endereço do associado";
    if (!contract.numeroRua) throw "Preencha o número do endereço do associado";
  }

  // pagamento
  if (!contract.formaPagamento)
    throw "Selecione a forma de pagamento da mensalidade do plano";

  if (contract.formaPagamento == 1) {
    if (!contract.cpfTitularContaCorrente)
      throw "Preencha o CPF do titular da conta corrente para pagamento de mensalidades";

    if (!checkCpf(contract.cpfTitularContaCorrente))
      throw "Por favor, preencha um CPF válido para o titular da conta corrente para pagamento de mensalidades.";

    if (!contract.nomeTitularContaCorrente)
      throw "Preencha o nome titular da conta corrente para pagamento de mensalidades";
    if (!contract.bancoContaCorrente)
      throw "Selecione o banco da conta corrente para pagamento de mensalidades";

    if (!contract.numeroContaCorrente)
      throw "Preencha o número da conta corrente para pagamento de mensalidades";

    // banco 1 e 104 não exigem número específico de dígitos
    if (
      Number(contract.bancoContaCorrente) !== 1 &&
      Number(contract.bancoContaCorrente) !== 104
    ) {
      const digitos = String(contract.numeroContaCorrente).length;
      const digitosNecessarios = getLimiteDigitosConta({
        banco: Number(contract.bancoContaCorrente),
      });

      if (digitos !== digitosNecessarios)
        throw `O número da conta corrente tem ${digitos} dígitos, mas deve conter ${digitosNecessarios} dígitos. Por favor, corrigir.`;
    }

    // se banco caixa 104, numero não pode exceder a 8
    if (Number(contract.bancoContaCorrente) === 104) {
      const digitosCaixa = String(contract.numeroContaCorrente).length;
      const digitosLimiteCaixa = getLimiteDigitosConta({
        banco: Number(contract.bancoContaCorrente),
      });

      if (digitosCaixa > digitosLimiteCaixa)
        throw `O número da conta corrente tem ${digitosCaixa} dígitos, mas deve conter até ${digitosLimiteCaixa} dígitos. Por favor, corrigir.`;
    }

    if (!contract.dvContaCorrente)
      throw "Preencha o dígito da conta corrente para pagamento de mensalidades";

    if (!contract.agenciaContaCorrente)
      throw "Preencha a agência da conta corrente para pagamento de mensalidades";

    const digitosAgencia = String(contract.agenciaContaCorrente).length;
    const digitosAgenciaNecessarios = getLimiteDigitosAgencia({
      banco: Number(contract.bancoContaCorrente),
    });

    if (digitosAgencia !== digitosAgenciaNecessarios)
      throw `O número da agência tem ${digitosAgencia} dígitos, mas deve conter ${digitosAgenciaNecessarios} dígitos. Por favor, corrigir.`;

    if (contract.bancoContaCorrente == 41) {
      if (!contract.dvAgenciaContaCorrente)
        throw "Preencha o dígito agência da conta corrente para pagamento de mensalidades";

      const digitosDvAgencia = String(contract.dvAgenciaContaCorrente).length;
      const digitosDvAgenciaNecessarios = 2;

      if (digitosDvAgencia !== digitosDvAgenciaNecessarios)
        throw `O DV da agência tem ${digitosDvAgencia} dígitos, mas deve conter ${digitosDvAgenciaNecessarios} dígitos. Por favor, corrigir.`;
    }

    if (contract.adesao) {
      if (!contract.diaDebitoConta)
        throw "Selecione o dia para débito em conta corrente para pagamento de mensalidades";
    } else {
      if (!contract.dataDebitoConta)
        throw "Preencha a data para débito em conta corrente para pagamento de mensalidades";

      checkDataCobranca(
        contract.dataDebitoConta,
        "Pagamento da mensalidade via débito em conta"
      );

      const diaDebitoConta = getDayFromString(contract.dataDebitoConta);

      if (diaDebitoConta % 5 > 0) {
        throw "Preencha a data para débito com dia 05, 10, 15, 20 ou 30 em conta corrente para pagamento de mensalidades";
      }
    }
  }

  if (contract.formaPagamento == 2) {
    if (!contract.cpfTitularCartao)
      throw "Preencha o CPF do titular do cartão para pagamento de mensalidades";

    if (!checkCpf(contract.cpfTitularCartao))
      throw "Por favor, preencha um CPF válido para o titular do cartão para pagamento de mensalidades.";

    if (!contract.nomeTitularCartao)
      throw "Preencha o nome titular do cartão para pagamento de mensalidades";
    if (!contract.numeroCartao)
      throw "Preencha o número do cartão  para pagamento de mensalidades";
    if (!contract.mesValidadeCartao)
      throw "Preencha o mês de validade do cartão para pagamento de mensalidades";

    if (String(contract.mesValidadeCartao).length < 2)
      throw "Preencha o mês de validade do cartão para pagamento de mensalidades com dois números";

    if (
      Number(contract.mesValidadeCartao) < 1 ||
      Number(contract.mesValidadeCartao) > 31
    )
      throw "Preencha um mês de validade do cartão válido";

    if (!contract.anoValidadeCartao)
      throw "Preencha o ano de validade do cartão  para pagamento de mensalidades";

    if (contract.anoValidadeCartao.length !== 4)
      throw "Preencha um ano de validade com 4 dígitos.";

    if (Number(contract.anoValidadeCartao) < new Date().getFullYear())
      throw "Preencha um ano de validade do cartão válido";

    if (
      isBefore(
        new Date(
          Number(contract.anoValidadeCartao),
          Number(contract.mesValidadeCartao) - 1,
          1
        ),
        setDate(new Date(), 1)
      )
    )
      throw "O cartão de crédito não pode estar vencido";

    if (!contract.cvvCartao)
      throw "Preencha o CVV do cartão para pagamento de mensalidades";

    if (contract.adesao) {
      if (!contract.diaFaturaCartao)
        throw "Selecione o dia da fatura do cartão para pagamento de mensalidades";
    } else {
      if (!contract.dataFaturaCartao)
        throw "Preencha a data de cobrança das mensalidades via cartão de crédito";

      checkDataCobranca(
        contract.dataFaturaCartao,
        "Pagamento da mensalidade via cartão"
      );
    }
  }

  if (contract.formaPagamento == 9 && contract.adesao === false) {
    if (!contract.dataVendaSemDados)
      throw "Necessário preencher data para pagamento da mensalidade";

      checkDataCobranca(
        contract.dataVendaSemDados,
        "Pagamento da mensalidade sem dados bancários"
      );
  }

  if (contract.formaPagamento == 0 ) {
    if (!contract.dataVendaSemDados)
      throw "Necessário selecionar a forma de pagamento";

      checkDataCobranca(
        contract.dataVendaSemDados,
        "Pagamento da mensalidade sem dados bancários"
      );
  }

  // Terceiro
  if (contract.terceiro) {
    if (!contract.cpfTerceiro) throw "Preencha o CPF do terceiro";

    if (!checkCpf(contract.cpfTerceiro))
      throw "Por favor, preencha um CPF válido para pagamento de mensalidades em conta corrente.";

    if (!contract.nomeTerceiro) throw "Preencha o nome completo do terceiro";
    if (!contract.nascimentoTerceiro) throw "Preencha o nascimento do terceiro";

    if (!checkStringDate(contract?.nascimentoTerceiro))
      throw "A data de nascimento do terceiro é inválida, por favor corrigir.";

    checkMaiorIdade(contract.nascimentoTerceiro, "O terceiro");

    if (!contract.nacionalidadeTerceiro)
      throw "Preencha o nacionalidade do terceiro";
    if (!contract.estadoCivilTerceiro)
      throw "Selecione o estado civil do terceiro";

    if (contract.estadoCivilTerceiro == 2)
      if (!contract.nomeConjugeTerceiro)
        throw "Preencha o nome do cônjuge do terceiro";

    if (!contract.nomeMaeTerceiro) throw "Preencha o nome da mãe do terceiro";

    if (!contract.emailTerceiro) throw "Preencha o e-mail do terceiro";

    if (contract?.email === contract?.emailTerceiro)
      throw "O e-mail do terceiro não pode ser igual ao e-mail do novo associado.";

    if (!contract.celularTerceiro) throw "Preencha o celular do terceiro";

    if (contract?.celular === contract?.celularTerceiro)
      throw "O celular do terceiro não pode ser igual ao celular do novo associado.";

    if (!contract.formaAssinaturaTerceiro)
      throw "Selecione uma opção para a forma de assinatura do terceiro";

    if (contract.formaAssinaturaTerceiro === 2)
      if (!contract.assinaturaTerceiro)
        throw "Na opção de assinatura offline(dedo), o terceiro precisa assinar";
  }

  // adesao via cartao e pagamento mensalidade via conta
  if (contract.formaAdesaoCoobrastur == 2 && contract.formaPagamento == 1) {
    if (!contract.cpfTitularCartao)
      throw "Preencha o CPF do titular do cartão para pagamento de mensalidades";

    if (!checkCpf(contract.cpfTitularCartao))
      throw "Por favor, preencha um CPF válido para o titular do cartão para pagamento de mensalidades.";

    if (!contract.nomeTitularCartao)
      throw "Preencha o nome titular do cartão para pagamento de mensalidades";
    if (!contract.numeroCartao)
      throw "Preencha o número do cartão  para pagamento de mensalidades";
    if (!contract.mesValidadeCartao)
      throw "Preencha o mês de validade do cartão para pagamento de mensalidades";

    if (String(contract.mesValidadeCartao).length < 2)
      throw "Preencha o mês de validade do cartão para pagamento de mensalidades com dois números";

    if (
      Number(contract.mesValidadeCartao) < 1 ||
      Number(contract.mesValidadeCartao) > 31
    )
      throw "Preencha um mês de validade do cartão válido";

    if (!contract.anoValidadeCartao)
      throw "Preencha o ano de validade do cartão  para pagamento de mensalidades";

    if (contract.anoValidadeCartao.length !== 4)
      throw "Preencha um ano de validade com 4 dígitos.";

    if (Number(contract.anoValidadeCartao) < new Date().getFullYear())
      throw "Preencha um ano de validade do cartão válido";

    if (
      isBefore(
        new Date(
          Number(contract.anoValidadeCartao),
          Number(contract.mesValidadeCartao) - 1,
          1
        ),
        setDate(new Date(), 1)
      )
    )
      throw "O cartão de crédito não pode estar vencido";

    if (!contract.cvvCartao)
      throw "Preencha o CVV do cartão para pagamento de mensalidades";
  }

  // ASSINATURA
  if (!contract.formaAssinatura)
    throw "Selecione uma forma de assinatura para o associado";

  if (contract.formaAssinatura === 2)
    if (!contract.assinatura)
      throw "Na opção de assinatura offline(dedo), o associado precisa assinar";

  return;
};

export const genRef = () => {
  const dataAtual = new Date();
  const diaAtual = dataAtual.getUTCDate();

  const horaAtual = dataAtual.getUTCHours();
  const minAtual = dataAtual.getUTCMinutes();

  const letras = Math.random().toString(36).substr(2, 2);

  return `${letras}-${diaAtual}-${horaAtual}${minAtual}`;
};

export const checkRef = (contract, contracts) => {
  const selected = contracts.filter((i) => i.ref === contract.ref && i);
  if (!selected[0]) throw "Referência de contrato não encontrada.";

  return;
};

export const removeByRef = (contract, contracts) => {
  checkRef(contract, contracts);

  const filtered = contracts.filter((i) => i.ref !== contract.ref && i);

  return filtered;
};

export const masker = (value, mask) => {
  if (!value) return "";

  const exp = /\-|\.|\/|\(|\)|/g;
  const numbers = value.toString().replace(exp, "");

  if (numbers < 1) return;

  let index = 0;
  let maskedValue = "";
  let digits = numbers.length;

  for (let i = 0; i <= digits; i++) {
    const isMask = Boolean(
      mask.charAt(i) == "-" ||
        mask.charAt(i) == "." ||
        mask.charAt(i) == "/" ||
        mask.charAt(i) == "(" ||
        mask.charAt(i) == ")" ||
        mask.charAt(i) == " "
    );

    maskedValue += isMask ? mask.charAt(i) : numbers.charAt(index);

    if (!isMask) index++;
    if (isMask) digits++;
  }

  return maskedValue;
};
