const INITIAL_STATE = {
  error: "",
  loading: false,
  data: [],
};

import { validateValues, removeByRef } from "../../utils/functions";

const contracts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "STORE_CONTRACT":
      validateValues(action.payload);

      return { ...state, data: [action.payload, ...state.data] };

    case "UPDATE_CONTRACT":
      validateValues(action.payload);

      const newData = removeByRef(action.payload, state.data);

      return { ...state, data: [action.payload, ...newData] };

    case "UPDATE_CONTRACT_LOADING":
      const newData_ = removeByRef(action.payload, state.data);

      return { ...state, data: [action.payload, ...newData_] };

    case "DELETE_CONTRACT":
      const contractsDeleted = removeByRef(action.payload, state.data);

      return { ...state, data: contractsDeleted };

    case "RESET_CONTRACTS":
      return { ...state, data: [] };

    case "SEND_CONTRACT":
      return { ...state, loading: true };

    case "SEND_CONTRACT_FAILURE":
      const dataUpdated = removeByRef(action.payload.contract, state.data);

      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: [...action.payload.contract, dataUpdated],
      };

    default:
      return state;
  }
};

export default contracts;
