import React, { memo, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";

import { mask, unMask } from "remask";

import BadgeOut from "../../outputs/Badge";

const BooleanIn = ({
  style,
  inputStyle,
  data,
  value,
  setValue,
  type,
  label,
  color,
  badges,
}) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 20,
    },

    content: {
      flexDirection: "row",
      flexWrap: "wrap",
    },

    label: {
      fontWeight: "bold",
      marginBottom: 10,
    },

    radioButton: {
      flex: 1,
      height: 36,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
    },

    radioButtonCircleContainer: {
      marginRight: 10,
      width: 20,
      height: 20,
      borderWidth: 2,
      borderColor: "#BFBFBF",
      borderRadius: 50,
      padding: 3,
    },

    radioButtonCircleContainerActive: {
      borderColor: color || "#89f",
    },

    radioButtonCircle: {
      backgroundColor: "rgba(0,0,0,0)",
      borderRadius: 50,
      height: "100%",
      width: "100%",
    },

    radioButtonCircleActive: {
      backgroundColor: color || "#89f",
    },

    selectedRadioButton: {
      backgroundColor: "#fff",
    },
  });

  return (
    <View style={[styles.container, style || {}]}>
      {label && <Text style={styles.label}>{label}:</Text>}

      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity
          style={styles.radioButton}
          onPress={() => setValue(data[0].value)}
        >
          <View
            style={[
              styles.radioButtonCircleContainer,
              value === data[0].value
                ? styles.radioButtonCircleContainerActive
                : {},
            ]}
          >
            <View
              style={[
                styles.radioButtonCircle,
                value === data[0].value ? styles.radioButtonCircleActive : {},
              ]}
            />
          </View>
          <Text>{data[0].label}</Text>
          {badges && badges[0] && (
            <BadgeOut
              value={String(badges[0].value)}
              done={Boolean(badges[0]?.done)}
            />
          )}
        </TouchableOpacity>

        {data[1] && (
          <TouchableOpacity
            style={styles.radioButton}
            onPress={() => setValue(data[1].value)}
          >
            <View
              style={[
                styles.radioButtonCircleContainer,
                value === data[1].value
                  ? styles.radioButtonCircleContainerActive
                  : {},
              ]}
            >
              <View
                style={[
                  styles.radioButtonCircle,
                  value === data[1].value ? styles.radioButtonCircleActive : {},
                ]}
              />
            </View>
            <Text>{data[1].label} </Text>
            {badges && badges[1] && (
              <BadgeOut
                value={String(badges[1].value)}
                done={Boolean(badges[1]?.done)}
              />
            )}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default BooleanIn;
