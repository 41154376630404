import React from "react";
import { StyleSheet, View, Text, Platform } from "react-native";

const TextOut = ({ style, label, value, uppercase, capitalize }) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: "100%",
      padding: 20,
      paddingVertical: 10,
    },

    content: {
      flexDirection: "row",
    },

    label: {
      fontWeight: "bold",
      marginBottom: 10,
    },

    value: {
      textTransform: uppercase
        ? "uppercase"
        : capitalize
        ? "capitalize"
        : "none",
    },
  });

  return (
    <View style={styles.container}>
      {label && <Text style={styles.label}>{label}:</Text>}

      <View style={styles.content}>
        <Text style={[styles.value, style || {}]}>
          {value === true
            ? "Sim"
            : value === false
            ? "Não"
            : value === undefined || String(value).length == 0
            ? "-"
            : String(value)}
        </Text>
      </View>
    </View>
  );
};

export default TextOut;
