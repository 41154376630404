export const signIn = (data) => {
  return {
    type: "REQUEST_SIGN_IN",
    payload: data,
  };
};

export const signOut = () => {
  return {
    type: "REQUEST_SIGN_OUT",
  };
};
