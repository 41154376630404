import React, { useRef, useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Button,
  Alert,
  TouchableOpacity,
  Animated,
} from "react-native";

import { useDispatch, useSelector } from "react-redux";
import { addContract, resetContracts } from "../../redux/contracts/actions";
import { signOut } from "../../redux/sign/actions";

import { mask } from "remask";

import { DialogContext } from "../../context/Dialog";

import colors from "../../../colors.json";

import { NavigationContext } from "../../context/Navigation";

import Header from "../../utils/outputs/Header";

export default function Home() {
  const dispatch = useDispatch();
  const sign = useSelector((state) => state.sign);

  const useDialog = useContext(DialogContext);
  const navigation = useContext(NavigationContext);

  const logout = () => {
    useDialog({
      message: "Você deseja deslogar?",
      options: [
        {
          text: "Sim",
          onPress: () => {
            dispatch(signOut());
            navigation.set.screen("login");
          },
        },

        {
          text: "Não",
          style: "cancel",
        },
      ],
    });
  };

  const reset = () => {
    useDialog({
      message: "Você deseja apagar todos contratos?",
      options: [
        {
          text: "Sim",
          onPress: () => dispatch(resetContracts()),
        },

        {
          text: "Não",
          style: "cancel",
        },
      ],
    });
  };

  useEffect(() => {
    if (!sign.loading) {
      if (!sign.data.token) {
        navigation.set.screen("login");

        return useDialog({ Title: "Sucesso", message: "Até breve." });
      }
    }
  }, [sign]);

  const [animated, setAnimated] = useState(false);
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: animated ? 1 : 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [animated]);

  return (
    <View style={styles.container}>
      <Header
        title="Minha Conta"
        headerLeft={{
          text: "Voltar",
          callback: () => navigation.set.screen("home"),
        }}
      />

      <Animated.ScrollView
        style={[styles.content, { opacity: animatedOpacity }]}
      >
        <View style={styles.inputContainer}>
          <Text style={styles.label}>nome:</Text>
          <Text style={[styles.inputValue, { textTransform: "uppercase" }]}>
            {sign?.data?.nome || "Sem registro."}
          </Text>
        </View>

        <View style={styles.inputContainer}>
          <Text style={styles.labelUppercase}>cpf:</Text>
          <Text style={[styles.inputValue, { textTransform: "uppercase" }]}>
            {mask(sign?.data?.cpf, ["999.999.999-99"]) || "Sem registro."}
          </Text>
        </View>

        <View style={styles.inputContainer}>
          <Text style={[styles.label, { textTransform: "none" }]}>
            Usuário:
          </Text>
          <Text style={styles.inputValue}>
            {sign?.data?.usuario || "Sem registro."}
          </Text>
        </View>

        <View style={styles.inputContainer}>
          <Text style={[styles.label, { textTransform: "none" }]}>E-mail:</Text>
          <Text style={styles.inputValue}>
            {sign?.data?.email || "Sem registro."}
          </Text>
        </View>

        <View style={styles.inputContainer}>
          <Text style={[styles.label, { textTransform: "none" }]}>Código:</Text>
          <Text style={styles.inputValue}>
            {sign?.data?.codigoVendedor || "Sem registro."}
          </Text>
        </View>

        <View style={styles.inputContainer}>
          <Button title="Deslogar" onPress={logout} />
        </View>

        <View style={styles.inputContainer}>
          <Button title="Resetar contratos" onPress={reset} />
        </View>

        <View style={styles.inputContainer}>
          <View style={{ padding: 20, flex: 1, alignItems: "center" }}>
            <Text style={{ color: "#999", fontWeight: "bold" }}>
              Versão 0.0.119
            </Text>
          </View>
        </View>
      </Animated.ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    backgroundColor: "rgb(240,240,245)",
  },

  content: {
    flex: 1,
    padding: 10,
    maxWidth: 720,
    width: "100%",
  },

  inputContainer: {
    padding: 10,
  },

  label: {
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "capitalize",
  },

  labelUppercase: {
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "uppercase",
  },

  headerButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: 10,
  },

  headerButtonText: {
    color: "#fff",
    fontSize: 18,
  },
});
