export const addContract = (data) => ({
  type: "STORE_CONTRACT",
  payload: data,
});

export const setContract = (data) => ({
  type: "UPDATE_CONTRACT",
  payload: data,
});

export const setContractLoading = (data) => ({
  type: "UPDATE_CONTRACT_LOADING",
  payload: data,
});

export const deleteContract = (data) => ({
  type: "DELETE_CONTRACT",
  payload: data,
});

export const sendContract = (data) => ({
  type: "SEND_CONTRACT",
  payload: data,
});

export const resetContracts = () => ({
  type: "RESET_CONTRACTS",
});
