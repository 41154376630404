import React, { memo, useState } from "react";
import { StyleSheet, View, Text, Picker } from "react-native";

import { mask, unMask } from "remask";

const SelectIn = ({
  style,
  inputStyle,
  data,
  value,
  setValue,
  type,
  label,
  color,
}) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 20,
    },

    content: {
      flexDirection: "row",
      minHeight: 45,
      borderRadius: 5,
      backgroundColor: "#fff",
    },

    label: {
      fontWeight: "bold",
      marginBottom: 10,
    },

    selectInput: {
      flex: 1,
      borderRadius: 5,
      minHeight: 45,
      maxHeight: 120,
      backgroundColor: "rgba(0,0,0,0)",
      paddingHorizontal: 10,
      overflow: "hidden",
      justifyContent: "center",
      borderWidth: 0,
      shadowColor: 0,
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 0,
      shadowOpacity: 0,
    },

    selectInputItem: {
      backgroundColor: "rgba(0,0,0,0)",
      paddingHorizontal: 10,
      borderRadius: 5,
      minHeight: 45,
      borderWidth: 0,
      shadowColor: 0,
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 0,
      shadowOpacity: 0,
    },
  });

  if (!data.length)
    return (
      <Text>
        SelectIn está incorreto, precisa receber um array com pelo menos um
        objeto.
      </Text>
    );

  return (
    <View style={[styles.container, style || {}]}>
      {label && <Text style={styles.label}>{label}:</Text>}

      <View style={styles.content}>
        <Picker
          selectedValue={value}
          onValueChange={(value) => setValue(value)}
          style={styles.selectInput}
          itemStyle={styles.selectInputItem}
        >
          {data?.map((item, i) => (
            <Picker.Item key={i} value={item.value} label={item.label} />
          ))}
        </Picker>
      </View>
    </View>
  );
};

export default SelectIn;
