import React, { useContext, useState } from "react";
import { StyleSheet, View, Text } from "react-native";

import Redux from "./src/redux";

import Dialog from "./src/context/Dialog";

import Navigation, { NavigationContext } from "./src/context/Navigation";

import Welcome from "./src/screens/Welcome";
import Login from "./src/screens/Login";
import Home from "./src/screens/Home";
import Account from "./src/screens/Account";
import ContractsIndex from "./src/screens/Contracts";
import ContractsShow from "./src/screens/Contracts/show";
import ContractsUpdate from "./src/screens/Contracts/update";
import ContractsStore from "./src/screens/Contracts/store";

import { useSelector } from "react-redux";

export default function App() {
  const Navigator = () => {
    const navigation = useContext(NavigationContext);
    const sign = useSelector((state) => state.sign);

    if (
      !sign.data.token &&
      navigation.get.screen() !== "welcome" &&
      navigation.get.screen() !== "login"
    ) {
      navigation.set.screen("welcome");
      return <Welcome />;
    }

    switch (navigation.get.screen()) {
      case "welcome":
        return <Welcome />;

      case "login":
        return <Login />;

      case "home":
        return <Home />;

      case "account":
        return <Account />;

      case "contractsIndex":
        return <ContractsIndex />;

      case "contractsShow":
        return <ContractsShow />;

      case "contractsStore":
        return <ContractsStore />;

      case "contractsUpdate":
        return <ContractsUpdate />;

      default:
        return (
          <View>
            <Text>404</Text>
          </View>
        );
    }
  };

  return (
    <View style={styles.container}>
      <Redux>
        <Dialog>
          <Navigation>
            <Navigator />
          </Navigation>
        </Dialog>
      </Redux>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flex: 1,
    backgroundColor: "#fefeff",
  },
});
