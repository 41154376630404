import React, { useRef, useState, createContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Keyboard,
  Platform,
  Animated,
} from "react-native";

export const DialogContext = createContext();

const Dialog = (props) => {
  const INITIAL_STATE = {
    open: false,
    title: "Atenção",
    message: "",
    options: [],
  };

  const [dialog, setDialog] = useState(INITIAL_STATE);

  const useDialog = (obj) => setDialog({ ...dialog, ...obj, open: true });

  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Keyboard.dismiss(0);

    dialog.open
      ? Animated.timing(animatedOpacity, {
          toValue: 1,
          duration: 250,
          useNativeDriver: true,
        }).start()
      : Animated.timing(animatedOpacity, {
          toValue: 0,
          duration: 100,
          useNativeDriver: true,
        }).start();
  }, [dialog]);

  const styles = StyleSheet.create({
    container: {
      position:
        Platform.OS === "ios" || Platform.OS === "android"
          ? "absolute"
          : "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(30,30,30,.9)",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100,
    },

    content: {
      shadowColor: "#333",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.5,
      shadowRadius: 8,
      borderRadius: 10,
      backgroundColor: "#fff",

      maxWidth: "90%",
      minWidth: 240,
      borderWidth: 1,
    },

    title: {
      marginTop: 10,
      padding: 10,
      fontSize: 18,
      fontWeight: "bold",
      color: "#222",
      textAlign: "center",
    },

    message: {
      paddingHorizontal: 20,
      fontSize: 14,
      color: "#222",
      textAlign: "center",
      marginBottom: 10,
    },

    option: {
      borderTopWidth: 0.5,
      borderTopColor: "#cfcfcf",
      paddingVertical: 10,
    },

    optionText: {
      width: "100%",
      fontSize: 14,
      color: "#45f",
      textAlign: "center",
    },
  });

  const reset = () => {
    setDialog(INITIAL_STATE);
  };

  return (
    <DialogContext.Provider value={useDialog}>
      {dialog.open && (
        <View style={styles.container}>
          <Animated.View style={[styles.content, { opacity: animatedOpacity }]}>
            <Text style={styles.title}>{dialog.title || "Atenção"} </Text>

            {dialog.message !== "" && (
              <Text style={styles.message}>{dialog.message}</Text>
            )}

            {dialog.options.length > 0 ? (
              dialog.options.map((option, i) => (
                <TouchableOpacity
                  key={i}
                  style={styles.option}
                  onPress={() => {
                    reset();

                    if (option.onPress) option.onPress();
                  }}
                >
                  <Text style={styles.optionText}>{option.text}</Text>
                </TouchableOpacity>
              ))
            ) : (
              <TouchableOpacity style={styles.option} onPress={reset}>
                <Text style={styles.optionText}>Ok</Text>
              </TouchableOpacity>
            )}
          </Animated.View>
        </View>
      )}

      {props.children}
    </DialogContext.Provider>
  );
};

export default Dialog;
