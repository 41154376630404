import React from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import Constants from "expo-constants";
import colors from "../../../../colors.json";

export default function Header({ headerLeft, title, headerRight }) {
  return (
    <View
      style={{
        backgroundColor: colors.primary,
        padding: 10,
        paddingTop: 20 + Constants.statusBarHeight,
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <TouchableOpacity
        onPress={() => headerLeft?.callback() || {}}
        style={{ padding: 10 }}
      >
        <Text style={{ color: "#fff", fontWeight: "600", fontSize: 16 }}>
          {headerLeft?.text || "        "}
        </Text>
      </TouchableOpacity>

      <Text
        style={{
          padding: 10,
          alignSelf: "center",
          color: "#fff",
          fontWeight: "bold",
          fontSize: 18,
        }}
      >
        {title || "        "}
      </Text>

      <TouchableOpacity
        onPress={() => headerRight?.callback() || {}}
        style={{ padding: 10 }}
      >
        <Text style={{ color: "#fff", fontWeight: "600", fontSize: 16 }}>
          {headerRight?.text || "        "}
        </Text>
      </TouchableOpacity>
    </View>
  );
}
