export const restricaoArray = [
  { value: 1, label: "Fiador" },
  { value: 2, label: "Aditamento" },
];

export const booleanArray = [
  { value: true, label: "Sim" },
  { value: false, label: "Não" },
];

export const ufArray = [
  { value: "AC", label: "AC" },
  { value: "AL", label: "AL" },
  { value: "AP", label: "AP" },
  { value: "AM", label: "AM" },
  { value: "BA", label: "BA" },
  { value: "CE", label: "CE" },
  { value: "DF", label: "DF" },
  { value: "ES", label: "ES" },
  { value: "GO", label: "GO" },
  { value: "MA", label: "MA" },
  { value: "MT", label: "MT" },
  { value: "MS", label: "MS" },
  { value: "MG", label: "MG" },
  { value: "PA", label: "PA" },
  { value: "PB", label: "PB" },
  { value: "PR", label: "PR" },
  { value: "PE", label: "PE" },
  { value: "PI", label: "PI" },
  { value: "RJ", label: "RJ" },
  { value: "RN", label: "RN" },
  { value: "RS", label: "RS" },
  { value: "RO", label: "RO" },
  { value: "RR", label: "RR" },
  { value: "SC", label: "SC" },
  { value: "SP", label: "SP" },
  { value: "SE", label: "SE" },
  { value: "TO", label: "TO" },
];

export const tipoVendaArray = [
  { value: 1, label: "Normal" },
  { value: 2, label: "Reativação" },
];

export const planoArray = [
  // { value: 21, label: "Vip" },
  // { value: 22, label: "Master" },
  // { value: 31, label: "Gold Vip" },
  // { value: 32, label: "Gold Master" },
  // { value: 33, label: "Diamante" },
  // { value: 34, label: "Go Vip" },
  // { value: 35, label: "Go Master" },
  { value: 36, label: "Vip+" },
  { value: 37, label: "Master+" },
  { value: 38, label: "Gold Vip+" },
  { value: 39, label: "Gold Master+" },
  { value: 40, label: "Diamante+" },
  { value: 41, label: "Go Vip+" },
  { value: 42, label: "Go Master+" },
];

export const planoDescricaoArray = [
  {
    value: 36,
    label:
      'Este plano contém a rede de hotéis "executivo" e pode ser utilizado o ano todo',
  },
  {
    value: 37,
    label:
      'Este plano contém a rede de hotéis "executivo" e pode ser utilizado de 15 de março a 15 de dezembro.',
  },
  {
    value: 38,
    label:
      'Este plano contém a rede de hotéis "superior" e pode ser utilizado o ano todo',
  },
  {
    value: 39,
    label:
      'Este plano contém a rede de hotéis "superior" e pode ser utilizado de 15 de março a 15 de dezembro.',
  },
  {
    value: 40,
    label:
      'Este plano contém a rede de hotéis "super luxo e resort" e pode ser utilizado o ano todo',
  },
  {
    value: 41,
    label:
      'Este plano contém a rede de hotéis "executivo" e pode ser utilizado o ano todo.',
  },
  {
    value: 42,
    label:
      'Este plano contém a rede de hotéis "executivo" e pode ser utilizado de 15 de março a 15 de dezembro.',
  },
];

export const diariasArray = [
  { value: 7, label: "7 diárias" },
  { value: 9, label: "9 diárias" },
  { value: 11, label: "11 diárias" },
  { value: 13, label: "13 diárias" },
  { value: 15, label: "15 diárias" },
  { value: 17, label: "17 diárias" },
  { value: 19, label: "19 diárias" },
  { value: 21, label: "21 diárias" },
  { value: 23, label: "23 diárias" },
  { value: 25, label: "25 diárias" },
  { value: 27, label: "27 diárias" },
  { value: 29, label: "29 diárias" },
  { value: 31, label: "31 diárias" },
  { value: 33, label: "33 diárias" },
  { value: 35, label: "35 diárias" },
  { value: 37, label: "37 diárias" },
  { value: 39, label: "39 diárias" },
  { value: 41, label: "41 diárias" },
  { value: 43, label: "43 diárias" },
  { value: 45, label: "45 diárias" },
  { value: 47, label: "47 diárias" },
  { value: 49, label: "49 diárias" },
];

export const diariasGoArray = [
  { value: 4, label: "4 diárias" },
  { value: 7, label: "7 diárias" },
  { value: 9, label: "9 diárias" },
  { value: 11, label: "11 diárias" },
  { value: 13, label: "13 diárias" },
  { value: 15, label: "15 diárias" },
  { value: 17, label: "17 diárias" },
  { value: 19, label: "19 diárias" },
  { value: 21, label: "21 diárias" },
  { value: 23, label: "23 diárias" },
  { value: 25, label: "25 diárias" },
  { value: 27, label: "27 diárias" },
  { value: 29, label: "29 diárias" },
  { value: 31, label: "31 diárias" },
  { value: 33, label: "33 diárias" },
  { value: 35, label: "35 diárias" },
  { value: 37, label: "37 diárias" },
  { value: 39, label: "39 diárias" },
  { value: 41, label: "41 diárias" },
  { value: 43, label: "43 diárias" },
  { value: 45, label: "45 diárias" },
  { value: 47, label: "47 diárias" },
  { value: 49, label: "49 diárias" },
];

export const formaPagamentoMensalidadeArrayBrima = [
  { value: 0, label: "Selecione" },
  { value: 1, label: "Conta bancária" },
  { value: 2, label: "Cartão (crédito)" },
  { value: 9, label: "Sem dados bancários" },
];

export const formaPagamentoMensalidadeArray = [
  { value: 0, label: "Selecione" },
  { value: 1, label: "Conta bancária" },
  { value: 2, label: "Cartão (crédito)" },
];

export const bandeiraCartaoArray = [
  { value: 1, label: "Visa" },
  { value: 2, label: "Credicard" },
  { value: 3, label: "Mastercard" },
  { value: 4, label: "Dinners" },
  { value: 6, label: "American Express" },
  { value: 7, label: "HiperCard" },
  { value: 8, label: "Elo" },
];

export const diaMensalidadeBancoArray = [
  { value: 5, label: "05" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: 30, label: "30" },
];

export const diaMensalidadeCartaoArray = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
];

export const bancoContaArray = [
  { value: 0, label: "Selecione" },
  { value: 1, label: "001-B. BRASIL" },
  { value: 33, label: "033-SANTANDER" },
  { value: 41, label: "041-BANRISUL" },
  { value: 104, label: "104-CAIXA" },
  { value: 237, label: "237-BRADESCO" },
  { value: 341, label: "341-ITAÚ" },
  { value: 748, label: "748-SICREDI" },
];

export const bancoCaixaTipoArray = [
  { value: "01", label: "Conta corrente PF - 01" },
  { value: "13", label: "Poupança PF/PJ - 13" },
  { value: "23", label: "Conta caixa fácil - 23" },
  { value: "03", label: "Conta corrente PJ - 03" },
  { value: "22", label: "Poupança PJ - 22" },
  { value: "06", label: "Entidades públicas - 06" },
  { value: "02", label: "Conta simples PF - 02" },
  { value: "28", label: "Poupança crédito imobiliário - 28" },
];

export const estadoCivilArray = [
  { value: 2, label: "Casado(a)" },
  { value: 1, label: "Solteiro(a)" },
  { value: 3, label: "Divorciado(a)" },
  { value: 4, label: "Viúvo(a)" },
];

export const formaAditamentoArray = [
  { value: 1, label: "12 meses" },
  { value: 2, label: "Imediato" },
];

export const formaAssinaturaArray = [
  { value: 1, label: "Online (d4sign)" },
  { value: 2, label: "Offline (dedo)" },
];

export const adesaoMeioArray = [
  { value: 1, label: "Vendedor" },
  { value: 2, label: "Coobrastur" },
];

export const formaPagamentoAdesaoVendedorArray = [
  { value: 1, label: "Dinheiro" },
  { value: 2, label: "Boleto" },
  { value: 3, label: "Cartão de crédito" },
  { value: 4, label: "Cartão de débito" },
];

export const formaPagamentoAdesaoCoobrasturArray = [
  { value: 1, label: "Boleto" },
  // { value: 2, label: "Cartão de crédito" },
];

export const parcelasPagamentoAdesaoArray = [
  { value: 1, label: "À vista" },
  { value: 2, label: "2x" },
  { value: 3, label: "3x" },
];

// baseado em 7 diárias
export const valorMensalidadePlanoArray = [
  { value: 21, mensalidade: 173.4, novaMensalidade: 188.9 },
  { value: 22, mensalidade: 139.9, novaMensalidade: 149.9 },
  { value: 31, mensalidade: 235.4, novaMensalidade: 249.9 },
  { value: 32, mensalidade: 189.9, novaMensalidade: 199.9 },
  { value: 33, mensalidade: 339.9, novaMensalidade: 344.9 },
  { value: 34, mensalidade: 101.9, novaMensalidade: 109.9 },
  { value: 35, mensalidade: 71.9, novaMensalidade: 79.9 },
  { value: 36, mensalidade: 188.9, novaMensalidade: 199.9 },
  { value: 37, mensalidade: 149.9, novaMensalidade: 157.9 },
  { value: 38, mensalidade: 249.9, novaMensalidade: 257.9 },
  { value: 39, mensalidade: 199.9, novaMensalidade: 205.9 },
  { value: 40, mensalidade: 344.9, novaMensalidade: 354.9 },
  { value: 41, mensalidade: 109.9, novaMensalidade: 114.9 },
  { value: 42, mensalidade: 79.9, novaMensalidade: 82.9 },
];

// baseado em 7 diárias
export const valorMensalidadePlanoFamiliaArray = [
  { value: 21, mensalidade: 260.1, novaMensalidade: 283.35 },
  { value: 22, mensalidade: 209.85, novaMensalidade: 224.85 },
  { value: 31, mensalidade: 353.1, novaMensalidade: 374.85 },
  { value: 32, mensalidade: 284.85, novaMensalidade: 299.85 },
  { value: 33, mensalidade: 509.85, novaMensalidade: 517.35 },
  { value: 36, mensalidade: 283.35, novaMensalidade: 299.85 },
  { value: 37, mensalidade: 224.85, novaMensalidade: 236.85 },
  { value: 38, mensalidade: 374.85, novaMensalidade: 386.85 },
  { value: 39, mensalidade: 299.85, novaMensalidade: 308.85 },
  { value: 40, mensalidade: 517.85, novaMensalidade: 532.35 },
];

export const acrescimoMensalidadeArray = [
  { value: 2, acrescimo: 25 },
  { value: 3, acrescimo: 20 },
  { value: 4, acrescimo: 15 },
  { value: 5, acrescimo: 10 },
  { value: 6, acrescimo: 10 },
];
