import React, { memo, useEffect, useState } from "react";
import { StyleSheet, View, Text, TextInput, Platform } from "react-native";

import { masker } from "../../functions/index";

const TextIn = ({
  style,
  inputStyle,
  value,
  setValue,
  type,
  label,
  placeholder,
  limit,
  disable,
}) => {
  const numericFirstKeyboard = "numbers-and-punctuation";

  const numericKeyboard =
    Platform.OS === "ios"
      ? "number-pad"
      : Platform.OS === "android"
      ? "numeric"
      : numericFirstKeyboard;

  const cnpjMask = "00.000.000/0000-00";
  const cpfMask = "000.000.000-00";
  const cepMask = "00000-000";
  const resMask = "0000-0000";
  const telMask = "0 0000-0000";
  const celularMask = "(00) 0 0000-0000";
  const dataMask = "00/00/0000";
  const globalTelMask = "+000 000 000 000";
  const cardMask = "0000 0000 0000 0000";

  const [formatedValue, setFormatedValue] = useState("");

  function getMask() {
    switch (type) {
      case "cartao":
        return cardMask;

      case "cpf":
        return cpfMask;

      case "cep":
        return cepMask;

      case "celular":
        return celularMask;

      case "telefone":
        return telMask;

      case "residencial":
        return resMask;

      case "data":
        return dataMask;

      default:
        return false;
    }
  }

  function getSecureTextEntry() {
    switch (type) {
      case "senha":
        return true;

      case "cvv":
        return true;

      default:
        return false;
    }
  }

  function getKeyboardType() {
    switch (type) {
      case "email":
        return "email-address";

      case "cpf":
        return numericKeyboard;

      case "rg":
        return numericKeyboard;

      case "cep":
        return numericKeyboard;

      case "data":
        return numericKeyboard;

      case "ddd":
        return numericKeyboard;

      case "celular":
        return numericKeyboard;

      case "telefone":
        return numericKeyboard;

      case "residencial":
        return numericKeyboard;

      case "numero":
        return numericKeyboard;

      case "numero-primeiro":
        return numericFirstKeyboard;

      case "cartao":
        return numericKeyboard;

      case "cvv":
        return numericKeyboard;

      default:
        return "default";
    }
  }

  function getContentType() {
    switch (type) {
      case "email":
        return "emailAddress";
      case "nome":
        return "name";

      default:
        return "none";
    }
  }

  function getCapitalize() {
    switch (type) {
      case "nome":
        return "words";

      case "ssp":
        return "characters";

      case "uf":
        return "characters";

      default:
        return "none";
    }
  }

  const capitalized = getCapitalize();
  const keyboardType = getKeyboardType();
  const contentType = getContentType();
  const secureTextEntry = getSecureTextEntry();

  useEffect(() => {
    const masking = getMask();

    if (masking && value && value !== "" && !secureTextEntry)
      return setFormatedValue(String(masker(String(value), getMask())));

    setFormatedValue("");
    if (value === undefined) setValue("");
  }, [value]);

  return (
    <View style={[styles.container, style || {}]}>
      {label && (
        <Text style={styles.label}>
          {label}:{" "}
          {String(formatedValue).length > 0 && (
            <Text style={styles.formatedValue}>
              {String(formatedValue) || ""}
            </Text>
          )}
        </Text>
      )}

      <View style={styles.content}>
        <TextInput
          style={[styles.input, inputStyle]}
          value={value || ""}
          onChangeText={(text) => setValue(text)}
          placeholder={placeholder || ""}
          autoCapitalize={capitalized}
          keyboardType={keyboardType}
          textContentType={contentType}
          secureTextEntry={secureTextEntry}
          returnKeyType="done"
          maxLength={limit || null}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    padding: 20,
  },

  content: {
    position: "relative",
    flexDirection: "row",
  },

  label: {
    fontWeight: "bold",
    marginBottom: 10,
  },

  input: {
    flex: 1,
    height: 45,
    borderRadius: 5,
    backgroundColor: "#fff",
    paddingHorizontal: 10,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    // borderWidth: 1,
    // borderColor: "#aaa",
    // color: "transparent",
  },

  formatedValue: {
    // position: "absolute",
    top: 15,
    left: 10,
    // opacity: 1,
  },
});

export default memo(TextIn);
