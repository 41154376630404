import React from "react";
import { StyleSheet, View, Text } from "react-native";

const Badge = ({ value, done }) => {
  const styles = StyleSheet.create({
    container: {
      alignSelf: "flex-start",
      width: 20,
      height: 20,
      backgroundColor: done ? "#2b7" : "#f56",
      borderRadius: 50,
      shadowOffset: { width: -0.5, height: -0.5 },
      shadowColor: "#fff",
      shadowOpacity: 0.3,
      shadowRadius: 2,
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
    },

    text: {
      fontSize: 12,
      fontWeight: "600",
      color: "#fff",
      textAlign: "center",
      textAlignVertical: "center",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
    },
  });

  return (
    <View style={styles.container}>
      {value && <Text style={styles.text}>{value}</Text>}
    </View>
  );
};

export default Badge;
