const INITIAL_STATE = {
  disabled: false,
};

const welcome = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ENABLE_WELCOME":
      return { ...state, disabled: false };

    case "DISABLE_WELCOME":
      return { ...state, disabled: true };

    default:
      return state;
  }
};

export default welcome;
