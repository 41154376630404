const INITIAL_STATE = {
  loading: false,
  error: "",
  data: {
    usuarioVendedor: "",
    token: "",
    email: "",
    usuario: "",
    nome: "",
    cpf: "",
    pagamentoAdesao: false,
    codigoVendedor: 0,
  },
};

const sign = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // login
    case "REQUEST_SIGN_IN":
      return { ...state, loading: true };

    case "SUCCESS_SIGN_IN":
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: "",
      };

    case "FAILURE_SIGN_IN":
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    // logout
    case "REQUEST_SIGN_OUT":
      return { ...state, loading: true };

    case "SUCCESS_SIGN_OUT":
      return { ...INITIAL_STATE, loading: false };

    case "FAILURE_SIGN_OUT":
      return { ...state, error: action.payload.error, loading: false };

    // something else...
    default:
      return state;
  }
};

export default sign;
