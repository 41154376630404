import React, { useRef, useState, useEffect, useContext } from "react";
import {
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  View,
  Text,
  Image,
  Alert,
  Animated,
} from "react-native";

import colors from "../../../colors.json";

import accountImg from "../../../assets/account.png";

import contractsIndexImg from "../../../assets/contractsIndex.png";
import contractsStoreImg from "../../../assets/contractsStore.png";
import hotelsIndexImg from "../../../assets/hotelsIndex.png";
import magazineImg from "../../../assets/magazine.png";

import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../redux/sign/actions";

import Constants from "expo-constants";

import { NavigationContext } from "../../context/Navigation";

import Header from "../../utils/outputs/Header";

const Card = ({ route, img, title }) => {
  const navigation = useContext(NavigationContext);

  const styles = StyleSheet.create({
    cardContainer: {
      width: "50%",
      padding: 10,
    },

    card: {
      height: 140,
      borderRadius: 10,
      shadowColor: "#223",
      shadowOpacity: 0.15,
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 5,
      backgroundColor: "#fff",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
    },

    cardImage: {
      marginVertical: 20,
      height: 52,
      width: 52,
    },

    cardTextContainer: {
      flex: 1,
      width: "100%",
      padding: 10,
      borderTopWidth: 1,
      borderTopColor: "#efefef",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },

    cardText: {
      textAlign: "center",
      fontSize: 16,
      color: "#444",
      textTransform: "capitalize",
    },
  });

  const getCardImage = () => {
    switch (img) {
      case "account":
        return <Image style={styles.cardImage} source={accountImg} />;
      case "contractsIndex":
        return <Image style={styles.cardImage} source={contractsIndexImg} />;
      case "contractsStore":
        return <Image style={styles.cardImage} source={contractsStoreImg} />;
      case "hotelsIndex":
        return <Image style={styles.cardImage} source={hotelsIndexImg} />;
      case "magazine":
        return <Image style={styles.cardImage} source={magazineImg} />;

      default:
        return <View style={styles.cardImage} />;
    }
  };

  return (
    <View style={styles.cardContainer}>
      <TouchableOpacity
        style={styles.card}
        onPress={() => navigation.set.screen(route)}
      >
        {getCardImage()}

        <View style={styles.cardTextContainer}>
          <Text style={styles.cardText}>{title}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default function Home() {
  const dispatch = useDispatch();
  const sign = useSelector((state) => state.sign);

  const navigation = useContext(NavigationContext);

  const [animated, setAnimated] = useState(false);
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: animated ? 1 : 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }, [animated]);

  return (
    <View style={styles.container}>
      <Header title="Bem-vindo" />

      <Animated.ScrollView
        style={[styles.content, { opacity: animatedOpacity }]}
      >
        <View style={styles.cards}>
          <Card title="Minha conta" route="account" img="account" />

          <Card title="Contratos" img="contractsIndex" route="contractsIndex" />

          <Card
            title="Novo contrato"
            img="contractsStore"
            route="contractsStore"
          />

          {/* <Card title="Hotéis" img="hotelsIndex" navigation={navigation} /> */}

          {/* <Card title="Revista" img="magazine" navigation={navigation} /> */}
        </View>
      </Animated.ScrollView>

      <View style={{ padding: 20 }}>
        <Text
          style={{
            fontSize: 14,
            color: "#999",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Coob+ Viagens e Turismo LTDA.
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    backgroundColor: "rgb(240,240,245)",
  },

  content: {
    flex: 1,
    maxWidth: 720,
    width: "100%",
  },

  cards: {
    flex: 1,
    padding: 10,

    flexDirection: "row",
    flexWrap: "wrap",
  },
});
