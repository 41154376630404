import axios from "axios";

const url = {
  prod: "https://telemarketing.coobrastur.com.br/wsAppVendas/ConsultaCrm.asmx/",
  feature2:
    "https://telemarketing.coobrastur.com.br/wsAppVendasV2/ConsultaCrm.asmx/",
  feature3: "http://10.1.2.174/telemarketing/wsTelemarketing/ConsultaCrm.asmx",
};

const baseURL = url.prod;

const api = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",

    "access-control-allow-origin": "*",
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",

    "Content-Type": "application/json",
    "content-type": "application/json",
  },
});

export const apiCoobrastur = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",

    "access-control-allow-origin": "*",
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",

    "Content-Type": "application/json",
    "content-type": "application/json",
  },
});

export const coobkey = "db3b36ac1ee158f3eb16baa30f618cf2873661df";

export const apiCoobD4sign = axios.create({
  baseURL: "https://api-coobrastur-vendas.herokuapp.com/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",

    "access-control-allow-origin": "*",
    "access-control-allow-headers": "*",
    "access-control-allow-methods": "*",

    "Content-Type": "application/json",
    "content-type": "application/json",
  },
});

export const apiViacep = axios.create({
  baseURL: "https://viacep.com.br/ws/",
});

export default api;