import React, { useState, memo } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";

import colors from "../../../../colors.json";

import arrowUp from "../../../../assets/arrowUp.png";
import arrowDown from "../../../../assets/arrowDown.png";

const DivisorCarousel = ({ value, children, opened }) => {
  const [show, setShow] = useState(opened ? true : false);

  return (
    <View>
      <View style={styles.hrContainer}>
        <View style={styles.hrLineLeft} />
        {value && <Text style={styles.hrTitle}>{value || ""}</Text>}

        <View style={styles.hrLine} />

        <TouchableOpacity style={styles.button} onPress={() => setShow(!show)}>
          <Image style={styles.imgButton} source={show ? arrowUp : arrowDown} />
        </TouchableOpacity>
      </View>

      {show && children && children}
    </View>
  );
};

const styles = StyleSheet.create({
  hrContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    marginTop: 10,
  },

  hrLine: {
    borderBottomWidth: 1,
    borderColor: "#cdcdcd",
    height: 1,
    flex: 1,
  },

  hrLineLeft: {
    borderRadius: 50,
    height: 6,
    width: 6,
    backgroundColor: "#59e",
    marginLeft: 20,
    marginRight: 10,
  },

  hrTitle: {
    color: "#444",
    fontSize: 16,
    fontWeight: "bold",
    margin: 5,
  },

  button: {
    width: 45,
    height: 45,
    // borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  imgButton: {
    width: 15,
    height: 15,
    opacity: 0.3,
    resizeMode: "contain",
  },
});

export default memo(DivisorCarousel);
