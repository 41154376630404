import AsyncStorage from "@react-native-async-storage/async-storage";

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import welcome from "./welcome/reducers";
import sign from "./sign/reducers";
import contracts from "./contracts/reducers";

const persistConfig = {
  key: "root_",
  storage: AsyncStorage,
};

const reducers = combineReducers({
  welcome,
  sign,
  contracts,
});

const persistedReducers = persistReducer(persistConfig, reducers);

export default persistedReducers;
